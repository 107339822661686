const alphaNumeric = /^[0-9a-zA-Z]+$/;

export function onlyLettersAndNumbers(username) {
  const message = "Can contain only letters and numbers";

  if (!alphaNumeric.test(username)) {
    return message;
  }

  // if (!Number.isNaN(username)) {
  //   return message;
  // }

  return true;
}

export function containMinimum6Characters(username) {
  if (username && username.length >= 6) {
    return true;
  }

  return "Username must contain minimum 6 characters";
}

export function containMaximum14Characters(username) {
  if (username && username.length <= 14) {
    return true;
  }

  return "Username must contain maximum 14 characters";
}

export const rules = [onlyLettersAndNumbers, containMinimum6Characters, containMaximum14Characters];
