<script setup>
import { computed, ref } from "vue";
import VueHorizontal from "vue-horizontal";
import { useBetStore } from "../../../store";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import "@splidejs/vue-splide/css";
import { useCasinoService } from "@/casino/composables/useCasinoService.js";
import { useI18n } from "@/app/composables/useI18n";
import { router } from "@/app/router";

const store = useBetStore();
const { t } = useI18n();

const { providers } = useCasinoService();

function filterByProvider(p) {
  store.setEmptyProvider();
  router.push({ name: "casino.provider", params: { providerId: p.slug } });
  store.handleSelectedProvider(p.slug, true);
}

function handleViewAll() {
  router.push({ path: "/casino/providers" });
}

const sortedProviders = computed(() => {
  const sp = providers.value;

  return sp.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
});

const horizontal = ref(null);

const prevSlide = (event) => {
  if (horizontal.value && typeof horizontal.value.prev === "function") {
    horizontal.value.prev(event);
  }
};

const nextSlide = (event) => {
  if (horizontal.value && typeof horizontal.value.next === "function") {
    horizontal.value.next(event);
  }
};
</script>

<template>
  <section v-if="providers.length > 0" class="list-wrapper">
    <div class="list-header" v-if="router.currentRoute.value.path !== '/casino/providers'">
      <div class="list-title">
        <BaseIcon name="providers" size="20" color="var(--be-disable-state)" />
        <span>
          {{ t("casino.providers") }}
        </span>
      </div>
      <div
        v-if="router.currentRoute.value.path !== '/casino/providers'"
        class="list-view"
        @click="handleViewAll"
      >
        <div>{{ t("general.view-all") }}</div>
        <div @click="(event) => prevSlide(event)" :class="horizontal?.hasPrev ? '' : 'dim'">
          <img src="/assets/images/left-arrow-base-sliding.svg" alt="" />
        </div>
        <div @click="(event) => nextSlide(event)" :class="horizontal?.hasNext ? '' : 'dim'">
          <img src="/assets/images/right-arrow-base-sliding.svg" alt="" />
        </div>
      </div>
    </div>

    <div class="base-slide">
      <VueHorizontal
        ref="horizontal"
        :button="false"
        v-if="router.currentRoute.value.path !== '/casino/providers'"
      >
        <div v-for="slide in providers">
          <div class="provider-group">
            <div class="provider" :title="slide.name" @click="filterByProvider(slide)">
              <img v-if="slide.image?.url" :src="`${slide?.image?.url}`" class="image" />
              <div class="unknown" v-else>
                <BaseIcon name="null" color="var(--be-secondary-text-color)" />
                <div class="center">{{ slide.name }}</div>
              </div>
            </div>
            <div class="game-count-slider">
              <BaseIcon name="games" color="var(--be-secondary-text-color)" size="16" />
              <div class="game-heading">Games</div>
              <div class="count">{{ slide.gamesCount }}</div>
            </div>
          </div>
        </div>
      </VueHorizontal>
    </div>
    <div v-if="router.currentRoute.value.path === '/casino/providers'" class="games-grid">
      <div v-for="slide in sortedProviders" :key="slide.id">
        <div class="provider" :title="slide.name" @click="filterByProvider(slide)">
          <img v-if="slide.image?.url" :src="`${slide?.image?.url}`" class="image" />
          <div class="unknown" v-else>
            <BaseIcon name="null" color="var(--be-secondary-text-color)" />
            <div class="center">{{ slide.name }}</div>
          </div>
        </div>
        <div class="game-count">
          <BaseIcon name="games" color="var(--be-secondary-text-color)" size="16" />
          <div class="game-heading">Games</div>
          <div class="count">{{ slide.gamesCount }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "@/casino/assets/scss/casino-styles.scss";

.base-slide {
  padding: 0 16px 16px 16px;
}

.dim {
  opacity: 0.3;
}

.games-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(115px, 1fr));
  padding: 14px 16px 30px 16px;
  gap: 16px;
}

@media screen and (max-width: 550px) {
  .base-slide {
    padding: 0px;
  }
  .games-grid {
    gap: 8px;
    padding: 0px;
  }
}

.unknown {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: Rubik-med;
  font-size: 12px;
  gap: 10px;

  .null-img {
  }
}

.provider-group {
  display: flex;
  flex-direction: column;
  margin-right: 8px;

  .provider {
    width: 120px;
  }
}

.game-count {
  display: flex;
  gap: 0 10px;
  justify-content: center;
  font-size: 12px;
  font-family: Rubik-med;
  align-items: center;
  padding: 7px 0px;
  background: var(--be-secondary-btn);
  border-radius: 0 0 5px 5px;
}

.game-heading {
  color: var(--be-secondary-text-color);
  font-family: Rubik;
}

.game-count-slider {
  display: flex;
  gap: 0 4px;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  padding: 7px 0px;
  background: var(--be-secondary-btn);
  border-radius: 0 0 5px 5px;
  width: 120px;
  font-family: Rubik;

  .count {
    font-family: Rubik-med;
  }
}

.list-wrapper {
  margin: 10px 0px;
  background-color: var(--be-big-card-bg);
  border-radius: 8px;

  .list-header {
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
    margin-bottom: 10px;
    padding-top: 16px;
  }

  .list-view {
    display: flex;
    gap: 0 20px;
    align-items: center;

    font-size: 16px;
    cursor: pointer;
    color: var(--be-primary-btn);
    font-family: Rubik;
  }

  .list-title {
    display: flex;
    align-items: center;
    margin: 0 0 4px 19px;
    font-size: 16px;
    font-family: Rubik-med;
    color: var(--be-secondary-btn-label);

    img {
      max-width: 24px;
      max-width: 24px;
      height: 24px;
    }

    span {
      margin: 4px 0 0 8px;
    }
  }
}

@media screen and (max-width: 550px) {
  .list-wrapper {
    margin: 8px 16px;
    background: transparent;

    .list-title {
      margin: 0 0 4px 0px;
    }
  }
}

.center {
  text-align: center;
  overflow-wrap: anywhere;
}

.provider {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--be-small-card-bg);
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  padding: 5px 10px;
  height: 88px;
  .mobile & {
    height: 88px;
  }

  .image {
    max-width: 100%;
    max-height: 100%;
    padding: 2px 5px;
  }
}
</style>
