<script setup>
import EventCoupons from "@/sportsbook/components/EventCoupons.vue";

// import FeaturedLiveMatch from "@/components/FeaturedLiveMatch.vue";

const props = defineProps({
  sport: {
    type: Object,
    required: true,
  },
  league: {
    type: Object,
    required: true,
  },

  marketGroups: {
    type: Array,
    default: () => [],
  },
  type: {
    type: String,
  },
});
</script>

<template>
  <div>
    <EventCoupons
      v-for="match in Object.values(props.league.matches)"
      :key="match.id"
      :match="match"
      :market-groups="props.marketGroups"
      :type="props.type"
      :league-name="props.league.name"
      :league-id="props?.league?.id"
      :league-country-id="props?.league?.country?.id"
      :sport="props.sport"
    />
  </div>
</template>
