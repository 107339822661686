<script setup>
import { computed, ref } from "vue";
import { useI18n } from "@/app/composables/useI18n";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { useCurrencies } from "@/app/composables/useCurrencies";
import { convertToLocalTime } from "@/sportsbook/utils/general";
import StakeDetail from "./StakeDetail.vue";
import SportStakeIcon from "@/app/components/MyBets/Bets/SportStakeIcon.vue";
const { ticket } = defineProps({
  ticket: {
    type: Object,
    required: true,
  },
});
const iconBase = "/assets/images/bets/mybets/";
const open = ref(false);

const { t } = useI18n();
const { name, ticker } = useCurrencies();

const odds = computed(() => {
  return ticket.odds > 0 ? ticket.odds : ticket.wagers[0].odds;
});

function getType(ticket) {
  if (ticket.freeBetId) {
    return "free-bet";
  }

  return "normal";
}
</script>

<template>
  <div class="open-bet">
    <div class="stakes-header" @click="open = !open">
      <div class="flex">
        <SportStakeIcon :status="ticket.status" :type="getType(ticket)" />
        <div class="flex stake">
          <div class="stake-name">
            <span
              :class="`stake-no ${
                ticket.status == 'open'
                  ? 'stake-no--open'
                  : ticket.status == 'won'
                  ? 'stake-no--win'
                  : 'stake-no--lose'
              }`"
              >{{ ticket.status == "won" ? "+" : ticket.status == "open" ? "" : "-"
              }}{{ ticket.stake }}</span
            >
          </div>
          <BaseIcon :name="ticker(ticket?.currencyId).toLowerCase()" />
        </div>
        <div class="paraly">
          {{ t(`${ticket.wagers.length > 1 ? "Multiple" : "Single"}`) }}
        </div>
      </div>
      <img :src="`${iconBase}${open ? 'arrow-down' : 'arrow-right'}.svg`" alt="" />
    </div>
    <div v-if="open" class="stake-detail">
      <div class="stake-detail-header">
        <div class="flex">
          <div class="odds">
            <div class="label">
              {{ t("bets.odds") }}
            </div>
            <div>{{ odds.toFixed(2) }}</div>
          </div>
          <div class="flex">
            <div class="label">
              {{ ticket.status === "won" ? t("bets.paid_winnings") : t("bets.potencial_win") }}
            </div>
            <div v-if="ticket.freeBetId == null">
              {{ ticket.profit }}
            </div>
            <div v-else>
              {{ ticket.profit - ticket.stake }}
            </div>
          </div>
        </div>
        <div class="selection-label">
          {{ ticket.wagers.length }}
          {{ ticket.wagers.length > 1 ? t("bets.selections") : t("bets.selection") }}
        </div>
      </div>
      <div v-for="(wager, j) in ticket.wagers" :key="j" class="wager">
        <StakeDetail :wager="wager" />
      </div>

      <div class="stake-bet">
        <div class="flex">
          <div class="label">
            {{ t("bets.bet_id") }}
          </div>
          <div>{{ ticket.code }}</div>
        </div>
        <div class="flex">
          <div class="label">
            {{ t("bets.placed") }}
          </div>
          <div>{{ convertToLocalTime(ticket.createdAt) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.stake-detail {
  font-size: 12px;
  .stake-detail-header {
    display: flex;
    justify-content: space-between;
    padding: 13px 0;
  }
  .selection-label {
    color: #1382eb;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .label {
    color: rgba(255, 255, 255, 0.5);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-right: 4px;
  }
}

.wager {
  border-bottom: 1px solid var(--be-small-card-bg);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.odds {
  display: flex;
  border-right: 1px solid var(--be-small-card-bg);
  padding-right: 8px;
  margin-right: 8px;
}

.stake-bet {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}
.stakes-header {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  background: var(--be-small-card-bg);
  padding-right: 8px;
}

.currency-icon {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.stake-name {
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  font-size: 14px;
}

.ml-8 {
  margin-left: 8px;
}

.paraly {
  color: rgba(255, 255, 255, 0.5);
  padding: 0 8px;
  display: flex;
  align-items: center;
  // border-right: 1px solid var(--be-big-card-bg);
  margin: 7px 0;
  font-size: 14px;
}
.stake {
  border-right: 1px solid var(--be-big-card-bg);
  padding-right: 8px;
  margin: 7px 0 7px 7px;
  display: flex;
  align-items: center;
}

.stake-no {
  margin: 0 8px;
}

.free-bet-title {
  white-space: nowrap;
}

.flex {
  display: flex;
}
.selections {
  display: flex;
  justify-content: space-between;
}
.wagerstatus {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}
.stake-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px 0 0 4px;
}

.stake--open {
  background: #ed8730;
}
.stake--win {
  background: #07863a;
}

.stake--lose {
  background: #d80027;
}

.stake-no--open {
  color: white;
}

.status--open {
  color: #ed8730;
}
.stake-no--lose {
  color: #d80027;
}

.stake-no--win {
  color: #07863a;
}

.status--void {
  color: #cdcdcd;
}
.open-bet {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 10px;
  margin: 10px 16px;
}
</style>
