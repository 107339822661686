<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import { useRoute } from "vue-router";
import { t } from "@/app/composables/useI18n";
import { api } from "@/services";

const props = defineProps({
  show: Boolean,
});
const emit = defineEmits(["open-deposit-modal", "close"]);

const transaction = ref({});
const wallet = ref({
  balance: 0,
  currency: {
    sign: "",
  },
});
const route = useRoute();
const transactionId = route.query.__txId;
let refreshIntervalId = null;

async function loadTransaction(id) {
  const result = await api.finance.userTransactions({
    id,
    paginator: {
      cp: 1,
      rpp: 1,
    },
  });

  if (result.transactions.length > 0) {
    transaction.value = result.transactions[0];

    const wallets = await api.finance.userWallets({
      id: transaction.value.walletId,
    });

    if (wallets.length > 0) {
      wallet.value = wallets[0];
    }

    if (transaction.value.status === "initiated" && refreshIntervalId === null) {
      refreshIntervalId = setInterval(() => {
        loadTransaction(transactionId);
      }, 5000);
    } else if (transaction.value.status !== "initiated" && refreshIntervalId !== null) {
      clearInterval(refreshIntervalId);
    }
  }
}

onMounted(() => {
  if (transactionId !== null && transactionId !== undefined) {
    loadTransaction(transactionId);
  }
});

onUnmounted(() => {
  if (refreshIntervalId !== null) {
    clearInterval(refreshIntervalId);
  }
});
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <div class="close-btn" @click="() => emit('close', false)">
          <div>{{ t("general.close") }}</div>
          <img src="/assets/images/close-modal-icon.svg" alt="" />
        </div>
        <div class="modal-body">
          <div class="deposit-view" v-if="transaction.id !== undefined">
            <h3 v-if="transaction.status === 'initiated'">
              {{ t("finance.initiated_transaction_title") }}
            </h3>
            <h3 v-else-if="transaction.status === 'success'">
              {{ t("finance.success_transaction_title") }}
            </h3>
            <h3 v-else-if="transaction.status === 'cancelled'">
              {{ t("finance.cancelled_transaction_title") }}
            </h3>
            <h3 v-else-if="transaction.status === 'error'">
              {{ t("finance.error_transaction_title") }}
            </h3>

            <div class="initiated" v-if="transaction.status === 'initiated'">
              <table>
                <tr>
                  <td><img src="/assets/images/loading.gif" alt="" class="loading" /></td>
                  <td>{{ t("finance.initiated_transaction_message") }}</td>
                </tr>
              </table>
            </div>
            <div class="success" v-else-if="transaction.status === 'success'">
              {{ t("finance.success_transaction_message") }}
            </div>
            <div class="cancelled" v-else-if="transaction.status === 'cancelled'">
              {{ t("finance.cancelled_transaction_message") }}
            </div>
            <div class="error" v-else-if="transaction.status === 'error'">
              {{ t("finance.error_transaction_message") }}
            </div>

            <div class="details">
              <div>
                <span class="label">{{ t("finance.transaction_id") }}:</span>
                <span>{{ transaction.id }}</span>
              </div>
              <div>
                <span class="label">{{ t("finance.amount") }}:</span>&nbsp; {{ wallet.currency.sign
                }}{{ transaction.amount }}
              </div>
              <div>
                <span class="label">{{ t("finance.current_balance") }}:</span>&nbsp;
                {{ wallet.currency.sign }}{{ wallet.balance }}
              </div>
              <div>
                <span class="label">{{ t("account.dob_date") }} & {{ t("finance.time") }}:</span
                >&nbsp;
                {{ transaction.createdAt }}
              </div>
              <div v-if="transaction.desc !== null && transaction.desc !== ''">
                <span class="label">{{ t("finance.description") }}:</span> {{ transaction.desc }}
              </div>
              <div v-if="transaction.message !== null">
                <span class="label">{{ t("finance.message") }}:</span> {{ transaction.message }}
              </div>
            </div>
          </div>
          <div class="deposit-view" v-else>
            <h3>{{ t("finance.deposit_not_found") }}</h3>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.registration-terms {
  font-size: 14px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(13, 12, 44, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  transition: opacity 0.3s ease;
}

.close-btn {
  color: white;
  position: absolute;
  top: -32px;
  right: 3px;
  display: flex;
  gap: 0 5px;
  cursor: pointer;
}

.modal-container {
  width: 491px;
  margin: auto;
  padding: 20px 20px;
  border-radius: 20px;
  background: var(--be-bg);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  position: relative;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  border-radius: 8px;
  background: var(--be-big-card-bg);
}

.modal-body {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 16px;

  h3 {
    margin-top: 0px;
  }

  .initiated {
    color: #3abaf5;
    .loading {
      height: 50px;
    }
  }
  .success {
    color: #3af585;
  }
  .cancelled {
    color: #f5883a;
  }
  .error {
    color: #ec5269;
  }

  .details {
    margin-top: 2em;
    .label {
      color: #f5a83a;
    }
  }
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
