function cfl(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const ellipsis = function (value, limit) {
  value = String(value);
  if (typeof value === "string" && value.length > limit) {
    value = `${value.slice(0, limit)}...`;
  }

  return value;
};

function camelToSnakeCaseWithNumbers(camelStr) {
  return camelStr
    .replace(/([a-z])([A-Z])/g, "$1_$2") // Convert camelCase to snake_case
    .replace(/([a-zA-Z])(\d)/g, "$1_$2") // Add underscore before numbers
    .toLowerCase();
}

export { cfl, ellipsis, camelToSnakeCaseWithNumbers };
