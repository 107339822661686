<script setup lang="ts">
import { computed, ref } from "vue";
import WalletSelectOption from "./WalletSelectOption.vue";
import BaseSeparator from "@/app/components/ui/BaseSeparator.vue";
import BaseDropdown from "@/app/components/ui/BaseDropdown.vue";
import { toast } from "@/app/utils/notification";

import { useGameHelper } from "@/casino/composables/useGameHelper.js";
import { useUserService } from "@/user-account/composables/useUserService";
import { useCurrencies } from "@/app/composables/useCurrencies";
import { useI18n } from "@/app/composables/useI18n";

const props = defineProps({
  showBalance: {
    type: Boolean,
    default: true,
  },
});
const { t } = useI18n();
const { wallets, activeWallet, user } = useUserService();
const { currencies } = useCurrencies();
const { isGameSession } = useGameHelper();
const baseDropdown = ref();

const walletSections = computed(() => {
  const fiat = wallets.value.filter((wallet) => {
    const currency = currencies.value.find((item) => item.id === wallet.currencyId);
    return currency.type === "fiat";
  });

  let crypto = wallets.value.filter((wallet) => {
    const currency = currencies.value.find((item) => item.id === wallet.currencyId);
    return currency.type === "crypto";
  });

  if (user.value.data.preferences.hideWalletsWithZeroBalances) {
    crypto = crypto.value.filter((item) => item.balance === 0);
  }
  return { fiat, crypto, total: [] };
});

function setActiveWallet(wallet) {
  if (!isGameSession.value) {
    activeWallet.value = wallet;
    baseDropdown.value.close();
  } else {
    toast.warn(t("casino.balance_switch_forbidden"));
  }
}
</script>

<template>
  <div v-if="activeWallet" class="wallet-select">
    <BaseDropdown ref="baseDropdown" class="wallet-select-dropdown">
      <template #label>
        <WalletSelectOption :wallet="activeWallet" class="selected-option" />
      </template>
      <template #panel>
        <div class="wallets">
          <div v-for="(sectionWallets, sectionName) in walletSections" :key="sectionName">
            <div v-if="sectionWallets.length > 0">
              <BaseSeparator :title="sectionName" />

              <WalletSelectOption
                v-for="wallet in sectionWallets"
                :key="wallet.id"
                :wallet="wallet"
                :show-balance="props.showBalance"
                :class="sectionName"
                @click="
                  sectionName !== 'total' && wallet.type === 'funds' && setActiveWallet(wallet)
                "
              />
            </div>
          </div>
        </div>
      </template>
    </BaseDropdown>
  </div>
</template>

<style lang="scss" scoped>
.wallet-select {
  // width: 9em;
  height: 40px;
  margin-right: 15px;
  border: 1px solid #424361;
  // border-radius: 3px;

  // &.balance-mode {
  //   background-color: var(--be-small-card-bg);
  //   border: 0;
  // }
  .wallet-select-dropdown {
    margin-top: 6px;
  }
  .wallets {
    width: fit-content;
    background-color: white;
    padding: 1em;
    border-radius: 3px;
    color: #333;
    max-height: 80vh;
    overflow-y: auto;
    width: 100%;
  }
}
</style>

<!-- <style scoped>
:deep(.mobile .dropdown-panel) {
  position: fixed;
  max-width: 100vw;
  top: 70px;
  left: 3%;
}
</style> -->
