import { jsonRpc } from "@/services/json-rpc/jsonrpc";

const kyc = {
  async redirect() {
    const response = await jsonRpc({
      method: "users.kyc:redirect-link",
    });

    window.location = response.redirectUrl;
  },
};

export default kyc;
