<script setup>
import { computed } from "vue";
import { useBetStore } from "/store";
import Wallets from "../MyBets/Wallets/index.vue";
import Bets from "../MyBets/Bets/index.vue";
import AccountDetails from "../MyBets/AccountDetails/index.vue";
import ReferAndEarn from "@/app/components/MyBets/refer-and-earn/ReferAndEarn.vue";

const { section } = defineProps({
  section: {
    type: Object,
    required: true,
  },
});

defineEmits(["toggleSection"]);

const store = useBetStore();

const openSection = computed(() => {
  return store.getSection;
});

const open = computed(() => {
  return openSection.value === section.value;
});

const iconSrc = computed(() => {
  const iconBase = "/assets/images/bets/";
  const iconName = section.icon;

  return `${iconBase}${iconName}.svg`;
});

const statusIcon = computed(() => {
  return open.value ? "/assets/images/close-icon.svg" : "/assets/images/open-icon.svg";
});

const components = {
  wallet: Wallets,
  bet: Bets,
  account: AccountDetails,
  referAndEarn: ReferAndEarn,
};
</script>

<template>
  <div class="detail">
    <div class="detail-padding" @click="$emit('toggleSection', section)">
      <div class="detail-option-title">
        <img :src="iconSrc" alt="" />

        <div>
          {{ section.title }}
        </div>
      </div>

      <img :src="statusIcon" alt="" />
    </div>

    <component :is="components[openSection]" v-if="open" />
  </div>
</template>

<style lang="scss" scoped>
.detail {
  border-bottom: 1px solid var(--be-small-card-bg);

  .detail-padding {
    display: flex;
    justify-content: space-between;
    padding: 10px 16px;
    cursor: pointer;
  }
}

.detail-option-title {
  display: flex;
  gap: 0 8px;
}
</style>
