import OneComponentLayout from "@/app/layouts/OneComponentLayout.vue";
import GeoBlockedView from "@/app/views/GeoBlockedView.vue";
import MaintenanceView from "@/app/views/MaintenanceView.vue";
import ComingSoonView from "@/app/views/ComingSoonView.vue";

function getInitialRoute() {
  const modules = import.meta.env.VITE_APP_MODULES?.split(",");

  return { name: modules?.[0] };
}

export const utilityRoutes = {
  path: "/",
  component: OneComponentLayout,
  children: [
    {
      path: "",
      name: "/",
      redirect: getInitialRoute(), // initialized in useConfig.ts
    },
    {
      path: "/maintenance",
      name: "maintenance",
      component: MaintenanceView,
    },
    {
      path: "/coming-soon",
      name: "coming-soon",
      component: ComingSoonView,
    },
    {
      path: "/geo-blocked",
      name: "geo-blocked",
      component: GeoBlockedView,
    },
  ],
};
