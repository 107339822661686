<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useI18n } from "@/app/composables/useI18n";
import { useConfig } from "@/app/composables/useConfig";
import { brands } from "@/app/utils/brands";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { useLogoBanner } from "../composables/useLogoBanner";
import PdfModal from "./modal/PdfModal.vue";

const { t } = useI18n();
const showPdfModal = ref(false);

const { footerLogo, name, address, title, licenseToken } = brands[import.meta.env.VITE_APP_BRAND];

const links = [
  {
    url: `/docs/${name}/t&c.pdf`,
    title: "general.general_t_c",
  },
  {
    url: `/docs/${name}/privacy-policy.pdf`,
    title: "general.privacy_policy",
  },
  {
    url: `/docs/${name}/kyc.pdf`,
    title: "account.kyc",
  },
  {
    url: `/docs/${name}/aml.pdf`,
    title: "general.aml_policy",
  },
  {
    url: `/docs/${name}/account.pdf`,
    title: "general.account_payouts_bonuses",
  },
];

const pageLinks = [
  {
    url: `/docs/${name}/responsible-betting.pdf`,
    title: "general.resp_betting",
  },
  {
    url: `/docs/${name}/self-exclusion.pdf`,
    title: "self_exclusion.self",
  },
  {
    url: `/docs/${name}/dispute.pdf`,
    title: "general.dispute_resolution",
  },
];

const screenWidth = ref(window.innerWidth);

function handleResize() {
  screenWidth.value = window.innerWidth;
}

onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize(); // Initial check
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});

const licenceUrl = `https://cert.gcb.cw/certificate?id=${licenseToken}`;

const { isDrawerOpen } = useConfig();
const { logo } = useLogoBanner();
const pdfLink = ref("");

const handlePdf = (link) => {
  showPdfModal.value = true;
  pdfLink.value = link;
};
</script>

<template>
  <PdfModal :show="showPdfModal" @close="() => (showPdfModal = false)" :link="pdfLink" />
  <footer :class="isDrawerOpen ? 'app-footer' : 'ml-313 app-footer'">
    <router-link to="/">
      <img :src="logo" alt="" class="brand-logo" :class="{ 'mobile-logo': screenWidth < 568 }" />
    </router-link>
    <div class="mid-footer">
      <div class="lists">
        <div class="list">
          <div class="list-heading">
            {{ t("general.play_safety") }}
          </div>
          <a
            v-for="pageLink in pageLinks"
            :key="pageLink.url"
            class="link-titles"
            @click="handlePdf(pageLink.url)"
          >
            {{ t(pageLink.title) }}
          </a>
        </div>
        <div class="list">
          <div class="list-heading">
            {{ t("general.t_and_c") }}
          </div>
          <a v-for="link in links" :key="link.url" @click="handlePdf(link.url)" class="link-titles">
            {{ t(link.title) }}
          </a>
        </div>
      </div>
      <hr v-if="screenWidth < 568" class="top-separator" />
      <div class="copy-right">
        Copyright © 2024 {{ title }}. All rights reserved.
        <div class="copy-detail">
          {{ title }}.com is operated by Temple Holdings N.V. (Reg no. 159511), {{ address }}, which
          having all the rights to operate the gaming software and/or offer games of chance under
          the Curacao License no. 365/JAZ. Payments are processed by Mammillaria Investments Limited
          (Reg no. ΗΕ 428232) registered in Cyprus, acting as the payments agent as per agreement
          between the two companies.
        </div>
        Crypto trading is not gambling, and therefore not covered by our gaming license.
      </div>
    </div>
    <div class="gaming-curacao">
      <a :href="licenceUrl" target="_blank">
        <img
          src="https://seal.cgcb.info/1c0246df-1aa7-485a-a24c-21ae5e730000"
          width="150"
          height="85"
          boarder="0"
          alt="Gaming Curacao: Click to Validate"
        />
      </a>

      <BaseIcon name="18+" size="43" color="var(--be-secondary-text-color)" />
    </div>
    <hr v-if="screenWidth > 568" class="top-separator" />
  </footer>
</template>

<style lang="scss" scoped>
.app-footer {
  color: var(--be-footer-text-color);
  background-color: var(--be-bg);
  position: relative;
  margin: 51px 16px 0 16px;
  margin-left: 97px;

  .top-separator {
    margin-top: 24px;
    margin-bottom: 24px;
    border: 1px solid #213743;
  }
  .link-titles {
    color: var(--be-secondary-text-color);
    cursor: pointer;
  }

  @media screen and (max-width: 500px) {
    .top-separator {
      margin-top: 48px;
      margin-bottom: 24px;
      border: 1px solid #213743;
    }
  }

  .copy-right {
    color: var(--be-secondary-text-color);
    font-family: Rubik;
    font-size: 14px;
    margin-bottom: 24px;
    width: 50%;

    .copy-detail {
      margin: 10px 0;
    }
  }

  @media screen and (max-width: 500px) {
    .copy-right {
      width: initial;
    }
  }

  .mid-footer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 40px;
    justify-content: space-between;
    margin-right: 40px;
  }

  @media screen and (max-width: 500px) {
    .mid-footer {
      display: flex;
      flex-direction: column;
      margin-top: 0px;
      justify-content: initial;
      margin-right: 0px;
    }
  }

  .brand-logo {
    width: 171px;
  }
  .top-links {
    margin-top: 10px;
    text-align: center;
    padding-left: 20%;
    padding-right: 20%;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
      color: var(--be-footer-text-color);
      opacity: 0.6;
      white-space: nowrap;
      transition: all 0.3s ease-in-out;
      &:hover {
        opacity: 1;
      }
      &:after {
        content: "|";
        opacity: 0.6 !important;
        margin: 10px;
        &:last-child {
          content: "";
        }
        .mobile & {
          content: "";
        }
      }
    }
    &:last-child .separator {
      display: none;
    }
  }

  .lists {
    display: flex;
    justify-content: space-between;
    color: #557086;
    font-family: Rubik;
    width: 27%;

    .list {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .list-heading {
      color: var(--be-primary-btn);
      font-size: 16px;
      font-family: Rubik-med;
      cursor: pointer;
      text-transform: uppercase;
    }
  }

  @media screen and (max-width: 500px) {
    .lists {
      margin-top: 48px;
      width: initial;
    }
  }

  .bottom-text {
    margin-top: 5px;
    text-align: center;
    padding-left: 20%;
    padding-right: 20%;
    font-size: 12px;
    .tablet & {
      padding: 5%;
    }
  }

  .email {
    display: block;
    text-align: center;
    margin: 10px;
    font-size: 13px;
  }

  @media screen and (max-width: 500px) {
    .email {
      margin-bottom: 80px;
    }
  }

  .gaming-curacao {
    display: flex;
    gap: 40px;
    align-items: center;
    // justify-content: center;
  }

  @media screen and (max-width: 500px) {
    .gaming-curacao {
      margin-bottom: 140px;
    }
  }
}

.ml-313 {
  margin-left: 313px;
}

@media screen and (max-width: 500px) {
  .app-footer {
    margin: 24px 16px 0 16px;
  }
}
</style>
