<script setup>
import { computed } from "vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { useI18n } from "@/app/composables/useI18n";

const { ticket } = defineProps({
  ticket: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();

function getBadgeColor(wager) {
  if (wager.status === "void") {
    return "grey";
  } else if (wager.status === "won" || wager.status === "half_won") {
    return "green";
  } else if (wager.status === "lost" || wager.status === "half_lost") {
    return "red";
  } else {
    return "";
  }
}
function getBadgeIcon(wager) {
  if (wager.status === "void") {
    return "circle";
  } else if (wager.status === "won" || wager.status === "half_won") {
    return "check";
  } else if (wager.status === "lost" || wager.status === "half_lost") {
    return "close";
  } else {
    return "";
  }
}
const odds = computed(() => {
  return ticket.odds > 0 ? ticket.odds : ticket.wagers[0].odds;
});
</script>

<template>
  <div class="ticket-details">
    <div class="wagers-details ticket-item">
      <div class="pot-winnings">
        <div class="odds-sum">
          {{ t("bets.odds") }} <span>{{ odds.toFixed(2) }}</span>
        </div>
        <div class="divider">|</div>
        <div class="d-flex align-center ml-1">
          {{ ticket.status === "won" ? t("bets.paid_winnings") : t("bets.potencial_win") }}
          <span class="d-flex align-center ml-1">
            <!-- <div> -->
            {{ ticket.profit }}
            <!-- {{ Number(ticket.profit).toFixed(ticket.wallet.currency.places) }} -->
            <!-- </div> -->
          </span>
        </div>
      </div>
      <div class="wagers-count">
        {{ ticket.wagers.length }}
        {{ ticket.wagers.length > 1 ? t("bets.selections") : t("bets.selection") }}
      </div>
    </div>
    <div v-for="(wager, j) in ticket.wagers" :key="j" class="wager ticket-item">
      <div class="wager-name d-flex align-center justify-space-between">
        <div>
          {{ t("bets.selection") }}
          <span> {{ wager.header }} {{ wager?.data?.line?.outcomeName }} </span>
        </div>
        <div v-if="getBadgeIcon(wager)" class="ticket-status-icon" :class="getBadgeColor(wager)">
          <!-- <v-icon>{{ getBadgeIcon(wager) }}</v-icon> -->
          <BaseIcon :name="getBadgeIcon(wager)" size="20" />
        </div>
      </div>
      <div class="wager-market">
        {{ t("bets.market") }}
        <span>{{ t(`markets.${wager.marketName.toLowerCase().replaceAll(" ", "-")}`) }}</span>
      </div>
      <div class="wager-odds">
        {{ t("bets.odds") }}
        <span>{{ wager.odds.toFixed(2) }}</span>
      </div>
      <div class="wager-event">
        {{ t("bets.event") }}
        <span>
          {{ wager.eventName }},
          {{ wager.data.eventStartDate }}
          <!-- {{ wager.data.eventStartDate | moment("Do MMMM HH:mm") }} -->
        </span>
      </div>
    </div>
    <div class="ticket-item-footer ticket-item">
      <div class="ticket-id">
        {{ t("bets.bet_id") }} <span>{{ ticket.code }}</span>
      </div>
      <div class="placed-date">
        {{ t("bets.placed") }}
        <span>{{ ticket.createdAt }}</span>
        <!-- <span>{{ ticket.createdAt | moment("Do MMMM HH:mm") }}</span> -->
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.wagers-details,
.ticket-item-footer,
.pot-winnings {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ticket-status-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: block;
  padding: 2px 0 0 !important;
  line-height: 19px;
  font-weight: 600;
  .v-icon {
    font-size: 12px;
    padding-left: 4px;
  }
  &.red {
    background-color: #f44336;
  }
  &.green {
    background-color: green;
  }
  &.grey {
    background-color: grey;
  }
}

.ticket-details {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin: 5px;
  // padding: 5px;
  font-size: 13px;
  background-color: var(--be-small-card-bg);
  line-height: 1.5;
}

.ticket-item {
  padding: 5px 10px !important;
  color: #979797;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 13px;
}
.ticket-item:nth-child(odd) {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.ticket-item span {
  color: #ffffff;
}
.ticket-details {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.wagers-details.ticket-item {
  border-right: 0;
}
.ticket-item-footer.ticket-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-left: 0;
  border-right: 0;
}
.wagers-count {
  color: #ff853c;
  font-weight: 600;
  text-transform: capitalize;
}

.divider {
  color: #ffffff;
  margin: 0 5px;
}

.odds-sum,
.wager-name {
  position: relative;
  font-size: 13px;
}

@media screen and (max-width: 500px) {
  .odds-sum,
  .wager-name,
  .ticket-details,
  .ticket-item {
    font-size: 11px;
  }

  .placed-date,
  .ticket-id {
    font-size: 12px;
  }
}

@media screen and (max-width: 380px) {
  .ticket-details {
    font-size: 12px;
  }

  .pot-winnings {
    width: 170px;
  }

  .wagers-count {
    font-size: 10px;
  }
}
</style>
