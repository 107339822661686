<script setup>
import { ref, watch } from "vue";
import { useBetStore } from "../../../store";
import TicketList from "@/sportsbook/components/ticket-history/TicketList.vue";
import ProgressLoader from "@/app/components/ui/ProgressLoader.vue";
import { useI18n } from "@/app/composables/useI18n";
import { useUserService } from "@/user-account/composables/useUserService";
import BetSlip from "@/app/components/BetSlip/BetSlip.vue";
// import BetSlip from "./betslip/BetSlip.vue";

const { activeWallet, isAuthenticated } = useUserService();
const { t } = useI18n();

// const header = ref("betSlip");
const loading = ref(false);
const selectedTab = ref(0);
const store = useBetStore();

const tabs = [
  {
    title: t("sportsbook.betslip"),
  },
  {
    title: t("sportsbook.open_bets"),
  },
];

// function l(l) {
// }
// async function onOpenMyBets() {
//   if (user.isAuthenticated) {
//     await this.$router.push({ name: "login" });
//   }
//   this.header = "myBets";
// }

watch(selectedTab, () => {
  if (selectedTab.value === 1 && !isAuthenticated()) {
    // router.push({ name: "login" });
    store.handleShowLogin();
  }
});
</script>

<template>
  <div class="information-panel-column">
    <div class="information-panel">
      <!-- <TabsHeader v-model="selectedTab" :tabs="tabs" class="tabs" @update="selectedTab = $event" />
      <div class="tabs">
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          class="tab"
          :class="{ active: selectedTab === index }"
          @click="selectedTab = index"
        >
          {{ tab.title }}
        </div>
      </div>
      <div class="bet-slip-wrapper-header">
        <div
          class="bet-slip-header pointer"
          :class="{ 'active-header': header === 'betSlip' }"
          @click="header = 'betSlip'"
        >
          {{ t("sportsbook.betslip") }}
        </div>
        <div
          class="my-bets-header pointer"
          :class="{ 'active-header': header === 'myBets' }"
          @click="onOpenMyBets"
        >
          {{ header === "myBets" ? t("sportsbook.open_bets") : t("sportsbook.mybets") }}
        </div>
      </div> -->
      <ProgressLoader :loading="loading" />
      <BetSlip v-if="selectedTab === 0" />
      <div v-if="selectedTab === 1">
        <div class="title">
          <router-link
            v-if="!['sports.mybets', 'sports.mybets.settled'].includes($route.name)"
            :to="{ name: 'sports.mybets' }"
          >
            {{ t("sportsbook.full_bet_history") }}
          </router-link>
          <span v-else>
            {{ activeWallet ? activeWallet.currency.ticker : "" }}
            {{ t("sportsbook.open_bets") }}
          </span>
        </div>
        <TicketList />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title {
  padding: 10px;
  text-align: center;
  // background-color: #19183b;
  font-size: 14px;
}
.information-panel-column {
  flex: 1 1 auto;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: var(--betslip-column-width);
  // z-index: 60;
  margin-left: 10px;
  .information-panel {
    // display: flex;
    // flex-direction: column;
    // position: fixed;
    top: 350px;
    overflow: hidden;
    padding-bottom: 0;
    height: 100%;
    width: var(--betslip-column-width);
    .tabs {
      height: 3em;
    }
  }
}
</style>

<!-- <script>
mounted() {
    this.handleTabContentHeight();
    onListener(window, ['scroll', 'resize', 'orientationchange'], this.handleTabContentHeight);
    this.$root.$on('handleBetslipHeight', this.handleTabContentHeight);
  },
  beforeDestroy() {
    offListener(window, ['scroll', 'resize', 'orientationchange'], this.handleTabContentHeight);
    this.$root.$off('handleBetslipHeight', this.handleTabContentHeight);
  },
  methods: {
    setTab(tab) {
      this.activeTabKey = tab;
    },
    ...mapActions('layout', ['hideBetslip']),
    handleTabContentHeight() {
      const footer = document.querySelector('footer');
      if (footer) {
        const { y: footerTop } = footer.getBoundingClientRect();
        const { innerHeight: windowHeight, innerWidth: windowWidth } = window;
        const { height: headerHeight } = document.querySelector('.top-header').getBoundingClientRect();

        let tabContentHeight = 0;

        if (footerTop < windowHeight) {
          // tabContentHeight = footerTop;
          tabContentHeight = footerTop - 30;
        } else {
          tabContentHeight = windowHeight;
        }

        tabContentHeight = Math.max(tabContentHeight, headerHeight);

        if (windowWidth >= 1024) {
          if (this.isBetslipVisible) {
            this.hideBetslip();
          }
        }
        if (windowWidth >= 430) {
          this.$el.style[tabContentHeight ? 'setProperty' : 'removeProperty'](
            'max-height',
            pxOrValue(tabContentHeight),
          );
        }
      }
    },
  },
  </script> -->
