<script setup>
import { useNavigation } from "../../app/composables/useNavigations";
import { watch, onMounted } from "vue";
import { router } from "@/app/router";
import { countryMapping, capitalizeHyphenatedString } from "../utils/general";

const props = defineProps({
  countrySlug: {
    type: String,
    default: null,
  },
  sportSlug: {
    type: String,
    default: null,
  },
  timeFrame: {
    type: String,
    default: null,
  },
});

onMounted(() => {
  const scrollContainer = document.querySelector(`.brand-${import.meta.env.VITE_APP_BRAND}`);
  if (scrollContainer) {
    scrollContainer.scrollTo(0, 0);
  }
});

const { navigations, findTournamentsByCountryId, findSportByName } = useNavigation();
const countryName = findSportByName(capitalizeHyphenatedString(props.sportSlug))?.categories[
  props.countrySlug
]?.name;
const tournamnets = findTournamentsByCountryId(
  capitalizeHyphenatedString(props.sportSlug),
  props.countrySlug
);
function handleLeagueClick(tournament) {
  router.push({
    name: "sport.tournament",
    params: {
      tournamentSlug: tournament,
    },
  });
}
</script>

<template>
  <div class="header">
    <img
      class="league-flag"
      :src="`/assets/images/flags/${countryMapping[countryName] || 'xx'}.svg`"
      alt=""
    />

    <div class="comp-heading">
      {{ countryName }}
    </div>
  </div>
  <div
    v-for="tournamnet in Object.values(tournamnets)"
    class="comp-league"
    @click="() => handleLeagueClick(tournamnet.id)"
  >
    <div class="league-header">
      <div class="league-name">
        {{ tournamnet.name }}
      </div>
    </div>
    <img src="/assets/images/live/view-all.svg" alt="" />
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-bottom: 8px;
}
.comp-heading {
  color: white;
  font-size: 19px;
  margin: 20px 0;
  padding: 0 10px;
}

.league-flag {
  width: 32px;
  height: 25px;
  border: 1px solid white;
}

.comp-league {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid white;
  padding-bottom: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  padding-right: 7px;
  cursor: pointer;

  .league-header {
    display: flex;
    gap: 0 25px;
    width: 58%;
    align-items: center;
    .league-name {
      font-size: 15px;
      color: white;
    }
  }
}

@media screen and (max-width: 500px) {
  .comp-league {
    margin: 10px 14px 0 14px;
  }
}

.comp-country {
  display: flex;
  flex-wrap: wrap;
  gap: 25px 0;
  color: white;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid white;
  padding-bottom: 10px;

  .country {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 48%;
    margin-left: 10px;
    cursor: pointer;

    .country-logo {
      display: flex;
      gap: 0 25px;
      align-items: center;
    }
  }
}
</style>
