<script setup>
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { router } from "@/app/router";
import { useI18n } from "@/app/composables/useI18n";
import { useAuth } from "@/user-account/composables/useAuth";
import { userAccountSections } from "@/user-account/utils/user-account-sections";

const { logout } = useAuth();
const { t } = useI18n();
</script>

<template>
  <div class="my-account">
    <h2>
      {{ t("account.my_account") }}
    </h2>
    <div v-for="(section, i) in userAccountSections" :key="i">
      <!-- <template v-if="!section.disabled"> -->
      <div class="section-header">
        <span>{{ t(section.title) }}</span>
      </div>

      <div class="section">
        <div
          v-for="(item, index) in section.items"
          :key="index"
          class="section-btn"
          @click="item.route ? router.push({ name: item.route }) : () => 0"
        >
          <a v-if="item.link" :href="item.link" target="_blank">
            <BaseIcon :name="item.icon" size="40" />
            <span>{{ t(item.title) }}</span>
          </a>
          <template v-else>
            <BaseIcon :name="item.icon" class="icon" size="40" />
            <span>{{ t(item.title) }}</span>
          </template>
        </div>
      </div>
      <!-- </template> -->
    </div>
    <div class="logout" @click="logout">
      {{ t("account.logout") }}
      <BaseIcon name="arrow-right" size="30" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.my-account {
  // width: 90%;
  // font-weight: 600;
  margin-top: 4em;

  .notebook & {
    width: 70%;
  }

  .section-header {
    padding: 7px;
    margin: 20px 0 5px;
    background-color: var(--be-card-background-color);
  }

  .section {
    display: flex;
    align-items: center !important;
    justify-content: space-around !important;

    .section-btn {
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      background-color: var(--be-small-card-bg);
      border-radius: 3px;
      padding: 10px;
      margin: 0 5px;
      height: 7em;
      justify-content: center;
      cursor: pointer;

      a {
        color: var(--be-primary-text-color);
        text-decoration: none;
      }

      // .icon {
      // color: var(--be-primary-text-color);
      // }
      span {
        margin-top: 0.4em;
        font-size: 0.9em;
        // color: var(--be-primary-text-color);
      }
    }
  }

  .logout {
    display: flex;
    align-items: center;
    float: right;
    margin: 30px 10px 0 0;
    cursor: pointer;
    // color: var(--be-primary-text-color);
  }
}
</style>
