<script setup>
import ProgressLoader from "@/app/components/ui/ProgressLoader.vue";
import { useVirtualGamesService } from "@/virtual-games/composables/useVirtualGamesService.js";
import VirtualGamesGameList from "@/virtual-games/components/VirtualGamesGameList.vue";
import { brands } from "@/app/utils/brands";
import Banner from "@/app/components/Banner.vue";
import { useLogoBanner } from "@/app/composables/useLogoBanner";

const { loading } = useVirtualGamesService();
const { casinoBannerImg, virtualBannerMblImg } = brands[import.meta.env.VITE_APP_BRAND];
const { banners } = useLogoBanner();
</script>

<template>
  <article class="virtual-games-view">
    <Banner :imgs="banners?.virtuals || []" />
    <ProgressLoader :loading="loading" />
    <VirtualGamesGameList />
  </article>
</template>

<style scoped>
.banner-img {
  width: 100%;
}
.virtual-games-view {
  width: 100%;
  min-height: 70vh;
}
</style>
