<script setup>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-vue-v3";
import { signin, verifyCode } from "../../../user-account/api/index";
import { useI18n } from "@/app/composables/useI18n";
import { setErrors, validate } from "@/user-account/utils/input-rules/validation";
import PhoneLoginForm from "@/user-account/components/auth/PhoneLoginForm.vue";
import { api } from "@/services";
import { useAuth } from "@/user-account/composables/useAuth";
import { useUserService } from "@/user-account/composables/useUserService";
import CodeVerificationForm from "@/user-account/components/auth/CodeVerificationForm.vue";

const props = defineProps({
  show: Boolean,
  back: Boolean,
});

const emit = defineEmits(["close", "resetPassword", "openRegisterModal"]);
const { t } = useI18n();
const { data, error, isLoading, getData } = useVisitorData(
  { extendedResult: true },
  { immediate: true },
);

onMounted(async () => {
  await getData();
});

const fingerPrintSealedResult = computed(() => {
  if (isLoading.value) {
    return "Loading...";
  }
  if (error.value) {
    return `Error: ${error.value.message}`;
  }
  return data.value?.sealedResult || "No sealedResult available";
});

const { setToken } = useAuth();
const { setUser, selectWallet } = useUserService();
const router = useRouter();
const requestId = ref();

const mode = computed(() => (requestId.value ? "verification" : "registration"));

async function login(form) {
  if (!validate(form)) {
    return;
  }

  const formData = form.reduce((acc, field) => {
    acc[field.name] = field.value;
    return acc;
  }, {});

  try {
    const { data: loginData } = await signin({
      ...formData,
      sealedResult: fingerPrintSealedResult.value,
    });
    if (loginData?.requestId) {
      requestId.value = loginData?.requestId;
    } else if (loginData?.user) {
      setToken(loginData?.token);
      setUser(loginData?.user);

      if (loginData?.user?.lastSelectedWalletId) {
        selectWallet(+loginData?.user?.lastSelectedWalletId);
      }
      emit("close", false);
      router.push({ path: "/" });
    }
  } catch (error) {
    console.log("error", error);
    const { errors } = error || {};
    setErrors(form, errors);
  }
}

async function verify(form) {
  if (!validate(form)) {
    return;
  }

  try {
    const { data: verifyCodeResponse } = await verifyCode({
      code: form[0].value,
      requestId: requestId.value,
    });

    const { token, user } = verifyCodeResponse;
    if (token) {
      setToken(token);
      setUser(user);
      emit("close", false);
      requestId.value = null;
      router.push({ path: "/" });
    }
  } catch (error) {
    const { errors, details } = error || {};
    if (details) {
      timeLockedForSeconds.value = details?.timeLockedForSeconds;
    }
    if (errors) {
      setErrors(form, errors);
    }
  }
}

async function resend() {
  const result = await api.auth.resendCode({ requestId: requestId.value });

  if (result?.requestId) {
    requestId.value = result.requestId;
  }
}
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <div class="modal-detail">
          <div
            v-if="back"
            class="back-btn"
            @click="
              () => {
                emit('openRegisterModal', false);
                emit('close', false);
              }
            "
          >
            <img src="/assets/images/left-icon.png" class="left-icon" alt="">
            <div>{{ t("general.return_register") }}</div>
          </div>
          <div class="close-btn" @click="() => emit('close', false)">
            <div>{{ t("general.close") }}</div>
            <img src="/assets/images/close-modal-icon.svg" alt="">
          </div>

          <div class="modal-body">
            <PhoneLoginForm
              v-if="mode === 'registration'"
              @submit="login"
              @reset-password="emit('resetPassword')"
            />
            <CodeVerificationForm v-if="mode === 'verification'" @submit="verify" @resend="resend" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #cdcdcd;
  color: white;
  line-height: 0px;
  margin: 10px 0 20px;
}

h2 span {
  background: var(--be-big-card-bg);
  font-size: 14px;
  padding: 0 16px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(13, 12, 44, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  transition: opacity 0.3s ease;
}

.back-btn {
  color: white;
  position: absolute;
  top: -34px;
  left: 8px;
  display: flex;
  align-items: center;
  gap: 0 5px;
  cursor: pointer;
  .left-icon {
    width: 30px;
  }
}

.close-btn {
  color: white;
  position: absolute;
  top: -32px;
  right: 3px;
  display: flex;
  gap: 0 5px;
  cursor: pointer;
}

.modal-container {
  width: 491px;
  margin: auto;
  padding: 39px 20px 20px 20px;

  height: 100%;
  overflow: auto;
  // background: var(--be-bg);

  transition: all 0.3s ease;
  position: relative;
  display: flex;
}

.modal-detail {
  background: var(--be-bg);
  padding: 20px 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  margin-top: 13px;
  border-radius: 20px;
  margin: auto;
  position: relative;
  width: 100%;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  border-radius: 8px;
  background: var(--be-big-card-bg);
}

.modal-body {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 16px;

  .login-view {
    .forgot-password {
      font-size: 14px;
      margin-bottom: 20px;
      display: flex;
      justify-content: end;
      cursor: pointer;
      color: #3886ec;
      font-family: "Poppins";

      // transition: 0.4s all ease-in;
      // opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }

    .login-button {
      margin-top: 16px;
      // height: 3em;
    }

    .dont-have-account {
      font-size: 14px;
      margin-bottom: 10px;
      text-align: center;
    }

    .login-now {
      text-align: center;
      color: var(--be-primary-btn);
      cursor: pointer;
    }

    .register-button {
      margin: 1em 0 3em;
      // height: 3em;
    }

    .dont-have-account {
      font-size: 0.9em;
      margin-bottom: 10px;
    }
  }
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
