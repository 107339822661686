<script setup>
import { useI18n } from "@/app/composables/useI18n";
import { ref } from "vue";
import StakeDetail from "../MyBets/Bets/Sports/StakeDetail.vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import moment from "moment";
import { useBetStore } from "../../../../store";
import { useCurrencies } from "@/app/composables/useCurrencies";
import { useUserService } from "@/user-account/composables/useUserService";
import { useBetslip } from "@/sportsbook/composables/useBetslip.js";

const { show, tickets } = defineProps({
  show: Boolean,
  tickets: Object,
});

const emit = defineEmits(["close"]);
const { t } = useI18n();
const store = useBetStore();
const { getIconByTicker } = useCurrencies();
const { activeWallet } = useUserService();
const { selections } = useBetslip();

const index = ref(0);
const keepSelection = ref(false);

const iconBase = "/assets/images/bets/mybets/";

const handleArrows = (dir) => {
  if (dir == "left") {
    if (index.value > -1) index.value = index.value - 1;
  } else {
    if (index.value <= tickets.length - 1) index.value = index.value + 1;
  }
};

const handleContinue = () => {
  if (keepSelection.value) {
    store.setTickets(false);
  } else {
    selections.value = [];
    store.setTickets(false);
  }
};

const handleKeepSelection = (e) => {
  keepSelection.value = e.target.checked;
};
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask-bonus">
      <div class="modal-container">
        <div class="close-btn" @click="() => store.setTickets(false)">
          <div>{{ t("general.close") }}</div>
          <img src="/assets/images/close-modal-icon.svg" alt="" />
        </div>
        <div class="modal-detail">
          <div class="heading">
            <div>Bet Placed</div>
            <div class="arrows" v-if="tickets.length > 1">
              <!-- v-if="index != 0" -->
              <img
                class="arrow"
                src="/assets/images/left-arrow.png"
                alt=""
                @click="() => handleArrows('left')"
              />
              <div class="win-no">{{ index + 1 }} of {{ tickets.length }}</div>
              <!--     v-if="index != tickets.length - 1" -->
              <img
                class="arrow"
                src="/assets/images/right-arrow.png"
                alt=""
                @click="handleArrows"
              />
            </div>
            <img src="/assets/images/sport.svg" class="bet-image" alt="" />
          </div>
          <div class="modal-body">
            <div class="ticket-bet">
              <div class="stake-bar">
                <div class="stake-icon stake--open">
                  <img :src="`${iconBase}sax.svg`" class="" alt="" />
                </div>
                <div class="stake">{{ tickets[index]?.stake }}</div>
                <img
                  :src="getIconByTicker(activeWallet?.currency?.ticker)"
                  class="currency-icon"
                  alt=""
                />
                <div class="multiple">
                  {{ t(`${tickets[index]?.wagers?.length > 1 ? "Multiple" : "Single"}`) }}
                </div>
              </div>
              <div v-if="true" class="stake-detail">
                <div class="stake-detail-header">
                  <div class="flex">
                    <div class="odds">
                      <div class="stake-label">
                        {{ t("bets.odds") }}
                      </div>
                      <div class="win-no">{{ tickets[index]?.odds }}</div>
                    </div>
                    <div class="to-win">
                      <div class="stake-label">
                        {{ " won" === "won" ? t("bets.paid_winnings") : t("bets.potencial_win") }}
                      </div>
                      <div class="win-no">
                        {{ tickets[index]?.toWin || tickets[index]?.stake * tickets[index]?.odds }}
                      </div>
                    </div>
                  </div>
                  <div class="selection-label">
                    {{ tickets[index]?.wagers?.length }}
                    {{
                      tickets[index]?.wagers?.length > 1
                        ? t("bets.selections")
                        : t("bets.selection")
                    }}
                  </div>
                </div>
                <div class="wagers">
                  <div v-for="(wager, j) in tickets[index]?.wagers" class="wager">
                    <StakeDetail :wager="wager" :response="true" />
                  </div>
                </div>

                <div class="stake-bet">
                  <div class="bet-id">
                    <div class="stake-label">
                      {{ t("bets.bet_id") }}
                    </div>
                    <div class="win-no">{{ store.getBetId[index]?.code }}</div>
                  </div>
                  <div class="bet-placed">
                    <div class="stake-label">
                      {{ t("bets.placed") }}
                    </div>
                    <div>{{ moment(+new Date()).format("Do MMMM hh:mm") }}</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="keep">
                  <label class="container">
                    <input :checked="keepSelection" type="checkbox" @change="handleKeepSelection" />
                    {{ t("bets.keep_selections") }}
                    <span class="checkmark" />
                  </label>
                </div>
                <button class="btn-g" @click="handleContinue">{{ t("bets.continue") }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.btn-g {
  color: var(--be-primary-btn-label);
  border: none;
  height: 42px;
  width: 90%;
  display: block;
  border-radius: 4px;
  background: var(--Linear, linear-gradient(90deg, #3886ec 0%, #544fe5 100%));
  margin: 18px auto;
}

@media screen and (max-width: 500px) {
  .btn-g {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .win-no {
    font-size: 12px;
    word-break: break-word;
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-family: Poppins-bold;
  margin-bottom: 20px;
  .bet-image {
    width: 40px;
    height: 40px;
  }
  .arrows {
    display: flex;
    gap: 30px;
    align-items: center;
    .arrow {
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 500px) {
    .arrows {
      display: flex;
      gap: 20px;
      align-items: center;
      .arrow {
        width: 30px;
        height: 30px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .heading {
    font-size: 18px;
    margin-bottom: 10px;

    .bet-image {
      width: 30px;
      height: 30px;
    }
  }
}
.ticket-bet {
  .wagers {
    height: 190px;
    overflow-x: auto;
    border-bottom: 1px solid var(--be-small-card-bg);
    margin-bottom: 10px;
    .wager {
      border-top: 1px solid var(--be-small-card-bg);
      padding-top: 16px;
      margin-bottom: 16px;
      font-family: Poppins;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 500px) {
    .wagers {
      height: 355px;
    }
  }

  .stake {
    font-family: Poppins;
    font-size: 16px;
    margin-left: 16px;
  }

  .multiple {
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-family: Poppins-reg;
    border-left: 1px solidvar(--be-big-card-bg);
    padding-left: 8px;
  }

  .currency-icon {
    width: 18px;
    height: 18px;
    margin: 0 8px;
  }

  @media screen and (max-width: 500px) {
    .multiple {
      font-size: 13px;
    }

    .stake {
      font-size: 13px;
    }

    .currency-icon {
      width: 15px;
      height: 15px;
    }
  }

  .odds {
    display: flex;
    align-items: center;
    border-right: 1px solid var(--be-small-card-bg);
    padding-right: 8px;
    margin-right: 8px;
    font-family: Poppins;
    font-size: 16px;
  }

  .to-win {
    display: flex;
    align-items: center;
    padding-right: 8px;
    margin-right: 8px;
    font-family: Poppins;
    font-size: 16px;
  }

  .bet-id {
    font-family: Poppins;
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .bet-placed {
    font-family: Poppins;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  @media screen and (max-width: 500px) {
    .bet-placed {
      font-size: 12px;
    }

    .bet-id {
      gap: 10px;
    }
  }
}

.stake--open {
  background: #ed8730;
}
.stake--win {
  background: #07863a;
}

.stake--lose {
  background: #d80027;
}

.chat-email {
  position: relative;
  display: flex;
  margin-top: 16px;
  .chat-input {
    background: var(--be-small-card-bg);
    width: 100%;
    height: 52px;
    border-radius: 6px;
    outline: none;
    text-indent: 17px;
    color: white;
    opacity: 1;
    font-size: 16px;
    font-family: Poppins;
  }

  .chat-image {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 16px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.stake-bar {
  border-radius: 8px;
  background: var(--be-small-card-bg);
  height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

@media screen and (max-width: 500px) {
  .stake-bar {
    height: 36px;
    margin-bottom: 13px;
  }
}

.keep {
  font-family: Poppins;
  font-size: 16px;
  margin-top: 15px;

  .container {
    display: block;
    position: relative;
    padding-left: 23px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: var(--White, #fff);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    transform: translate(0px, -50%);
    top: 50%;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 4px;
    background-color: var(--be-small-card-bg);
    border: 1px solid #333360;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: var(--be-small-card-bg);
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #1382eb;
    border: 1px solid #1382eb;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 5.5px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid var(--be-primary-btn-label);
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.flex {
  display: flex;
}

.stake-bet {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-top: 8px;
  border-bottom: 1px solid var(--be-small-card-bg);
  padding-bottom: 15px;
}

.modal-mask-bonus {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(13, 12, 44, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  transition: opacity 0.3s ease;
}
.stake-detail {
  font-size: 12px;
  font-family: Poppins-reg;
  .stake-detail-header {
    display: flex;
    justify-content: space-between;
    padding: 0 0 13px 0;
  }
  .selection-label {
    color: #1382eb;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  @media screen and (max-width: 500px) {
    .selection-label {
      font-size: 12px;
    }
  }

  .stake-label {
    color: rgba(255, 255, 255, 0.5);
    font-family: Poppins-reg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-right: 4px;
  }

  @media screen and (max-width: 500px) {
    .stake-label {
      font-size: 12px;
      margin-right: 0px;
    }
  }
}

@media screen and (max-width: 500px) {
  .modal-mask-bonus {
    top: 2;
  }
}

.close-btn {
  color: white;
  position: absolute;
  top: -32px;
  right: 3px;
  display: flex;
  gap: 0 5px;
  cursor: pointer;
}

.modal-container {
  width: 600px;
  margin: auto;
  // padding: 0px 20px 20px 20px;

  height: 100%;

  // background: var(--be-bg);

  transition: all 0.3s ease;
  position: relative;
  display: flex;
}

@media screen and (max-width: 500px) {
  .modal-container {
    padding: 0 10px 10px 10px;
    width: 100%;
    height: 94%;
    margin-top: 38px;
  }
}

.modal-detail {
  background: var(--be-bg);
  padding: 30px;
  height: 100%;
  overflow: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  margin-top: 13px;
  border-radius: 20px;
  // margin: auto;
  margin: 60px 0 auto 0;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 500px) {
  .modal-detail {
    margin: auto 0 auto 0;
    padding: 10px;
  }
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  border-radius: 8px;
  background: var(--be-big-card-bg);
}

.modal-body {
  border-radius: 16px;
  background: var(--be-big-card-bg);
  padding: 16px;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
