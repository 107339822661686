<script setup>
import { computed, ref, watch } from "vue";
import { useI18n } from "@/app/composables/useI18n";
import BaseButton from "@/app/components/ui/BaseButton.vue";
import BaseTextField from "@/app/components/ui/BaseTextField.vue";
import BasePhoneField from "@/app/components/ui/BasePhoneField.vue";
import { rules as phoneRules } from "@/user-account/utils/input-rules/phone";
import { rules as passwordRules } from "@/user-account/utils/input-rules/password";
import { rules as emailRules } from "@/user-account/utils/input-rules/email";
import { rules as general } from "@/user-account/utils/input-rules/password/general";
import { rules as referCodeRules } from "@/user-account/utils/input-rules/refer-code";
import { api } from "@/services";
import { useReferAndEarn } from "@/app/composables/useReferAndEarn.js";
import { useConfig } from "@/app/composables/useConfig";

const emit = defineEmits(["submit", "toLogin"]);

const { referAndEarn } = useReferAndEarn();
const { registrationFlow } = useConfig();

const { t } = useI18n();

const countries = ref([]);

const inputs = ref([
  {
    name: "password",
    value: "",
    type: "password",
    label: t("auth.password"),
    placeholder: t("auth.password"),
    rules: general,
    iconName: "eye-opened",
    error: "",
  },
  {
    name: "referCode",
    value: "",
    type: "string",
    label: t("auth.referCode"),
    placeholder: t("auth.referCode"),
    rules: referCodeRules,
    error: "",
  },
]);

watch(
  () => registrationFlow.value.type,
  (newType) => {
    if (["number_2fa", "number_2fa_with_email", "email_2fa_with_number"].includes(newType)) {
      inputs.value.push({
        name: "phone",
        value: "",
        type: "tel",
        label: t("auth.phone"),
        placeholder: t("auth.phone"),
        rules: phoneRules,
        error: "",
      });
    }
    if (["number_2fa", "number_2fa_with_email", "email_2fa_with_number"].includes(newType)) {
      inputs.value.push({
        name: "countryCode",
        value: "",
        type: "text",
      });
    }
    if (["email_2fa", "number_2fa_with_email", "email_2fa_with_number"].includes(newType)) {
      inputs.value.push({
        name: "email",
        value: "",
        type: "email",
        label: t("auth.email"),
        placeholder: t("auth.email"),
        rules: emailRules,
        error: "",
      });
    }
  },
  { immediate: true },
  { deep: true } // Ensures the watcher runs initially to handle the current typeInfo value
);

function onLogin() {
  emit("toLogin");
}

const countryCodes = computed(() => {
  return countries.value.data?.filter((country) => country.status).map((country) => country.code);
});

async function fetchCountries() {
  countries.value = await api.utils.listCountries({
    paginator: {
      cp: 1,
      rpp: 1000,
    },
  });
}

fetchCountries();
</script>

<template>
  <form @submit.prevent="$emit('submit', inputs)">
    <div class="form-fields">
      <BasePhoneField
        :model-value="inputs[2].value"
        :rules="inputs[2].rules"
        :label="inputs[2].label"
        :placeholder="inputs[2].placeholder"
        :type="inputs[2].type"
        :icon-name="inputs[2].iconName"
        :error="inputs[2].error"
        :country-codes="countryCodes"
        @update:model-value="inputs[2].value = $event"
        @country-changed="inputs[3].value = $event.iso2"
        @error="inputs[2].error = $event"
        v-if="
          ['number_2fa', 'number_2fa_with_email', 'email_2fa_with_number'].includes(
            registrationFlow.type
          )
        "
      />

      <BaseTextField
        :model-value="inputs[inputs.length - 1].value"
        :rules="inputs[inputs.length - 1].rules"
        :label="inputs[inputs.length - 1].label"
        :placeholder="inputs[inputs.length - 1].placeholder"
        :type="inputs[inputs.length - 1].type"
        :icon-name="inputs[inputs.length - 1].iconName"
        :error="inputs[inputs.length - 1].error"
        @update:model-value="inputs[inputs.length - 1].value = $event"
        @error="inputs[inputs.length - 1].error = $event"
        v-if="
          ['email_2fa', 'number_2fa_with_email', 'email_2fa_with_number'].includes(
            registrationFlow.type
          )
        "
      />

      <BaseTextField
        :model-value="inputs[0].value"
        :rules="inputs[0].rules"
        :label="inputs[0].label"
        :placeholder="inputs[0].placeholder"
        :type="inputs[0].type"
        :icon-name="inputs[0].iconName"
        :error="inputs[0].error"
        @update:model-value="inputs[0].value = $event"
        @error="inputs[0].error = $event"
      />

      <BaseTextField
        v-if="referAndEarn.enabled"
        :model-value="inputs[1].value"
        :rules="inputs[1].rules"
        :label="inputs[1].label"
        :placeholder="inputs[1].placeholder"
        :type="inputs[1].type"
        :icon-name="inputs[1].iconName"
        :error="inputs[1].error"
        @update:model-value="inputs[1].value = $event"
        @error="inputs[1].error = $event"
      />
    </div>

    <!-- <div class="terms" v-if="registrationFlow?.allow_personal_info">
      <a href="/docs/t&c.pdf" target="_blank">
        {{ t("account.registration_age_term") }}
      </a>
    </div> -->

    <BaseButton class="submit alight wide high" type="submit">
      {{ registrationFlow?.allow_personal_info ? t("auth.next") : t("auth.register") }}
    </BaseButton>

    <div class="dont-have-account">
      <p>
        {{ t("account.already_account_label") }}
      </p>
    </div>

    <button class="login-now" type="button" @click="onLogin">
      {{ t("account.login_now_label") }}
    </button>
  </form>
</template>

<style lang="scss" scoped>
.form-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.terms {
  font-size: 14px;
  margin-bottom: 1rem;
}

.login-now {
  text-align: center;
  color: var(--be-primary-btn);
  font-size: 14px;
  width: 100%;
}
.dont-have-account {
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}
</style>
