import { type RouteRecordRaw } from "vue-router";
import Main from "@/livebetting/components/Main.vue";
import LiveBettingLayout from "@/livebetting/layouts/LiveBettingLayout.vue";

// import ResetPassword from "@/user-account/components/security/ResetPassword.vue";

export const liveBettingRoutes: RouteRecordRaw = {
  path: "/livebetting",
  // name: "account",
  component: LiveBettingLayout,
  children: [
    {
      path: "/livebetting",
      name: "livebetting",
      component: Main,
      props: (route) => ({ query: route.query.name }),
      meta: { requiresAuth: true, backRoute: "/" },
    },
  ],
};
