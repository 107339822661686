<script>
import BaseLayout from "@/app/layouts/BaseLayout.vue";

export default {
  name: "VirtualGamesLayout",
  components: {
    BaseLayout,
  },
};
</script>

<template>
  <BaseLayout>
    <router-view :key="$route.path" />
  </BaseLayout>
</template>
