<script setup>
import { useI18n } from "@/app/composables/useI18n";
import { useConfig } from "@/app/composables/useConfig";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";

const { appDivisions, appDivision } = useConfig();

const { t } = useI18n();

function getIcon(division) {
  const icons = {
    casino: "poker-cards",
    sportsbook: "soccer",
    virtualGames: "e-sports",
  };

  return icons[division];
}
</script>

<template>
  <div class="top-buttons">
    <router-link
      v-for="division in appDivisions"
      :key="division"
      :class="{ active: division === appDivision }"
      :to="{ name: division }"
    >
      <BaseIcon :name="getIcon(division)" size="18" />
      <span>{{ t(`general.${division}`) }}</span>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.top-buttons {
  display: flex;
  justify-content: space-evenly;
  margin: 0 8px 12px;
  a {
    flex-basis: 40%;
    gap: 5px;
    margin: 0 5px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    line-height: 32px;
    border-radius: 20px;
    text-align: center;
    text-decoration: none;
    background-color: rgba(48, 50, 77, 0.7);
    &.active {
      color: var(--be-primary-btn-label);
      background: var(--be-primary-btn);
    }
  }
  .notebook &,
  .desktop & {
    display: none;
  }
}
</style>
