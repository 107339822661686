<script setup>
import { onMounted, ref } from "vue";

import BaseButton from "@/app/components/ui/BaseButton.vue";
import BaseTextField from "@/app/components/ui/BaseTextField.vue";
import { validate } from "@/user-account/utils/input-rules/validation";
import { router } from "@/app/router";
import { useI18n } from "@/app/composables/useI18n";

import { rules as passwordRules } from "@/user-account/utils/input-rules/password";
import { containMinimum6Characters } from "@/user-account/utils/input-rules/username";
import { useAuth } from "@/user-account/composables/useAuth";

const props = defineProps({
  verificationEmailExpired: {
    type: Boolean,
    default: false,
  },
});

const { login } = useAuth();

const { t } = useI18n();

const isAppInstallable = ref(false);

// isAppInstallable.value = !!window.deferredPrompt;

onMounted(() => {
  isAppInstallable.value = !!window.deferredPrompt;
});

const inputs = ref([
  {
    name: "username",
    value: "",
    type: "text",
    label: `${t("account.username")} / ${t("account.email")}`,
    placeholder: t("account.username_login_placeholder"),
    rules: [containMinimum6Characters],
  },
  {
    name: "password",
    value: "",
    type: "password",
    label: t("account.password"),
    placeholder: t("account.password_login_placeholder"),
    rules: passwordRules,
    keyupEnter: "login",
  },
  {
    name: "one_time_password",
    value: "",
    type: "password",
    label: t("account.2fa_optional"),
    placeholder: t("account.2fa_placeholder"),
    keyupEnter: "login",
  },
]);

if (import.meta.env.VITE_ENV === "local") {
  inputs.value[0].value = "user.betkudos";
  inputs.value[1].value = "qwe123QWE";
}

function _login() {
  if (!validate(inputs.value)) {
    return;
  }

  login({
    username: inputs.value[0].value,
    password: inputs.value[1].value,
    otp: inputs.value[2].value,
  });
}

async function installApp() {
  console.log("👍", "butInstall-clicked");
  const promptEvent = window.deferredPrompt;
  if (!promptEvent) {
    // The deferred prompt isn't available.
    console.log("The deferred prompt isn't available");
    return;
  }
  // Show the install prompt.
  promptEvent.prompt();
  // Log the result
  const { outcome } = await promptEvent.userChoice;
  console.log("👍", "userChoice", outcome);
  // Reset the deferred prompt variable, since
  // prompt() can only be called once.
  if (outcome === "accepted") {
    console.log("User accepted the install prompt");
    window.deferredPrompt = null;
  }
}
</script>

<template>
  <div class="login-wrapper">
    <h2>
      {{ t("account.auth_login") }}
    </h2>
    <div v-if="props.verificationEmailExpired">
      {{ t("account.email_verification_expired") }}
    </div>
    <div class="login-view">
      <div class="form-fields">
        <BaseTextField
          v-for="(input, i) in inputs.slice(0, 2)"
          :key="i"
          :model-value="inputs[i].value"
          :rules="inputs[i].rules"
          :label="inputs[i].label"
          :placeholder="inputs[i].placeholder"
          :type="inputs[i].type"
          @update:model-value="inputs[i].value = $event"
        />

        <div class="forgot-password" @click="router.push({ name: 'forgot-password' })">
          {{ t("account.forgot_password") }}
        </div>

        <BaseTextField
          :model-value="inputs[2].value"
          :label="inputs[2].label"
          :placeholder="inputs[2].placeholder"
          :type="inputs[2].type"
          :rules="inputs[2].rules"
          @update:model-value="inputs[2].value = $event"
        />
      </div>

      <div>
        <BaseButton class="login-button alight wide high" @click="_login">
          {{ t("account.auth_login") }}
        </BaseButton>

        <p class="dont-have-account">
          {{ t("account.dont_have_an_account") }}
        </p>

        <BaseButton text to="register" class="register-button high">
          {{ t("account.auth_register_now") }}
        </BaseButton>
      </div>
      <BaseButton v-if="isAppInstallable" class="alight wide high" @click="installApp">
        Install the BetEvet App!
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.login-wrapper {
  width: 100%;
  margin-top: 10%;
  padding: 0 20px 190px;
  max-width: 500px;
}
.login-view {
  .forgot-password {
    font-size: 0.9em;
    font-family: Poppins !important;
    margin-bottom: 20px;
    cursor: pointer;
    transition: 0.4s all ease-in;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
  .login-button {
    margin: 2em 0 4em;
    // height: 3em;
  }
  .register-button {
    margin: 1em 0 3em;
    // height: 3em;
  }
  .dont-have-account {
    font-size: 0.9em;
    margin-bottom: 10px;
  }
}
</style>
