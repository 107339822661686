import { type Ref, ref } from "vue";
import { api } from "@/services";

const locales: Locale[] = [
  {
    code: "en",
    name: "English",
    flag: "England",
  },
  {
    code: "tr",
    name: "Türkçe",
    flag: "Turkey",
  },
  {
    code: "pt",
    name: "Português",
    flag: "Brazil",
  },
  {
    code: "ge",
    name: "Deutsch",
    flag: "Germany",
  },
  {
    code: "sp",
    name: "Español",
    flag: "Spain",
  },
  {
    code: "fr",
    name: "Français",
    flag: "France",
  },
  {
    code: "hi",
    name: "India",
    flag: "India",
  },
  {
    code: "id",
    name: "Indonesian",
    flag: "Indonesia",
  },
  {
    code: "ja",
    name: "日本語",
    flag: "Japan",
  },
  {
    code: "ko",
    name: "한국어",
    flag: "South Korea (general)",
  },
  {
    code: "po",
    name: "Polski",
    flag: "Poland",
  },
  {
    code: "ru",
    name: "Pусский",
    flag: "Russia",
  },
  {
    code: "vn",
    name: "Tiếng Việt",
    flag: "Vietnam",
  },
  {
    code: "zh",
    name: "中文",
    flag: "China",
  },
];

const locale: Ref<Locale> = ref();
export const t = useI18n().t;
let messages;

export function useI18n() {
  async function initI18n() {
    messages = [];
    const osLang = window.navigator.language.substring(0, 2);
    const lang =
      localStorage.getItem(`${import.meta.env.VITE_APP_NAME}_lang`) ??
      (import.meta.env.VITE_APP_DEFAULT_LOCALE || osLang);
    await loadLanguage(lang);
  }

  async function loadLanguage(lang) {
    if (locale.value !== lang) {
      const localeMessages = await api.utils.downloadLanguage(lang);
      messages[lang] = localeMessages[lang];
      locale.value = locales.find((l) => l.code === lang);
      localStorage.setItem(`${import.meta.env.VITE_APP_NAME}_lang`, lang);
    }
  }

  function t(msg, params = null) {
    if (!msg || !locale.value) {
      return "";
    }
    let val =
      msg.split(".").reduce((val, part) => {
        return val?.[part] ?? null;
      }, messages[locale.value.code]) ?? msg;
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        val = val.replace(`{${key}}`, value);
      }
    }
    return val ?? msg;
  }

  return {
    t,
    locale,
    locales,
    initI18n,
    loadLanguage,
  };
}
