<script setup>
import { onMounted } from "vue";
import VirtualGamesGameCard from "./VirtualGamesGameCard.vue";
import { useVirtualGamesService } from "@/virtual-games/composables/useVirtualGamesService.js";

const { games, updateGames } = useVirtualGamesService();

onMounted(() => {
  updateGames();
});
</script>

<template>
  <div class="games-grid">
    <VirtualGamesGameCard v-for="game in games" :key="game.id" :game="game" />
  </div>
</template>

<style lang="scss" scoped>
.games-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 1em;
}
</style>
