<script setup></script>

<template>
  <div class="rules">
    <div><b>100% Welcome Bonus up to $1000 (in crypto equivalent) Terms & Conditions</b></div>
    <div class="container">
      <ul class="terms">
        <li>Valid for all deposit methods</li>
        <li>First deposit on the site will be matched 100%.</li>
        <li>
          Bonus only applicable to games shown within the ‘Bonus Games’ category. Management will
          have the right to move the game category in the event of error.
        </li>
        <li>Minimum $1 / Maximum $1000 (or Equivalent in Crypto)</li>
        <li>
          By default, if there are applicable bonus funds for the selected currency, the bonus funds
          will be deducted first (if of course the game is within the ‘Bonus Games’ category).
        </li>
        <li>
          Bonus will show in the balance dropdown immediately after making a deposit, and full bonus
          details can be viewed by clicking the info button.
        </li>
        <li>
          To withdraw the bonus, users must complete wagering requirements. Violation of the rules
          will result in removal of the bonus.
        </li>
        <li>Bonus expiry date is 30 days from the 1st deposit time.</li>
        <li>Maximum bonus win is $1000. Any bonus winnings above this amount will be forfeited.</li>
        <li>
          Current rollover is 40x. That means if you deposit $100, once you have rolled over (staked
          over) $4000 in Casino bets, you can withdraw the bonus.
        </li>
        <li>In the event of a dispute, all management decisions are final.</li>
      </ul>

      <p class="games-intro">
        Games eligible to roll over the bonus are listed below. You can find them all within the
        ‘Bonus Games’ category.
      </p>

      <ul class="games-list">
        <li>Wild Weather (TomHorn)</li>
        <li>Diamond Hill (TomHorn)</li>
        <li>Spinball (TomHorn)</li>
        <li>La Tomatina (TomHorn)</li>
        <li>Cricket Mania (TomHorn)</li>
        <li>Rolling Roger (Habanero)</li>
        <li>Knockout Football (Habanero)</li>
        <li>5 Mariachis (Habanero)</li>
        <li>London Hunter (Habanero)</li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rules {
  font-family: Poppins-reg;
  font-size: 14px;
  height: 300px;
  overflow: auto;
}

.container {
  margin: 0 auto;
  padding: 20px 0;
}

.terms,
.games-list {
  list-style-type: disc;
  padding-left: 20px;
}

.terms li,
.games-list li {
  margin-bottom: 10px;
}

.games-intro {
  margin: 20px 0;
  font-weight: bold;
}

.games-list {
  list-style-type: square;
  padding-left: 30px;
}
</style>
