import auth from "./auth";
import finance from "./finance";
import users from "./users";
import kyc from "./kyc";
import betLimits from "./bet-limits.js";
import utils from "./utils";
import casino from "./casino";
import promotion from "./promotion";
import virtualGames from "./virtual-games";
import sportsdata from "./sportsdata";
import {
  authInterceptor,
  metaInterceptor,
  notificationInterceptor,
  validationErrorInterceptor,
} from "./interceptors";
import jsonrpc from "@/services/json-rpc/jsonrpc";
import http from "@/services/json-rpc/http";
import { useAuth } from "@/user-account/composables/useAuth";

// const auth: any = null;

const api = {
  sportsdata,
  casino,
  virtualGames,
  finance,
  auth,
  users,
  kyc,
  betLimits,
  utils,
  promotion,
  http,
  init() {
    http.setBaseUrl(import.meta.env.VITE_API_URL);
    http.setTokenFetcher(useAuth().getToken);
    jsonrpc.setMetaDataCallback(() => {
      return {
        bost: false,
        at: useAuth().getToken(),
      };
    });
    jsonrpc.setLogoutCallback(useAuth().logout);
    jsonrpc.addResponseInterceptor(validationErrorInterceptor);
    jsonrpc.addResponseInterceptor(notificationInterceptor);
    jsonrpc.addResponseInterceptor(authInterceptor);
    jsonrpc.addResponseInterceptor(metaInterceptor);
  },
};

export { api };
export default api;
