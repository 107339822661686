import { onlyNumbers } from "@/sportsbook/utils/stakeValidationRules";

const validationRules = [onlyNumbers];

function validateStakes(selections, multipleStake) {
  let message = null;
  // if (this.hasMultiples) {
  // console.log("----- multiples are not implemented -----");
  // return "Multiple bets / parlays coming soon! Single bets only currently";
  // }
  if (selections === 0) {
    message = "You should provide a valid stake";
  }

  selections.forEach((selection) => {
    validationRules.forEach((rule) => {
      const error = rule(selection.stake);
      if (typeof error === "string") {
        message = error;
      }
    });
  });

  validationRules.forEach((rule) => {
    const isMultipleStakeInvalid = rule(multipleStake);
    if (typeof isMultipleStakeInvalid === "string") {
      message = isMultipleStakeInvalid;
    }
  });

  return message;
}

export { validateStakes };
