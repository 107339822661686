<script setup>
import { computed, onMounted, ref } from "vue";
import vueSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  rules: {
    type: Array,
    default: () => [],
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(["error"]);

const modelValue = defineModel();

const dropdownPanel1 = ref(null);
const dropdownPanelMenu = ref(false);

function onClick(event) {
  if (dropdownPanelMenu.value) {
    dropdownPanel1.value.onSearchBlur();
  } else if (dropdownPanel1.value.open) {
    dropdownPanelMenu.value = true;
  }
}

function onClose(event) {
  dropdownPanelMenu.value = false;
  props.rules.forEach((rule) => {
    const result = rule(event);
    if (result !== true) {
      emit("error", result);
    }
  });
}
</script>

<template>
  <span class="input-element">
    <span v-if="props.label" class="label">{{ props.label }}</span>

    <vueSelect
      v-model="modelValue"
      :options="options"
      :append-to-body="false"
      :use-popper="true"
      :reset-on-close="true"
      :placeholder="props.placeholder"
      :reduce="(option) => option.value"
      ref="dropdownPanel1"
      label="label"
      @click="onClick"
      class="base-select-field"
      @close="onClose(modelValue)"
      :selectOnTab="true"
      :searchable="false"
      :closeOnSelect="true"
    />

    <small v-if="props.error" class="error">{{ props.error }}</small>
  </span>
</template>

<style>
.input-element {
  position: relative;
  overflow: visible;
}

.base-select-field .vs__dropdown-toggle {
  height: 40px;
  border-radius: 3px;
  background-color: var(--be-small-card-bg);
}

#vs1__listbox {
  background: var(--be-small-card-bg);
  visibility: visible !important; /* Force visibility */
  display: block !important;
}

#vs2__listbox {
  background: var(--be-small-card-bg);
}

.vs__dropdown-option--highlight {
  background: var(--be-bg);
}

.error {
  color: #ff5252;
  margin: 3px 0 0 12px;
  height: 20px;
}

input {
  color-scheme: dark;
  opacity: 0.5;
}
</style>
