<script setup>
import { computed, ref } from "vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";

const props = defineProps({
  slides: {
    type: Array,
    default: () => [],
  },
  spliderOptions: {
    type: Object,
    default: () => ({
      rewind: true,
      perPage: 10,
      pagination: false,
      breakpoints: {
        600: {
          perPage: 3,
        },
        882: {
          perPage: 5,
        },
        1230: {
          perPage: 8,
        },
        1114: {
          perPage: 7,
        },
        1000: {
          perPage: 6,
        },
        1376: {
          perPage: 9,
        },
      },
    }),
  },
});
// const defaultSpliderOptions = {
//   // type: "loop",
//   rewind: true,
//   perPage: 2,
//   // gap: "10px",
//   pagination: false,
//   // lazyLoad: "nearby",
//   // lazyLoad: "sequential",
//   breakpoints: {
//     600: {
//       perPage: 2,
//     },
//     800: {
//       perPage: 2,
//     },
//     980: {
//       perPage: 4,
//     },
//     1200: {
//       perPage: 5,
//     },
//     1400: {
//       perPage: 6,
//     },
//   },
// };
const cursor = ref(props.spliderOptions.perPage * 2);

function moved({ length, index, options }) {
  if (cursor.value !== props.slides.length && index > length - options.perPage * 2) {
    cursor.value += options.perPage * 2;
    if (cursor.value > props.slides.length) {
      cursor.value = props.slides.length;
    }
  }
}

const takenSlides = computed(() => {
  return props.slides.slice(0, cursor.value);
});
</script>

<template>
  <Splide :options="spliderOptions" @splide:moved="moved">
    <SplideSlide v-for="slide in takenSlides" :key="slide.id">
      <slot name="slide" :slide="slide" />
    </SplideSlide>
  </Splide>
</template>

<style lang="scss" scoped>
@import "@/casino/assets/scss/casino-styles.scss";

.list-wrapper {
  padding-bottom: 8px;
  margin-top: 20px;

  .list-title {
    display: flex;
    align-items: center;
    margin: 0 0 4px 0px;

    img {
      max-width: 24px;
      max-width: 24px;
      height: 24px;
    }

    span {
      margin: 4px 0 0 8px;
    }
  }
}

// .provider-wrapper {
//   // display: flex;
//   padding: 5px;
//   display: flex;
//   justify-content: center;

.provider {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #232441;
  // opacity: 0.8;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
  margin: auto;
  height: 80px;
  width: 160px;
  .mobile & {
    height: 55px;
    width: 110px;
  }

  .image {
    max-width: 100%;
    max-height: 100%;
    padding: 2px 5px;
  }
}

// }
</style>
