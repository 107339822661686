<script setup lang="ts">
import { type Ref, computed, onBeforeUnmount, onMounted, ref, watch } from "vue";

import SidebarSection from "./SidebarSection.vue";
import { useI18n } from "@/app/composables/useI18n";
import { useConfig } from "@/app/composables/useConfig";
import { useSportsdata } from "@/sportsbook/composables/useSportsdata";
import { useCasinoService } from "@/casino/composables/useCasinoService.js";
import { useAuth } from "@/user-account/composables/useAuth";
import { useVirtualGamesService } from "@/virtual-games/composables/useVirtualGamesService";
import { useBetStore } from "../../../../store";
import LanguageSelect from "../LanguageSelect.vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";

const { isDrawerOpen, appDivisions, appDivision } = useConfig();

const { sports } = useSportsdata();
const { categories } = useCasinoService();
const { virtualGames } = useVirtualGamesService();

const { isAuthenticated } = useAuth();
const { t } = useI18n();

const rootItems: SidebarMenuItem[] = [];
const store = useBetStore();
let baseItems = ref([]);

baseItems = computed(() => {
  return [
    {
      id: -101,
      title: t("sportsbook.mybets"),
      icon: "bets",
      route: { name: "" },
      click: true,
      handleClick: () => {
        if (isAuthenticated()) {
          store.handleShowBetModal();
          store.setSection("bet");
        } else {
          store.handleShowLogin();
        }
      },
    },
  ];
});

let supportItems = ref([]);

supportItems = computed(() => {
  return [
    {
      id: -101,
      title: t("general.live_support"),
      icon: "live",
      route: { name: "" },
      click: true,
      handleClick: () => store.handleShowChatModal(),
    },
    {
      id: 103,
      title: t("general.language"),
      icon: "globe",
      route: { name: "" },
      click: true,
      handleClick: () => (language.value = !language.value),
    },
  ];
});

if (appDivisions.value.includes("sportsbook")) {
  rootItems.push({
    id: -101,
    title: t("general.sports"),
    icon: "sports",
    route: { name: "sportsbook" },
  });
}
if (appDivisions.value.includes("casino")) {
  rootItems.push({
    id: -102,
    title: t("general.casino"),
    icon: "lobby",
    route: { name: "casino" },
  });
  rootItems.push({
    id: -101,
    title: t("general.virtualGames"),
    icon: "sports",
    route: { name: "virtualGames" },
  });
}

const casinoItems: Ref<SidebarMenuItem[]> = computed(() => {
  return categories.value
    .map((casinoCategory) => {
      const slug = casinoCategory.name.toLowerCase().replace(" ", "-");
      return {
        id: casinoCategory.id,
        title: t(`casino.${casinoCategory.slug}`),
        icon: `${slug}`,
        route: {
          name: "casino.category",
          params: { category: slug },
        },
      };
    })
    .filter((mi) => mi);
});

const sportbookItems: Ref<SidebarMenuItem[]> = computed(() => {
  return sports.value.map((sport) => {
    return {
      id: sport.id,
      title: t(`sports.${sport.slug}`),
      icon: sport.icon,
      route: {
        name: !sport.showAllMatches ? "sports.today" : "sport.competition",
        params: {
          // sport: sport.name,
          // sportName: sport.name,
          // sportId: sport.id,
          sportSlug: sport.slug,
        },
      },
    };
  });
});

const virtualGamesItems: Ref<SidebarMenuItem[]> = computed(() => {
  return virtualGames.value.map((game) => {
    return {
      id: game?.id,
      title: t(`general.${game?.code}`),
      icon: game?.code,
      route: {
        name: "virtualGames.game",
        params: { code: game?.code },
      },
    };
  });
});

const onHover = ref(false);

const screenWidth = ref(window.innerWidth);

function handleResize() {
  screenWidth.value = window.innerWidth;
}

onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize(); // Initial check
  isDrawerOpen.value = screenWidth.value > 500 ? isDrawerOpen.value : false;
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});

const language = ref(false);

let DrawerValue = screenWidth.value >= 568 ? true : isDrawerOpen;

const handleDepostBet = () => {
  if (isAuthenticated()) {
    store.handleShowBetModal();
    store.setSection("wallet");
    store.setWalletTab("deposit");
  } else {
    store.handleShowLogin();
  }
};

const handleLanguageClick = () => {
  // if (language.value) {
  //   language.value = false;
  // }
};
</script>

<template>
  <!-- <nav class="sidenav" :class="{ opened: isDrawerOpen }">
    <SidebarSection :menu-items="rootItems" :show-separator="false" />
    <SidebarSection
      v-if="appDivision === 'casino'"
      :menu-items="casinoItems"
      :title="t('general.casino')"
    />
    <SidebarSection
      v-if="appDivision === 'sportsbook'"
      :menu-items="sportbookItems"
      :title="t('general.sports')"
    />
  </nav> -->

  <v-card style="z-index: 11">
    <v-layout>
      <v-navigation-drawer
        v-model="DrawerValue"
        :expand-on-hover="false"
        :style="`top: 58px; border: none; width:${
          isDrawerOpen ? '' : '270px'
        }; transform:translateX(${
          isDrawerOpen ? '0%' : screenWidth <= 568 && !isDrawerOpen ? '-200%' : null
        })`"
        class="side-bar-col"
        :rail="screenWidth >= 568 ? isDrawerOpen : false"
        @click="handleLanguageClick"
      >
        <div class="modal-overlay" v-if="language" @click="() => (language = false)">
          <LanguageSelect
            :class="` ${!isDrawerOpen ? 'language-selector' : 'shrink-language-selector'}`"
            @close="() => (language = false)"
          />
        </div>
        <SidebarSection
          :menu-items="baseItems"
          :show-separator="screenWidth >= 568 ? !isDrawerOpen : true"
        />
        <!-- :expand-on-hover="false"
        :style="`top: 58px; background: #19183b !important; color: white; border: none; width:${
          isDrawerOpen ? '' : '270px'
        }`"
        :rail="isDrawerOpen" -->
        <SidebarSection
          v-if="appDivision === 'casino'"
          :menu-items="casinoItems"
          :title="t('general.casino')"
          :gameHeading="true"
          :show-separator="screenWidth >= 568 ? !isDrawerOpen : true"
        />
        <SidebarSection
          v-if="appDivision === 'sportsbook'"
          :menu-items="sportbookItems"
          :title="t('general.sports')"
          :gameHeading="true"
          :show-separator="screenWidth >= 568 ? !isDrawerOpen : true"
        />
        <SidebarSection
          v-if="appDivision === 'virtualGames'"
          :menu-items="virtualGamesItems"
          :title="t('general.virtualGames')"
          :gameHeading="true"
          :show-separator="screenWidth >= 568 ? !isDrawerOpen : true"
        />

        <SidebarSection
          :menu-items="supportItems"
          :title="t('general.virtualGames')"
          :show-separator="screenWidth >= 568 ? !isDrawerOpen : true"
        />
        <div class="deposit-btn" @click="handleDepostBet">
          <!-- <img
            src="/assets/images/bets/deposit-active.svg"
            :class="`${isDrawerOpen ? 'ml-0' : 'ml-_11'}`"
            alt=""
          /> -->
          <div :class="`${isDrawerOpen ? 'ml-0' : 'ml-_11'}`">
            <BaseIcon name="deposit" size="18" color="var(--be-primary-btn-label)" />
          </div>
          <div v-if="screenWidth >= 568 ? !isDrawerOpen : true">
            {{ t("finance.deposit") }}
          </div>
        </div>
        <!-- <v-list-item prepend-icon="mdi-folder" title="My Files" value="myfiles">
            <img src="/assets/images/Logo.png" alt="" />
          </v-list-item> -->
        <!-- <v-list-item prepend-icon="mdi-account-multiple" title="Shared with me" value="shared" />
          <v-list-item prepend-icon="mdi-star" title="Starred" value="starred" /> -->
      </v-navigation-drawer>
    </v-layout>
  </v-card>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/thin-scrollbar.scss";

.modal-overlay {
  position: fixed;
  top: -60px;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  width: 1000vh;
  height: 100vh;
}

.side-bar-col {
  background: var(--be-big-card-bg);
  color: var(--be-primary-text-color);
}

.deposit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 8px;
  height: 40px;
  background: var(--be-primary-btn);
  color: var(--be-primary-btn-label);
  margin: 10px;
  font-size: 14px;
  font-family: Rubik-med;
  cursor: pointer;
}

.ml-0 {
  margin-left: 0px;
}
.ml-_11 {
  margin-left: -11px;
}

.language-selector {
  flex-shrink: 0;
  margin: 0 10px 0 0;
  position: absolute;
  left: 271px;
  top: 150px;
  width: 186px;

  background: var(--be-small-card-bg);
  overflow: auto;
}

.shrink-language-selector {
  left: 55px;
  flex-shrink: 0;
  margin: 0 10px 0 0;
  position: absolute;
  top: 150px;
  width: 186px;

  background: var(--be-small-card-bg);
  overflow: auto;
}
.sidenav {
  margin-top: 67px;
  padding: 20px;
  width: 90%;
  max-width: 300px;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  height: calc(100% - 67px);
  background: var(--be-background-color);
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateX(-100%);
  transition: transform 0.3s;
  will-change: transform;
  z-index: 99;
  &.opened {
    transform: translateX(0);
  }
}

// .sidenav.loaded {
//   visibility: visible !important;
// }
</style>
