<script setup>
import { brands } from "@/app/utils/brands";
import BaseTextField from "@/app/components/ui/BaseTextField.vue";
import { useCurrencies } from "@/app/composables/useCurrencies";
import { useReferAndEarn } from "@/app/composables/useReferAndEarn.js";
import { t } from "@/app/composables/useI18n";

const { getDefaultCurrency } = useCurrencies();
const defaultCurrency = getDefaultCurrency();

const { referAndEarn } = useReferAndEarn();

const { name } = brands[import.meta.env.VITE_APP_BRAND];

const code = "YOUR_CODE";

const termsUrl = `/docs/${name}/t&c.docx`;
</script>

<template>
  <div class="wrapper">
    <strong>
      {{ t("general.earn_upto") }}
    </strong>
    <p>
      {{ t("general.check_full") }}
      <a class="link" :href="termsUrl">{{ t("general.terms_of_business") }}</a>
    </p>
    <p>
      {{ t("general.send_code_friend") }}
      {{ defaultCurrency.ticker }} {{ referAndEarn.award }}
      {{ t("general.equivalent_crypto") }}
    </p>
    <BaseTextField
      :model-value="referAndEarn.code"
      :label="t('general.your_referral_code')"
      type="text"
      readonly="true"
      icon-name="copy"
    />
  </div>
</template>

<style scoped>
.wrapper {
  border-radius: 8px;
  margin: 0 16px 16px;
}

.link {
  text-decoration: underline;
}
</style>
