<script setup>
import { useI18n } from "@/app/composables/useI18n";
import { router } from "@/app/router";
import { useBetStore } from "../../../../store";

const { t } = useI18n();

const store = useBetStore();

const sections = [
  // {
  //   title: t("sportsbook.live"),
  //   class: "popular-live",
  //   count: liveSportsCount,
  //   route: {
  //     name: "sports"
  //   }
  // },
  {
    title: t("sportsbook.popular"),
    class: "starting-soon",
    route: {
      name: "sports.upcoming",
    },
  },
  {
    title: t("sportsbook.live"),
    class: "popular-upcoming",
    // route: {
    //   name: "livebetting",
    //   props: { id: "4499" },
    // },
  },
];

function routing() {
  router.push({
    name: "sport.competition",
    params: { sportSlug: store?.sport?.slug },
  });
}
</script>

<template>
  <div class="sections-header">
    <!-- <div
      v-for="(section, i) in sections"
      :key="i"
      @click="routing"
      tag="div"
      exact
      class="section-header"
      active-class="active"
    >
      {{ section.title }}
    </div> -->
    <div class="popular">Popular Today</div>
    <div class="view-full" @click="() => routing()">
      <div>View Full {{ t(`sports.${store?.sport?.slug}`) }}</div>
      <img src="/assets/images/live/view-all.svg" alt="" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.popular {
  cursor: pointer;
}
.view-full {
  opacity: 0.5;
  color: white;
  cursor: pointer;
  display: flex;
  gap: 0 9px;
  text-transform: capitalize;
}
.sections-header {
  // background-color: #19183b;
  // border-radius: 3px 3px 0 0;
  display: flex;
  position: relative;
  background: var(--be-big-card-bg);
  border-radius: 8px 8px 0 0;
  margin-bottom: 5px;
  padding: 10px;
  justify-content: space-between;
  font-size: 12px;
  .section-header {
    display: flex;
    justify-content: center;
    flex-basis: 50%;
    margin: auto;
    cursor: pointer;
    font-size: 13px;
    opacity: 0.4;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

    &.active {
      opacity: 1;
      &:after {
        content: "";
        position: absolute;
        bottom: 5px;
        height: 4px;
        width: 4px;
        border-radius: 5px;
        background-color: #f09426;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
