<script setup>
import { useI18n } from "@/app/composables/useI18n";

const props = defineProps({
  tabs: {
    type: Array,
    default: () => [],
  },
  modelValue: {},
});

const emit = defineEmits(["update"]);

const { t } = useI18n();
</script>

<template>
  <div class="tabs">
    <div
      v-for="(tab, index) in props.tabs"
      :key="index"
      class="tab"
      :class="{ active: props.modelValue === index }"
      @click="emit('update', index)"
    >
      {{ t(tab.title) }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--be-small-card-bg);
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);

  .tab {
    // text-align: center;
    width: 50%;
    // padding: 10px 0 7px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active,
    &:hover {
      background-color: #f09426;
      color: #000000;
      font-weight: 600;
      background: rgb(255, 232, 26);
      box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px, rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
        rgba(0, 0, 0, 0.15) 0px -3px 0px inset, rgb(180 135 1) 0px 0px 12px inset;
      // rgba(0, 0, 0, 0.15) 0px -3px 0px inset, rgb(255, 135, 25) 0px 0px 12px inset;
    }
  }
}
</style>
