import { type RouteRecordRaw } from "vue-router";
import SportsbookLayout from "@/sportsbook/layouts/SportsbookLayout.vue";

import SportsbookView from "@/sportsbook/views/SportsbookView.vue";
import SportListCoupons from "@/sportsbook/components/SportListCoupons.vue";
import SportCoupons from "@/sportsbook/components/SportCoupons.vue";
import SportPanel from "@/sportsbook/components/SportPanel.vue";
import SportsCompetition from "@/sportsbook/views/SportsCompetition.vue";
import Leagues from "@/sportsbook/views/Leagues.vue";
import UpcomingEventsSection from "@/sportsbook/components/coming-soon/UpcomingEventsSection.vue";
import EventView from "@/sportsbook/views/EventView.vue";
import Main from "@/events/components/Main.vue";
import { getNumberFromString } from "../utils/general";

export const sportsbookRoutes: RouteRecordRaw = {
  path: "/sports",
  component: SportsbookLayout,
  children: [
    {
      path: "",
      name: "sportsbook",
      component: SportsbookView,
      redirect: "/sports/home",
      children: [
        {
          path: "home",
          components: {
            main: SportListCoupons,
            upcoming: UpcomingEventsSection,
          },
          name: "sports.upcoming",
          props: {
            upcoming: { timeFrame: "upcoming", type: "popular" },
          },
        },
        {
          path: "today",
          components: {
            main: SportListCoupons,
            upcoming: UpcomingEventsSection,
          },
          name: "sports.today",
          props: {
            upcoming: { timeFrame: "today", type: "live" },
          },
        },
        {
          path: "competition",
          name: "sports.competition",
          component: SportsCompetition,
        },
      ],
    },
    {
      path: "event/:eventId",
      name: "sport.event",
      props: (route) => ({
        eventId: getNumberFromString(route.params.eventId),
      }),
      component: EventView,
    },
    {
      path: ":sportSlug",
      props: true,
      component: SportPanel,
      children: [
        {
          path: "",
          name: "upcoming.events",
          props: (route) => ({
            loadTournaments: true,
            sportSlug: route.params.sportSlug,
          }),
          component: SportCoupons,
        },
        {
          path: "todays",
          name: "sport.countries",
          props: (route) => ({
            loadTournaments: true,
            sportSlug: route.params.sportSlug,
            timeFrame: "todays",
            filters: { timeFrame: "today" },
            type: "todays",
          }),
          component: SportCoupons,
        },
        {
          path: "competition",
          name: "sport.competition",
          props: (route) => ({
            sportSlug: route.params.sportSlug,
          }),
          component: SportsCompetition,
        },
        {
          path: "competition/:countrySlug",
          name: "sport.league",
          props: (route) => ({
            sportSlug: route.params.sportSlug,
            countrySlug: route.params.countrySlug,
          }),
          component: Leagues,
        },
        {
          path: "competition/:countrySlug/:tournamentSlug",
          name: "sport.tournament",
          props: (route) => ({
            sportSlug: route.params.sportSlug,
            tournamentSlug: route.params.tournamentSlug,
            filters: { tournamentId: +route.params.tournamentSlug, timeFrame: "all" },
          }),
          component: SportCoupons,
        },
        {
          path: ":countrySlug",
          name: "sport.country",
          props: (route) => ({
            loadTournaments: true,
            sportSlug: route.params.sportSlug,
            countrySlug: route.params.countrySlug,
            filters: { categoryId: +route.params.countryId },
          }),
          component: EventView,
        },
      ],
    },
  ],
};
