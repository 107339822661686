import { t } from "@/app/composables/useI18n";

export function contains6Digits(OTP) {
  if (typeof OTP !== "string") {
    //
    return t("account.invalid_otp_6");
  }

  if (OTP.length !== 6) {
    return t("account.invalid_otp_6");
  }

  return true;
}

export function isInvalidNumber(OTP) {
  if (OTP) {
    if (!Number(OTP) > 999999) {
      return "Invalid one time password, it should be less than or equals to 999999.";
    }

    return true;
  }

  return t("account.invalid_otp_6");
}

export const rules = [contains6Digits, isInvalidNumber];
