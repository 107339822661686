<script setup>
import { onMounted, ref, watch } from "vue";
import { useI18n } from "@/app/composables/useI18n";
import TabsHeader from "@/app/components/ui/TabsHeader.vue";
import { router, useRoute } from "@/app/router";

const { t } = useI18n();

const route = useRoute();

const selectedTab = ref(0);

const sections = [
  {
    title: "finance.deposit",
    class: "deposit-tab",
    route: { name: "user-deposits" },
  },
  {
    title: "finance.withdraw",
    class: "withdraw-tab",
    route: { name: "user-withdrawals" },
  },
  {
    title: "finance.transactions",
    class: "transactions-tab",
    route: { name: "user-transactions" },
  },
];
watch(selectedTab, () => {
  router.push(sections[selectedTab.value].route);
});
onMounted(() => {
  for (let i = 0; i < sections.length; i++) {
    if (sections[i].route.name === route.name) {
      selectedTab.value = i;
      break;
    }
  }
});
</script>

<template>
  <div class="finances">
    <h2> {{ t("finance.payments_history") }} </h2>
    <TabsHeader
      v-model="selectedTab"
      class="tabs"
      :tabs="sections"
      @update="selectedTab = $event"
    />
    <router-view v-slot="{ Component }">
      <!-- <Transition name="fade" mode="out-in"> -->
      <component :is="Component" />
      <!-- </Transition> -->
    </router-view>
  </div>
</template>

<style lang="scss" scoped>
.tabs {
  height: 70px;
  .mobile & {
    height: 50px;
  }
}

.finances {
  .notebook &,
  .desktop & {
    width: 70%;
  }
}
</style>
