import { type ThemeDefinition, createVuetify } from "vuetify";

import "vuetify/styles"; // Global CSS has to be imported

import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const betKudosTheme: ThemeDefinition = {
  dark: true,
  variables: {
    "border-color": "red",
  },
  colors: {
    "background": "#FFFFFF",
    "surface": "#FFFFFF",
    "primary": "#6200EE",
    "primary-darken-1": "#3700B3",
    "secondary": "#03DAC6",
    "secondary-darken-1": "#018786",
    "error": "#B00020",
    "info": "#2196F3",
    "success": "#4CAF50",
    "warning": "#FB8C00",
  },
};

export default createVuetify({
  theme: {
    defaultTheme: "betKudosTheme",
    themes: {
      betKudosTheme,
    },
  },
  components,
  directives,
});
