import http from "@/services/json-rpc/http";
import { jsonRpc } from "@/services/json-rpc/jsonrpc";

const utils = {

  async ping(params) {
    return await jsonRpc({
      method: "utils.system:ping",
      params,
    });
  },

  async downloadLanguage(lang) {
    const data = await http.get(`${window.location.origin}/assets/lang/${lang}.json`);
    return { [lang]: data };
  },

  async listCurrencies() {
    return await jsonRpc(
      {
        method: "utils.resources:list",
        params: {
          database: "maindb",
          resource: "currencies",
        },
      },
      { uri: "currencies:list" },
    );
  },

  async listRegions(params) {
    return await jsonRpc({
      method: "utils.regions:list",
      params,
    });
  },

  async listCountries(params) {
    return await jsonRpc({
      method: "utils.countries:list",
      params,
    });
  },

  async getBankList() {
    return await jsonRpc({
      method: "finance.fastlyypay.banks:list",
    });
  },

  async fetchExchangeRates() {
    return await jsonRpc({
      method: "utils.exchangeRate:list",
    });
  },
};

export default utils;
