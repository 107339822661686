import { computed, ref } from "vue";
import { loadBetslipFromLs, saveBetslipToLs } from "@/sportsbook/utils/betslipStorageService";

const mobileBetslipVisible = ref(false);
const waitingForBetConfirmation = ref(false);
const selections = ref([]);
const multipleStake = ref("");
const betslipState = ref("active"); // active | placed| error
const selectedFreeBet = ref(false);
const selectedFreeBetId = ref(null);
const freeBets = ref([]);

const hasMoreThanOneSelectionFromTheSameEvent = computed(() => {
  for (const selection of selections.value) {
    const filter = selections.value.filter((s) => s.eventId === selection.eventId);
    if (filter.length > 1) {
      return true;
    }
  }
  return false;
});

const multipleBet = computed(() => {
  if (selections.value.length === 0) {
    return {
      stake: 0,
      toWin: 0,
      odds: 0,
    };
  }
  const odds = selections.value
    .reduce((acc, selection) => {
      return acc * selection.odds;
    }, 1)
    .toFixed(2);

  const toWin = multipleStake.value * odds;
  return {
    stake: multipleStake.value,
    toWin,
    odds,
  };
});

export function useBetslip() {
  function containsSelection(selectionId) {
    return selections.value.findIndex((s) => s.id === selectionId) >= 0;
  }

  function toggleBetslip() {
    mobileBetslipVisible.value = !mobileBetslipVisible.value;
  }

  function removeSelection(selectionId) {
    const ind = selections.value.findIndex((s) => s.id === selectionId);
    if (ind >= 0) {
      selections.value.splice(ind, 1);
      save();
      return true;
    }
  }

  function removeSelectionByEventId(eventId) {
    selections.value = selections.value.filter((selection) => selection.eventId !== eventId);
  }

  function toggleSelection(selection, event, callToast) {
    betslipState.value = "active";

    if (removeSelection(selection.id)) {
      return;
    }

    // de-select other selections from the same market
    const market = Object.values(event.markets).find((m) => m.id === selection.marketId);
    removeSelectionByEventId(event.id);

    if (selections.value.length > 19) {
      callToast();
    } else {
      selections.value.push({
        eventName: event.name,
        marketName: market.market.name,
        ...selection,
      });
    }

    save();

    setFreeBets(freeBets.value);
  }

  function toggleSelection2(selection) {
    // To limit liability, we only allow 20 selections in the bet slip
    if (selections.value.length >= 20) {
      return;
    }

    betslipState.value = "active";

    if (removeSelection(selection.id)) {
      return;
    }

    removeSelectionByEventId(selection.eventId);

    selections.value.push(selection);

    save();

    setFreeBets(freeBets.value);
  }

  function updateOdds(updatedSelectionOdds) {
    betslipState.value = "active";

    selections.value = selections.value.map((item) => {
      if (updatedSelectionOdds[item.id]) {
        return { ...item, odds: updatedSelectionOdds[item.id]?.price };
      } else {
        return { ...item };
      }
    });
  }

  function reset() {
    selections.value = [];
    betslipState.value = "active";
  }

  function resetAndKeepSelections() {
    selections.value.forEach((selection) => {
      selection.stake = null;
    });
    betslipState.value = "active";
    multipleStake.value = "";
  }

  function save() {
    saveBetslipToLs({
      selections: selections.value,
      multipleStake: multipleStake.value,
    });
  }

  function load() {
    const state = loadBetslipFromLs();
    selections.value = state.selections;
    multipleStake.value = state.multipleStake;
  }

  function selectFreeBet(freeBetId, amount, i) {
    selectedFreeBetId.value = selectedFreeBet.value ? freeBetId : null;
    multipleStake.value = selectedFreeBet.value ? amount : "";
    if (selections.value.length > 0) {
      selections.value = selections.value.map((item, index) => {
        if (i) {
          if (index == i) {
            return { ...item, stake: selectedFreeBet.value ? amount : "" };
          } else {
            return { ...item, stake: "" };
          }
        } else {
          if (index == 0) {
            return { ...item, stake: selectedFreeBet.value ? amount : "" };
          } else {
            return { ...item, stake: "" };
          }
        }
      });
    }
  }

  function setFreeBets(pFreeBets, noAutoSelect) {
    if (pFreeBets.length === 0) {
      selectedFreeBet.value = false;
      selectedFreeBetId.value = null;
      return;
    }

    if (noAutoSelect !== true) {
      selectedFreeBet.value = true;
      freeBets.value = pFreeBets;
      selectFreeBet(pFreeBets[0].id, pFreeBets[0].amount);
    }
  }

  return {
    mobileBetslipVisible,
    waitingForBetConfirmation,
    multipleBet,
    multipleStake,
    selections,
    hasMoreThanOneSelectionFromTheSameEvent,
    betslipState,
    toggleBetslip,
    toggleSelection,
    toggleSelection2,
    containsSelection,
    removeSelection,
    reset,
    resetAndKeepSelections,
    save,
    load,
    setFreeBets,
    updateOdds,
    selectFreeBet,
    selectedFreeBet,
    selectedFreeBetId,
  };
}
