<script setup>
const props = defineProps({
  status: {
    type: String,
    default: "neutral",
    validator: prop => ["positive", "negative", "neutral"].includes(prop),
  },
  type: {
    type: String,
    default: "normal",
    validator: prop => ["normal", "free-bet", "free-spin"].includes(prop),
  },
});

const iconBase = "/assets/images/bets/mybets";

const icons = {
  positive: `${iconBase}/up-down.svg`,
  negative: `${iconBase}/down.svg`,
  neutral: `${iconBase}/sax.svg`,
};


const texts = {
  "normal": { line1: "", line2: "" },
  "free-bet": { line1: "FREE", line2: "BET" },
  "free-spin": { line1: "FREE", line2: "SPIN" },
  "bonus": { line1: "BONUS", line2: "BET" },
};
</script>

<template>
  <div class="icon" :class="status">
    <div>{{ texts[type].line1 }}</div>
    <img :src="icons[status]">
    <div>{{ texts[type].line2 }}</div>
  </div>
</template>

<style lang="scss" scoped>
.icon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px 0 0 4px;
  display: inline-flex;
  flex-direction: column;
  font-size: 8px;
}

.positive {
  background: #07863a;
}

.negative {
  background: #d80027;
}

.neutral {
  background: #ed8730;
}

@media screen and (max-width: 500px) {
  .icon {
    height: 36px;
    width: 36px;
    font-size: 5px;
  }
}
</style>
