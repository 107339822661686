<script setup>
import { reactive, ref } from "vue";
import { t } from "@/app/composables/useI18n";
import { api } from "@/services";
import BaseTextField from "@/app/components/ui/BaseTextField.vue";
import BaseButton from "@/app/components/ui/BaseButton.vue";
import { rules as emailRules } from "@/user-account/utils/input-rules/email";
import { rules as passwordRules } from "@/user-account/utils/input-rules/password";
import { validate } from "@/user-account/utils/input-rules/validation";
import { useUserService } from "@/user-account/composables/useUserService.ts";

const { user } = useUserService();

const form = reactive({
  current_password: "",
  totp: "",
});

const inputs = ref([
  {
    name: "email",
    value: user.value.email,
    type: "email",
    label: t("account.email_edit"),
    placeholder: t("account.email_label"),
    rules: emailRules,
  },
  {
    name: "new_password",
    value: "",
    type: "password",
    label: t("account.new_password"),
    placeholder: t("account.new_password_label"),
    rules: passwordRules,
  },
  {
    name: "new_password_confirmation",
    value: "",
    type: "password",
    label: t("account.confirm_new_password"),
    placeholder: t("account.confirm_new_password"),
    rules: [
      (password) => {
        if (password === inputs.value[1].value) {
          return true;
        }
        return t("account.passwords_do_not_match");
      },
    ],
  },
]);

// inputs.value[0].value = props.user.email;

async function update() {
  if (!validate(inputs.value)) {
    return;
  }

  const params = {
    email: inputs.value[0].value,
    newPassword: inputs.value[1].value,
  };

  if (user.value.data.auth["2fa"].google.enabled) {
    params.otp = form.totp;
  } else {
    params.password = form.current_password;
  }

  const data = api.users.updateDetails(params);
}
</script>

<template>
  <div class="user-details-wrapper">
    <h2>
      {{ t("account.edit_password_title") }}
    </h2>

    <div class="account-wrapper-content">
      <div class="form-wrapper">
        <BaseTextField
          v-for="(input, i) in inputs"
          :key="i"
          v-model="input.value"
          v-bind="input"
          :clearable="true"
        />
      </div>
      <div class="current-password-wrapper">
        <BaseTextField
          v-if="user.data.auth['2fa'].google.enabled"
          v-model="form.totp"
          :label="t('account.2fa_OTP')"
          :placeholder="t('account.2fa_placeholder')"
          type="number"
          required
        />
        <BaseTextField
          v-else
          v-model="form.current_password"
          :label="t('account.current_password')"
          :placeholder="t('account.current_password_placeholder')"
          type="password"
          required
        />
      </div>
      <BaseButton class="alight wide high" @click="update">
        {{ t("account.save") }}
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-wrapper {
  span {
    margin-bottom: 15px;
  }
}

.current-password-wrapper {
  border-top: 3px solid #fff;
  padding-top: 15px;
  margin: 1em 0 2em;
}
</style>
