<script setup>
import { computed } from "vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { useBetslip } from "../composables/useBetslip.js";

const props = defineProps({
  selection: {
    type: Object,
    required: true,
    default: () => {
      return { isSuspended: true };
    },
  },
  selected: {
    type: Boolean,
    default: false,
  },
});

const { selections } = useBetslip();

const selection = computed(() => {
  const s = props.selection;

  s.specifier = "";
  if (s.specifiers) {
    s.specifier = s.handicap
      ? `${s.header.substring(0, 1)} ${s.handicap}`
      : s.name
      ? `${s.header.substring(0, 1)} ${s.name}`
      : "";
  }
  return s;
});
</script>

<template>
  <div class="market-selection" :class="{ selected, locked: !selection }">
    <template v-if="!selection.isSuspended">
      <span>{{ selection.odds.toFixed(2) }}</span>

      <div v-if="selection.specifier">
        <hr class="divider" />
        {{ selection.specifier }}
      </div>
    </template>

    <!-- <BaseIcon v-else name="lock-outline" /> -->
  </div>
</template>

<style lang="scss" scoped>
.lock {
  width: 30px;
}
.market-selection {
  display: flex;
  flex-direction: column;
  background-color: var(--be-small-card-bg);
  color: rgba(255, 255, 255, 0.5);
  height: 45px;
  margin-right: 2px;
  font-size: 12px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;

  &.selected,
  .notebook &:hover:not(.locked),
  .desktop &:hover:not(.locked) {
    background-color: var(--be-primary-btn);
    // box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px, rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    //   rgba(0, 0, 0, 0.15) 0px -3px 0px inset, var(--be-primary-btn) 0px 0px 12px inset;
    color: var(--be-primary-btn-label);
    font-weight: 600;
  }
  .mobile & {
    height: 41px;
  }
  .divider {
    margin: 2px 0;
  }
}
</style>
