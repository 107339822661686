import { PhoneNumberUtil } from "google-libphonenumber";
import { useI18n } from "@/app/composables/useI18n";
const { t } = useI18n();
export function containsPlus(phone) {
  if (phone === null || phone !== "") {
    return true;
  }

  if (phone.includes("+")) {
    return true;
  }

  return "Invalid phone + missing";
}

export function isValidPhone(phone) {
  const phoneUtil = PhoneNumberUtil.getInstance();

  let validationMessage;
  try {
    const number = phoneUtil.parse(phone); // Replace 'US' with the default region if needed
    const isValid = phoneUtil.isValidNumber(number);
    validationMessage = isValid ? true : t("errors.invalid_phone");
  } catch (error) {
    validationMessage = t("errors.invalid_phone");
  }

  return validationMessage;
}

export const rules = [containsPlus, isValidPhone];
