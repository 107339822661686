<script setup>
import { computed } from "vue";
import { useI18n } from "@/app/composables/useI18n";
import LeagueSubheader from "@/sportsbook/components/LeagueSubheader.vue";

const props = defineProps({
  live: {
    type: Boolean,
    default: false,
  },

  full: {
    type: Boolean,
  },

  type: {
    type: String,
  },

  title: {
    type: String,
    required: true,
  },

  marketGroups: {
    type: Array,
    default: () => [],
  },

  // viewAll: {
  //   type: Boolean,
  //   default: false,
  // },

  showStatus: {
    type: Boolean,
    default: false,
  },

  // showMarketsDropdown: {
  //   type: Boolean,
  //   default: false,
  // },

  titleColor: {
    type: String,
    default: "#FFFFFF",
  },
});

const { t } = useI18n();

// function setActiveMarket(marketGroup, index) {
//   marketGroup.activeIndex = index;
//   // this.currentMarket = index;

//   // this.$emit("setActiveMarket", index);
// }
// marketName(market) {
//   if (!market) {
//     market = this.markets[this.currentMarket];
//   }
//   let mn = market?.featured_header ?? market?.key ?? market?.name ?? "";
//   mn = mn.toLowerCase().replaceAll(" ", "-");
//   // console.log(mn);
//   return t("markets." + mn);
// },

const timeStatus = computed(() => {
  return t("sportsbook.popular");
  // return this.live ? t("sportsbook.live") : t("sportsbook.upcoming");
});
</script>

<template>
  <div></div>
  <LeagueSubheader league-name="" :market-groups="marketGroups" />
  <!-- <div class="sport-subheader">
    <div class="subheader-title" :style="{ color: titleColor }">
      <span v-if="showStatus" :class="`time-status-${live ? 'live' : 'upcoming'}`">
        {{
          props.type == "upcoming"
            ? t("sportsbook.popular")
            : props.type == "today"
            ? t("sportsbook.live")
            : props.type == "todays"
            ? t("sportsbook.today_events")
            : t("sportsbook.popular")
        }}
      </span>
      <span class="title">{{ title }}</span>
    </div>
    <div class="market-groups">
      <div
        v-for="(marketGroup, index) in props.marketGroups"
        :key="index"
        class="market-group"
        :class="{
          'three-way': marketGroup.markets[marketGroup.activeIndex].headers.length === 3,
        }"
      >
        <template v-if="marketGroup.markets.length === 1">
          <div
            v-for="market in marketGroup.markets"
            :key="market.id"
            align="center"
            class="markets"
          >
            {{ market.name }}
          </div>
        </template>
        <template v-else-if="marketGroup.markets.length > 1">
          <div class="markets"></div>
        </template>
      </div>
    </div>
  </div> -->
</template>

<style lang="scss" scoped>
@import "../assets/scss/market-groups.scss";

.sport-subheader {
  /* text-transform: capitalize; */
  padding-left: 10px;
  background-color: var(--be-header-background-color);
  font-weight: bold;
  font-size: 14px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  justify-content: space-between;
  .subheader-title {
    color: #fff !important;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 15px 0;
    // padding-left: 15px;
    & .title {
      text-transform: capitalize;
      margin-left: 6px;
    }
    .time-status-live {
      color: #e96161;
    }
    .time-status-upcoming {
      color: #266def;
    }
  }
  .markets {
    text-align: center;
    white-space: nowrap;
    // min-width: 200px;
  }
}

.market-name {
  color: white;
  font-size: 13px;
  text-transform: none;
}

/* .details {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding-left: 15px;
} */

// .view-all {
//   padding-left: 10px;
//   font-size: 12px;
//   opacity: 0.8;
// }

// .view-all a {
//   color: #ffffff;
//   text-decoration: none;
// }

// .view-all a:hover {
//   text-decoration: underline;
// }

.featured-market {
  /*margin-right: 20px;*/
  font-size: 12px;
}
</style>
