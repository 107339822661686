<script>
import BaseLayout from "@/app/layouts/BaseLayout.vue";
import { onMounted, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "CasinoLayout",
  components: {
    BaseLayout,
  },
  setup() {
    const route = useRoute();
    const scrollToTop = () => {
      const scrollContainer = document.querySelector(`.brand-${import.meta.env.VITE_APP_BRAND}`);
      if (scrollContainer) {
        scrollContainer.scrollTo(0, 0);
      }
    };
    onMounted(() => {
      scrollToTop();
    });
    watch(
      () => route.fullPath,
      () => {
        scrollToTop();
      }
    );
  },
};
</script>

<template>
  <BaseLayout class="casino">
    <!-- <KeepAlive> -->
    <router-view :key="$route.fullPath" />
    <!-- </KeepAlive> -->
  </BaseLayout>
</template>

<style scoped></style>
