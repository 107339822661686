<script setup>
import { onMounted, ref } from "vue";
import SelectItem from "../../SelectItem.vue";
import BaseTextField from "@/app/components/ui/BaseTextField.vue";
import { t } from "@/app/composables/useI18n";
import { api } from "@/services";

const props = defineProps({
  currencyId: {
    type: Number,
    required: true,
  },
  providerId: {
    type: Number,
    required: true,
  },
});

const wallets = ref([]);
const withdrawalWallet = ref({});
const withdrawalFormData = ref({});
const loading = ref(false);
const selectedBank = ref({});
const accountNumber = ref("");
const amount = ref(0);

withdrawalWallet.value = {
  balance: 0,
  currency: {
    sign: "",
  },
};

async function loadWithdrawalFormData() {
  withdrawalFormData.value = await api.finance.withdrawalFormData({
    providerId: props.providerId,
    currencyId: props.currencyId,
  });
}

async function loadUserWallets() {
  // Load wallets
  wallets.value = await api.finance.userWallets();

  // Search withdrawal wallet
  if (wallets.value.length > 0) {
    const filteredWallets = wallets.value.filter((wallet) => {
      return wallet.currency.id === props.currencyId;
    });
    if (filteredWallets.length > 0) {
      withdrawalWallet.value = filteredWallets[0];
    }
  }
}

async function withdraw() {
  loading.value = true;
  await api.finance.withdraw({
    providerId: props.providerId,
    currencyId: props.currencyId,
    recipientBankCode: selectedBank.value.code,
    recipientAccountNumber: accountNumber.value,
    amount: amount.value,
  });
  loading.value = false;
}

onMounted(() => {
  loading.value = true;
  loadWithdrawalFormData();
  loadUserWallets();
  loading.value = false;
});
</script>

<template>
  <div>
    <div class="label">
      {{ t("finance.bank") }}
    </div>
    <div class="bank">
      <SelectItem :value="selectedBank.name">
        <template #header>
          <div v-for="bank in withdrawalFormData.banks" :key="bank.id" @click="selectedBank = bank">
            {{ bank.name }}
          </div>
        </template>
      </SelectItem>
    </div>

    <div>
      <div class="label">
        {{ t("finance.account_number") }}
      </div>
      <BaseTextField v-model="accountNumber" />
    </div>

    <div class="amount-container">
      <div class="amount-title">
        <div>{{ t("finance.amount") }}</div>
        <div class="digits">{{ withdrawalWallet.currency.sign }}{{ withdrawalWallet.balance }}</div>
      </div>
      <div class="amount">
        <input v-model="amount" type="number" class="amount-input" />
        <button class="max-btn" @click="amount = withdrawalWallet.balance">MAX</button>
      </div>
    </div>

    <button class="btn-g" :disabled="loading" @click="withdraw()">
      <span v-if="loading">{{ t("finance.loading") }}</span>
      <span v-else>{{ t("finance.withdraw") }}</span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.amount-container {
  margin: 10px 0px;
}

.bank {
  height: 40px;
  margin-bottom: 16px;
}

.label {
  margin-bottom: 7px;
  font-size: 14px;
}
.amount {
  border-radius: 4px;
  position: relative;
  display: flex;
  width: 100%;
  .amount-icon {
    position: absolute;
    top: 50%;
    transform: translate(8px, -50%);
  }

  .amount-input {
    border: none;
    background: var(--be-small-card-bg);
    height: 40px;
    border-radius: 4px 0 0 4px;
    outline: none;
    color: white;
    text-indent: 10px;
    width: 100%;
  }
}

.amount-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;

  .digits {
    color: rgba(255, 255, 255, 0.5);
  }
}

.max-btn {
  height: 40px;
  width: 50px;
  background: var(--Linear, linear-gradient(90deg, #3886ec 0%, #544fe5 100%));
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  border-radius: 0 4px 4px 0;
}

.btn-g {
  color: var(--be-primary-btn-label);
  border: none;
  height: 42px;
  width: 100%;
  border-radius: 4px;
  background: var(--Linear, linear-gradient(90deg, #3886ec 0%, #544fe5 100%));
  margin: 20px 0px;
  font-size: 14px;
}

.withdraw-desc {
  font-family: Poppins;
  font-size: 12px;
}
</style>
