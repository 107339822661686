export function isValidBetLimit(limit) {
  if (limit === "" || limit === null || limit === undefined || Number.parseInt(limit) === 0) {
    return true;
  }

  if (!/^\d+$/.test(limit)) {
    return "Number required";
  }

  return Number.parseFloat(limit) > 0 || "You must specify a number greater than zero";
}

export const rules = [isValidBetLimit];
