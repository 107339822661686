import { ref } from "vue";

const scores = ref([]);
const odds = ref([]);
const competitions = ref([]);

export function useLiveData() {
  return { scores, odds, competitions };
}

export function addScores(newScores) {
  newScores.forEach((score) => {
    const index = scores.value.findIndex(s => s.competitionId === score.competitionId);
    if (index >= 0) {
      scores.value[index] = score;
    } else {
      scores.value.push(score);
    }
  });
}

export function addOdds(newOdds) {
  newOdds.forEach((odd) => {
    const index = odds.value.findIndex(o => o.id === odd.id);
    if (index >= 0) {
      odds.value[index] = odd;
    } else {
      odds.value.push(odd);
    }
  });
}

export function addCompetitions(newCompetitions) {
  newCompetitions.forEach((competition) => {
    const index = competitions.value.findIndex(c => c.id === competition.id);
    if (index >= 0) {
      competitions.value[index] = competition;
    } else {
      competitions.value.push(competition);
    }
  });
}
