<script setup>
import { useI18n } from "@/app/composables/useI18n";
import { ref } from "vue";
const props = defineProps({
  show: Boolean,
});

const emit = defineEmits(["close"]);
const { t } = useI18n();
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask-bonus">
      <div class="modal-container">
        <div class="modal-detail">
          <div class="close-btn" @click="() => emit('close', false)">
            <div>{{ t("general.close") }}</div>
            <img src="/assets/images/close-modal-icon.svg" alt="" />
          </div>

          <div class="modal-body">
            <div class="free-bet">
              <div class="free-heading">{{ t("general.active_bonus") }}</div>
              <div class="free-award">{{ t("general.active_bonus_desc") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.chat-email {
  position: relative;
  display: flex;
  .chat-input {
    background: var(--be-small-card-bg);
    width: 100%;
    height: 30px;
    border-radius: 6px;
    outline: none;
    text-indent: 17px;
    color: white;
    opacity: 1;
    font-size: 12px;
    font-family: Rubik-med;
  }

  .chat-image {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 10px;
    cursor: pointer;
  }
}
.modal-mask-bonus {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(13, 12, 44, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  transition: opacity 0.3s ease;
}

.free-bet {
  border-radius: 0px;
  // background: var(--be-big-card-bg);
  padding: 20px 9px;
  margin-top: 10px;

  .btn {
    background: var(--be-primary-btn);
    font-size: 14px;
    font-family: Poppins;
    border-radius: 5px;
    width: 100%;
    height: 41px;
    margin-top: 20px;
    outline: none;
  }
  .free-heading {
    font-size: 20px;
    font-family: Poppins-bold;
    margin-bottom: 30px;
    text-align: center;
  }
  .free-award {
    font-size: 16px;
    font-family: Poppins-reg;
    margin-bottom: 30px;
    text-align: center;
  }

  .times {
    display: flex;
    justify-content: space-between;
    .time {
      font-size: 48px;
      font-family: Poppins;
    }
    .time-label {
      font-family: Poppins-reg;
      font-size: 14px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 500px) {
  .modal-mask-bonus {
    top: 2;
  }
}

.close-btn {
  color: white;
  position: absolute;
  top: -32px;
  right: 3px;
  display: flex;
  gap: 0 5px;
  cursor: pointer;
}

.modal-container {
  width: 320px;
  margin: auto;
  // padding: 0px 20px 20px 20px;

  height: 100%;
  overflow: auto;
  // background: var(--be-bg);

  transition: all 0.3s ease;
  position: relative;
  display: flex;
}

@media screen and (max-width: 500px) {
  .modal-container {
    padding: 0 16px 16px 16px;
  }
}

.modal-detail {
  background: var(--be-bg);
  padding: 0px 10px 10px 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  margin-top: 13px;
  border-radius: 20px;
  // margin: auto;
  margin: 280px 0 auto 0;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 500px) {
  .modal-detail {
    margin: auto;
    padding: 0px;
  }
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  border-radius: 8px;
  background: var(--be-big-card-bg);
}

.modal-body {
  border-radius: 8px;
  // background: var(--be-big-card-bg);
  padding: 10px 10px 0px 10px;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
