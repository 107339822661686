<script setup>
import { useBetslip } from "@/sportsbook/composables/useBetslip";
import { useStopwatch, useTimer } from "vue-timer-hook";
import { useTimeServer } from "@/app/composables/useTimeServer";
import moment from "moment";
import { router } from "@/app/router";
import { capitalizeHyphenatedString, matchStatuses, convertToTimeFormat } from "../utils/general";
const betslip = useBetslip();
const props = defineProps({
  match: {
    type: Object,
  },
  matchIndex: {
    type: Number,
  },
  slug: {
    type: String,
  },
  matchesName: {
    type: String,
  },
  tournamentId: {
    type: String,
  },
  countryId: {
    type: String,
  },
});

const autoStart = true;
const { timeServer } = useTimeServer();
const currentTime = moment(timeServer.value);
const givenTime = moment(props.match?.clock?.refPoint);
const difference = moment.duration(currentTime.diff(givenTime));
function durationToSeconds(duration) {
  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;

  const { years, months, days, hours, minutes, seconds, milliseconds } = duration;

  const totalSeconds =
    seconds +
    minutes * secondsInMinute +
    hours * secondsInHour +
    days * secondsInDay +
    milliseconds / 1000;

  return totalSeconds;
}

const stopwatch = useStopwatch(Math.floor(durationToSeconds(difference._data)), autoStart);

const time = new Date();
time.setSeconds(time.getSeconds() + Math.floor(durationToSeconds(difference._data))); // 10 minutes timer
const timer = useTimer(time);

function callToast() {
  toast.error("Max 20 selections per bet");
}
function toggleSelection(marketGroup, index, match) {
  const market = Object.values(match.markets).find(
    (m) => m.id === marketGroup.markets[marketGroup.activeIndex].id
  );
  const selection = market?.odds[index];
  selection.awayTeamName = match.awayTeamName;
  selection.homeTeamName = match.homeTeamName;
  selection.is_live = match?.isLive ? true : false;
  selection.startDate = match.startsAt;
  betslip.toggleSelection(selection, match, callToast);
}

function onItemClick(match) {
  router.push({
    name: "sport.event",
    params: {
      eventId: match.id,
    },
  });
}
</script>
<template>
  <div class="pop-item">
    <div class="item-up">
      <div class="item-half">
        {{
          slug == "football" && match?.clock?.isRunning
            ? convertToTimeFormat(
                `${stopwatch.minutes.value + stopwatch.hours.value * 60}:${
                  stopwatch.seconds.value
                } `
              )
            : (slug == "basketball" || slug == "ice-hockey") && match?.clock?.isRunning
            ? convertToTimeFormat(
                `${timer.minutes.value + timer.hours.value * 60}:${timer.seconds.value} `
              )
            : null
        }}
        {{ matchStatuses?.[capitalizeHyphenatedString(slug)]?.[match?.gameState] }}
      </div>

      <div class="item-block">
        <div class="item-name">{{ matchesName }}</div>
      </div>
    </div>
    <div class="item-data">
      <div class="item-name" @click="onItemClick(match)">
        <div class="overflow-name">{{ match.homeTeamName }}</div>
        <div class="overflow-name">{{ match.awayTeamName }}</div>
      </div>
      <div>
        <div>
          <span class="current">{{ match?.score?.home }} </span>
          <span v-if="slug == 'tennis'" class="current">{{ match?.score?.currentSet?.home }}</span>
          <span v-if="slug == 'tennis' || slug == 'table-tennis'">{{
            match?.score?.currentGame?.home
          }}</span>
        </div>
        <div>
          <span class="current">{{ match?.score?.away }} </span>
          <span class="current" v-if="slug == 'tennis'">{{ match?.score?.currentSet?.away }}</span>
          <span v-if="slug == 'tennis' || slug == 'table-tennis'">{{
            match?.score?.currentGame?.away
          }}</span>
        </div>
      </div>
      <div class="pop-blocks">
        <div
          :class="`pop-block  ${betslip.containsSelection(odd.id) ? ' pop-active' : null}`"
          v-for="(odd, oddIndex) in Object.values(match.markets)[0]?.odds"
          @click="
            () =>
              odd.isActive
                ? toggleSelection(
                    { markets: [Object.values(match.markets)[0].market], activeIndex: 0 },
                    oddIndex,
                    match
                  )
                : null
          "
          :data-selection-id="odd?.id"
          :data-event-id="odd?.eventId"
          :data-league-id="tournamentId"
          :data-market-id="odd?.marketId"
          :data-category-id="countryId"
          :data-odds-id="odd?.id"
        >
          <img v-if="!odd.isActive" class="lock" src="/assets/images/lock.png" alt="" />
          <div v-if="odd.isActive">
            {{ (Math.round(odd.odds * 100) / 100).toFixed(2) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.lock {
  width: 30px;
}

.current {
  margin-right: 5px;
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popular-block {
  width: 57%;
  margin-top: 10px;
  .popular-tab {
    background: var(--be-big-card-bg);
    display: flex;
    justify-content: space-between;
    height: 46px;
    align-items: center;
    padding: 0 10px;
    border-radius: 8px 8px 0px 0px;
  }

  .popular-btn {
    background: #ff0000;
    color: white;
    border-radius: 4px 4px 0px 0px;
    height: 30px;
    text-align: center;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 3px;
    font-size: 14px;
    font-weight: 500;
  }

  .view-all {
    font-size: 12px;
    opacity: 0.5;
    display: flex;
    gap: 0 10px;
    cursor: pointer;
    text-transform: capitalize;
  }
  .pop-item {
    background: #14132c;
    padding: 10px;
    margin-top: 4px;
    font-size: 12px;

    .item-up {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      text-transform: uppercase;

      .item-half {
        color: #ff453a;
      }
      .item-block {
        width: 230px;
        display: flex;
        justify-content: center;
        .item-name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 150px;
          text-align: center;
        }
      }
      @media screen and (max-width: 500px) {
        .item-block {
          width: 150px;
        }
      }
    }

    .item-data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 7px;
      .item-name {
        width: 150px;
        cursor: pointer;
      }

      @media screen and (max-width: 500px) {
        .item-name {
          width: 96px;
          text-align: left;

          .overflow-name {
            text-overflow: ellipsis;
            width: 96px;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
      .pop-blocks {
        display: flex;
        gap: 10px;
        width: 230px;
        justify-content: flex-end;
        flex-wrap: wrap;
        .pop-block {
          height: 46px;
          width: 70px;
          cursor: pointer;
          border-radius: 5px;
          color: rgba(255, 255, 255, 0.5);
          background: var(--be-small-card-bg);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media screen and (max-width: 500px) {
          .pop-block {
            width: 45px;
            height: 41px;
          }
        }

        .pop-active {
          background: var(--be-primary-btn);
          color: var(--be-primary-btn-label);
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .pop-item {
      width: 100%;
    }
  }
}

@media screen and (max-width: 500px) {
  .popular-block {
    width: 100%;
    padding: 10px 16px;
  }
}

.sports-wrapper {
  display: flex;
  padding: 0 0 0 10px;
  justify-content: space-between;
  .notebook & {
    flex-direction: column;
  }
  .mobile &,
  .tablet & {
    flex-direction: column-reverse;
    padding: 0;
  }
  .upcoming-games {
    display: flex;
    flex-direction: column;
    flex-basis: 40%;
    min-width: 0;
    margin: 0 0 12px 10px;
    .mobile &,
    .tablet & {
      flex-direction: column;
      margin-left: 0;
    }
  }
  .sports-feed {
    flex-basis: 50%;
    .mobile &,
    .tablet & {
      width: 100%;
    }
  }
}
</style>
