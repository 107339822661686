<template>
  <div class="table">
    <div class="label">Correct Score</div>
    <div class="tabs">
      <div class="tab-">
        <div>Barcelona</div>
        <div class="counter">
          <div class="btn">-</div>
          <div>1</div>
          <div class="btn">+</div>
        </div>
      </div>
      <div class="tab-">
        <div>Barcelona</div>
        <div class="counter">
          <div class="btn">-</div>
          <div>1</div>
          <div class="btn">+</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 10px;
  .label {
    color: var(--White, #fff);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .tab- {
    border-radius: 4px;
    background: var(--be-small-card-bg);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 39px;
    width: 100%;
  }

  .tabs {
    display: flex;
    gap: 0 10px;
  }

  .counter {
    display: flex;
    gap: 0 16px;
    color: #fff;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    .btn {
      width: 18px;
      height: 18px;
      background: white;
      color: black;
      border-radius: 4px;
      font-size: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .headings {
    background: var(--be-small-card-bg);
    display: flex;
    justify-content: center;
    height: 39px;
    align-items: center;
    border-radius: 4px 4px 0 0;
    color: #fff;

    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    .flex-item {
      flex: 33%;
      display: flex;
      justify-content: center;
    }
  }

  .item {
    display: flex;
    justify-content: center;
    height: 39px;
    // align-items: center;
    border-radius: 4px 4px 0 0;

    .flex-item {
      flex: 33%;
      display: flex;
      padding-left: 10px;
      align-items: center;
      border-right: 1px solid var(--be-small-card-bg);
      border-left: 1px solid var(--be-small-card-bg);
      border-bottom: 1px solid var(--be-small-card-bg);
      color: #fff;

      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .flex-item-center {
      flex: 33%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid var(--be-small-card-bg);
      border-bottom: 1px solid var(--be-small-card-bg);
      color: #f09426;

      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
</style>
