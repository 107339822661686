<script setup>
import { ref } from "vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { convertToLocalTime } from "@/sportsbook/utils/general";
import CasinoStakeIcon from "@/app/components/MyBets/Bets/CasinoStakeIcon.vue";

const props = defineProps({
  result: Number,
  ticket: {
    type: Object,
    required: true,
  },
});

const iconBase = "/assets/images/bets/mybets/";

function formatNumber(num) {
  num = Number.parseFloat(num);
  if (Number.isInteger(num)) {
    return num?.toString();
  } else {
    return num
      .toString()
      .replace(/(\.\d*[1-9])0+$/, "$1")
      .replace(/\.0+$/, "");
  }
}

const result = props.ticket.payout - props.ticket.bet;

const open = ref(false);

function getStatus(ticket) {
  if (ticket?.status === "open") {
    return "neutral";
  } else if (result > 0) {
    return "positive";
  } else if (result === 0) {
    return "neutral";
  } else {
    return "negative";
  }
}

function getType(ticket) {
  if (ticket.free_spin_id) {
    return "free-spin";
  }

  if (ticket.is_bonus) {
    return "bonus";
  }

  return "normal";
}
</script>

<template>
  <div class="open-bet">
    <div class="stakes-header" @click="open = !open">
      <div class="flex">
        <CasinoStakeIcon :status="getStatus(ticket)" :type="getType(ticket)" />
        <div class="flex stake">
          <div class="stake-name">
            <span
              :class="`stake-no ${
                ticket?.status == 'open'
                  ? 'stake-no--neutral'
                  : result > 0
                    ? 'stake-no--success'
                    : result == 0
                      ? 'stake-no--neutral'
                      : 'stake-no--red'
              }`"
            >{{
              ticket?.status == "open"
                ? formatNumber(ticket.bet)
                : result > 0
                  ? `+${result}`
                  : result
            }}</span>
          </div>
          <BaseIcon :name="ticket?.currency?.ticker.toLowerCase()" size="22" />
          <div class="game-name">
            {{ props.ticket.game.name }}
          </div>
        </div>
      </div>
      <img :src="`${iconBase}${open ? 'arrow-down' : 'arrow-right'}.svg`" alt="">
    </div>
    <div v-if="open" class="stake-detail">
      <div class="stake-detail-header">
        <div class="flex">
          <div class="odds">
            <div class="label">
              Stake:
            </div>
            <div class="session-no">
              {{ formatNumber(ticket.bet) }}
            </div>
            <BaseIcon :name="ticket?.currency?.name" size="17" />
          </div>
          <div class="flex">
            <div class="label">
              Pot Win:
            </div>
            <div class="session-no">
              {{ formatNumber(ticket.payout) }}
            </div>
            <BaseIcon :name="ticket?.currency?.name" size="17" />
          </div>
        </div>
      </div>
      <div>
        <div class="flex">
          <div class="label">
            Game:
          </div>
          <div>{{ ticket.game.name }}</div>
        </div>
        <div class="flex">
          <div class="label">
            Provider:
          </div>
          <div>{{ ticket.game.provider.name }}</div>
        </div>
        <div class="flex">
          <div class="label">
            Bet Time
          </div>
          <div>{{ convertToLocalTime(ticket.updatedAt) }}</div>
        </div>
      </div>
      <div class="stake-bet">
        <div class="flex">
          <div class="label">
            Bet ID
          </div>
          <div>{{ ticket.id }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.game-name {
  font-size: 14px;
  margin-left: 10px;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 97px;
}
.stake-detail {
  font-size: 12px;
  .stake-detail-header {
    display: flex;
    justify-content: space-between;
    padding: 13px 0;
  }

  .selection-label {
    color: #1382eb;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .session-no {
    margin-right: 4px;
  }

  .label {
    color: rgba(255, 255, 255, 0.5);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-right: 4px;
  }
}

.odds {
  display: flex;
  border-right: 1px solid var(--be-small-card-bg);
  padding-right: 8px;
  margin-right: 8px;
}

.stake-bet {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  border-top: 1px solid var(--be-small-card-bg);
  margin-top: 8px;
}
.stakes-header {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  background: var(--be-small-card-bg);
  padding-right: 8px;
}

.currency-icon {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.stake-name {
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  font-size: 14px;
}

.ml-8 {
  margin-left: 8px;
}

.paraly {
  color: rgba(255, 255, 255, 0.5);
  padding: 0 8px;
  display: flex;
  align-items: center;
  border-right: 1px solid var(--be-big-card-bg);
  margin: 7px 0;
  font-size: 14px;
}
.stake {
  display: flex;
  align-items: center;
  padding-right: 8px;
  margin: 7px 0 7px 4px;
  width: 313px;
}

@media screen and (max-width: 500px) {
  .stake {
    width: 229px;
  }
}

.stake-no {
  margin: 0 8px;
}

.stake-no--success {
  color: #07863a;
}
.stake-no--red {
  color: #d80027;
}

.stake-no--neutral {
  color: #ed8730;
}
.flex {
  display: flex;
}
.stake-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px 0 0 4px;
}

.stake-down {
  background: #d80027;
}
.stake-up {
  background: #07863a;
}

.stake-neutral {
  background: #ed8730;
}

.open-bet {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 10px;
  margin: 10px 16px;
}
</style>
