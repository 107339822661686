<script setup>
import { computed, ref } from "vue";

// import SportCoupons from "@/sportsbook/components/SportCoupons";
import { useSportsdata } from "../composables/useSportsdata.js";
import PageSelector from "@/sportsbook/components/PageSelector.vue";
import ProgressLoader from "@/app/components/ui/ProgressLoader.vue";
import ContentLoader from "@/app/components/ContentLoader.vue";

const props = defineProps({
  sportSlug: {
    type: String,
    required: true,
  },
});
const { getSportBySlug } = useSportsdata();
const loading = ref(false);

const sport = computed(() => {
  return getSportBySlug(props.sportSlug);
});
</script>

<template>
  <div>
    <ProgressLoader :loading="loading" />
    <div>
      <PageSelector v-if="sport" :active-sport="sport" />
      <router-view @loading="loading = $event" />
      <ContentLoader v-if="loading" />
    </div>
  </div>
</template>
