<script setup>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import { useBetStore } from "../../../store";

import UpcomingEventsWidget from "@/sportsbook/components/coming-soon/UpcomingEventsWidget.vue";
import { router } from "@/app/router";
import { watch, ref, onMounted, onBeforeUnmount } from "vue";
import { useSportsdata } from "@/sportsbook/composables/useSportsdata";
import { useBetslip } from "@/sportsbook/composables/useBetslip";
import { toast } from "@/app/utils/notification";
import { useI18n } from "@/app/composables/useI18n";
import "vue3-carousel/dist/carousel.css";
import { calculateTotalMatchLength } from "../utils/general";
import LiveRow from "../components/LiveRow.vue";
import { updateNestedProperties } from "@/app/utils/events";
import { useEventSource } from "@/sportsbook/composables/useEventSource";
import { useConfig } from "@/app/composables/useConfig";

const store = useBetStore();

onMounted(() => {
  const scrollContainer = document.querySelector(`.brand-${import.meta.env.VITE_APP_BRAND}`);
  if (scrollContainer) {
    scrollContainer.scrollTo(0, 0);
  }
});

const { fetchSportsEvents } = useSportsdata();
const { data, scoreData, error } = useEventSource();
const betslip = useBetslip();
const { t } = useI18n();

function viewAll() {
  router.push({
    name: "livebetting",
  });
}
const tournaments = ref({});
const sport = ref("");

function eventTime(time) {
  return new Date(time);
}

const { isDrawerOpen } = useConfig();

function eventTimeDay(time) {
  const day = eventTime(time).getDate().toString().padStart(2, "0");
  const month = (eventTime(time).getMonth() + 1).toString().padStart(2, "0");
  return `${day}/${month}`;
}

function eventTimeTime(time) {
  const hours = eventTime(time).getHours().toString().padStart(2, "0");
  const minutes = eventTime(time).getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}
function callToast() {
  toast.error("Max 20 selections per bet");
}

function toggleSelection(marketGroup, index, match) {
  const market = match.markets.find(
    (m) => m.id === marketGroup.markets[marketGroup.activeIndex].id
  );
  const selection = market?.odds[index];
  selection.awayTeamName = match.awayTeamName;
  selection.homeTeamName = match.homeTeamName;
  selection.is_live = !!match?.isLive;
  betslip.toggleSelection(selection, match, callToast);
}

function displayFirstTenMatches(matchesArray) {
  const matchesToShow = [];

  // Iterate through each league object in the array
  for (const league of matchesArray) {
    // Iterate through each match in the league
    for (const match of league.matches) {
      // Push the match into the matchesToShow array
      matchesToShow.push(match);

      // Check if we have reached 10 matches, if yes, break the loop
      if (matchesToShow.length === 10) {
        break;
      }
    }

    // If we have already reached 10 matches, break the outer loop as well
    if (matchesToShow.length === 10) {
      break;
    }
  }

  return matchesToShow;
}

const newAppendedData = ref([]);

watch(data, (newValue) => {
  if (newValue) {
    const newUpdatedData = newValue.map((item) => {
      const keys = [
        item.tournamentId,
        "matches",
        item.competitionId,
        "markets",
        item.marketType,
        "odds",
        item.id,
      ];
      const updates = {
        odds: item.price,
        isActive: item.is_active,
      };

      newAppendedData.value.push({
        ...item,
        temp: undefined,
        extraData: undefined,
        settlementData: undefined,
      });
      Object.values(tournaments.value).map((item) =>
        updateNestedProperties(item.tournaments, keys, updates)
      );
      //

      return item;
    });
  }
});

watch(scoreData, (newValue) => {
  if (newValue) {
    const newUpdatedData = newValue.map((item) => {
      const keys = [item.tournamentId, "matches", item.competitionId];
      const updates = item.score;
      updateNestedProperties(tournaments.value, keys, updates);

      return item;
    });
  }
});

const latestEvent = ref(null);
let worker = null;

const fetchSportsLive = async () => {
  let fetchSports;
  fetchSports = await fetchSportsEvents({
    timeFrame: "today",
    type: "live",
  });

  tournaments.value = fetchSports;
};

const screenWidth = ref(window.innerWidth);

function handleResize() {
  screenWidth.value = window.innerWidth;
}

onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize(); // Initial check
  fetchSportsLive();
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});

// const download = () => {
//   const blob = new Blob([JSON.stringify(newAppendedData.value)], { type: "text/plain" });
//   const a = document.createElement("a");
//   const url = window.URL.createObjectURL(blob);

//   a.href = url;
//   a.download = "code.js"; // Filename for the downloaded file

//   // Trigger the download
//   a.click();

//   // Clean up the URL object
//   window.URL.revokeObjectURL(url);
// };

const settings = {
  dots: true,
  dotsClass: "slick-dots custom-dot-class",
  edgeFriction: 0.35,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
</script>

<template>
  <div class="sports-wrapper" :class="isDrawerOpen ? '' : 'sports-wrapper-drawer'">
    <div class="upcoming-games">
      <UpcomingEventsWidget />
    </div>

    <!-- {{ calculatedValue }} -->
    <div class="popular-block">
      <div class="popular-tab">
        <div class="popular-btn">
          <img src="/assets/images/live/video-square.svg" alt="" />
          <div>{{ t("casino.popular") }} {{ t("sportsbook.live") }}</div>
        </div>
        <div class="view-all">
          <div @click="viewAll">
            {{ t("general.view-all") }} {{ t("sportsbook.live") }} ({{
              Object.values(tournaments).flatMap((item) => Object.values(item.tournaments))
                ? calculateTotalMatchLength(
                    Object.values(tournaments).flatMap((item) => Object.values(item.tournaments))
                  )
                : ""
            }})
          </div>
          <img src="/assets/images/live/view-all.svg" alt="" />
        </div>
      </div>

      <div
        v-for="(tournament, sectionIndex) in Object.values(tournaments)"
        v-if="screenWidth >= 568"
      >
        <div v-for="(matches, sectionIndex) in Object.values(tournament.tournaments)">
          <LiveRow
            v-for="(match, matchIndex) in Object.values(matches.matches)"
            :match-index="matchIndex"
            :match="match"
            :matches-name="matches.name"
            :slug="tournament?.slug"
            :tournament-id="matches?.id"
            :country-id="matches?.country?.id"
          />
        </div>
      </div>
      <Carousel v-if="screenWidth <= 568">
        <Slide
          v-for="({ match, matches, slug }, matchIndex) in Object.values(tournaments)
            .flatMap((item) => {
              return { tournaments: item.tournaments, slug: item.slug };
            })
            .flatMap(({ tournaments, slug }) =>
              Object.values(tournaments).map((it) => ({
                tournament: it,
                slug,
              }))
            )
            .flatMap(({ tournament, slug }) =>
              Object.values(tournament.matches).map((it) => ({
                match: it,
                matches: tournament.name,
                slug,
              }))
            )"
          :key="match"
        >
          <LiveRow
            :match-index="matchIndex"
            :match="match"
            :matches-name="matches.name"
            :slug="slug"
          />
        </Slide>

        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.lock {
  width: 30px;
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popular-block {
  width: 50%;
  margin-top: 10px;
  .popular-tab {
    background: var(--be-big-card-bg);
    display: flex;
    justify-content: space-between;
    height: 46px;
    align-items: center;
    padding: 0 10px;
    border-radius: 8px 8px 0px 0px;
  }

  .popular-btn {
    background: #ff0000;
    color: white;
    border-radius: 4px 4px 0px 0px;
    height: 30px;
    text-align: center;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 3px;
    font-size: 14px;
    font-weight: 500;
  }

  .view-all {
    font-size: 12px;
    opacity: 0.5;
    display: flex;
    gap: 0 10px;
    cursor: pointer;
    text-transform: capitalize;
  }
  .pop-item {
    background: var(--be-big-card-bg);
    padding: 10px;
    margin-top: 4px;
    font-size: 12px;

    .item-up {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      text-transform: uppercase;

      .item-half {
        color: #ff453a;
      }
      .item-block {
        width: 230px;
        display: flex;
        justify-content: center;
        .item-name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 150px;
          text-align: center;
        }
      }
      @media screen and (max-width: 500px) {
        .item-block {
          width: 150px;
        }
      }
    }

    .item-data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 7px;
      .item-name {
        width: 150px;
      }

      @media screen and (max-width: 500px) {
        .item-name {
          width: 96px;
          text-align: left;
        }
      }
      .pop-blocks {
        display: flex;
        gap: 10px;

        .pop-block {
          height: 46px;
          width: 70px;
          cursor: pointer;
          border-radius: 5px;
          color: rgba(255, 255, 255, 0.5);
          background: var(--be-small-card-bg);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media screen and (max-width: 500px) {
          .pop-block {
            width: 45px;
            height: 41px;
          }
        }

        .pop-active {
          background: #f09426;
          color: #17271a;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .pop-item {
      width: 100%;
      border-radius: 0 0 8px 8px;
      margin-top: 0px;
    }
  }
}

@media screen and (max-width: 1438px) {
  .popular-block {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .popular-block {
    width: 100%;
    padding: 10px 16px;
  }
}
.sports-wrapper {
  display: flex;
  // padding: 0 0 0 10px;
  justify-content: space-between;
  .notebook & {
    flex-direction: column;
  }
  .mobile &,
  .tablet & {
    flex-direction: column-reverse;
    padding: 0;
  }
  .upcoming-games {
    display: flex;
    flex-direction: column;
    flex-basis: 49%;
    min-width: 0;
    // margin: 0 0 12px 10px;
    .mobile &,
    .tablet & {
      flex-direction: column;
      margin-left: 0;
    }
  }
  .sports-feed {
    flex-basis: 50%;
    .mobile &,
    .tablet & {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1438px) {
  .sports-wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 1660px) {
  .sports-wrapper-drawer {
    flex-direction: column;
  }
}
</style>
