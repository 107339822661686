import { validateStakes } from "./betslipValidate";
import { useBetslip } from "@/sportsbook/composables/useBetslip";
import { useUserService } from "@/user-account/composables/useUserService";
import { api } from "@/services";
import { useSportsdata } from "../composables/useSportsdata";
import { findNumber } from "./general";

const { activeWallet, isAuthenticated } = useUserService();
const {
  betslipState,
  selections,
  multipleStake,
  multipleBet,
  waitingForBetConfirmation,
  hasMoreThanOneSelectionFromTheSameEvent,
  selectedFreeBetId,
} = useBetslip();

const { inActiveOddsIds } = useSportsdata();

let ticketsPayload = [];

async function placeBets(handleLogin, singleTab, acceptOddsChanges) {
  if (waitingForBetConfirmation.value) {
    return;
  }
  if (!isAuthenticated()) {
    // await router.push({ name: "login" });
    handleLogin();
  }

  if (!validateStakes(selections.value, multipleStake)) {
    return;
  }

  let tickets = [];

  // TODO: Fix the Straight and Parlay Bets

  // if (multipleBet.value.stake && !hasMoreThanOneSelectionFromTheSameEvent.value) {
  //   tickets = buildParlayPayload();
  // } else if (selections.value.length > 0) {
  //   tickets = buildStraightBetPayload();
  // }

  if (singleTab || selections.value.length == 1) {
    tickets = buildStraightBetPayload();
    ticketsPayload = buildStraightBetPayloadResponse();
  } else {
    tickets = buildParlayPayload();
    ticketsPayload = buildParlayPayloadResponse();
  }

  waitingForBetConfirmation.value = true;

  try {
    const data = await api.users.placeBet({
      tickets,
      walletId: activeWallet.value.id,
      acceptOddsChanges,
    });
    if (data) {
    }
  } catch (e) {
    console.error(e);
  }

  waitingForBetConfirmation.value = false;
}

function buildStraightBetPayload() {
  return selections.value
    .filter((o) => o.stake != null && o.stake != "" && !findNumber(inActiveOddsIds.value, o.id))
    .map((selection) => {
      const ticket = {
        type: "straight",
        stake: +selection?.stake,
        odds: selection.odds,
        freeBetId: selectedFreeBetId.value ? selectedFreeBetId.value : undefined,
        wagers: [
          {
            eventId: selection.eventId,
            oddsId: selection.id,
            odds: selection.odds,
            marketId: selection.marketId,
          },
        ],
      };

      return ticket;
    });
}

function buildParlayPayload() {
  const wagers = selections.value
    .map((selection) => {
      if (findNumber(inActiveOddsIds.value, selection.id)) {
        return;
      } else {
        return {
          eventId: selection.eventId,
          oddsId: selection.id,
          odds: selection.odds,
          marketId: selection.marketId,
        };
      }
    })
    .filter((item) => item !== undefined);

  const ticket = {
    type: "parlay",
    stake: +multipleBet.value.stake,
    toWin: +multipleBet.value.toWin,
    odds: +multipleBet.value.odds,
    freeBetId: selectedFreeBetId.value,
    wagers,
    // stake: +this.multipleStake,
    // toWin: +this.multipleReturns,
    // odds: +this.multipleAccumulator,
    // wagers,
  };

  return [ticket];
}

function buildStraightBetPayloadResponse() {
  return selections.value
    .filter((o) => o.stake != null && o.stake != "" && !findNumber(inActiveOddsIds.value, o.id))
    .map((selection) => {
      const ticket = {
        type: "straight",
        stake: +selection?.stake,
        odds: selection.odds,
        freeBetId: selectedFreeBetId.value ? selectedFreeBetId.value : undefined,
        wagers: [
          {
            eventName: selection.eventName,
            odds: selection.odds,
            selectionName: selection.name,
            marketName: selection.marketName,
            status: "open",
            data: { eventStartDate: selection.startDate },
          },
        ],
      };

      return ticket;
    });
}

function buildParlayPayloadResponse() {
  const wagersPayload = selections.value
    .map((selection) => {
      if (findNumber(inActiveOddsIds.value, selection.id)) {
        return;
      } else {
        return {
          eventName: selection.eventName,
          odds: selection.odds,
          selectionName: selection.name,
          marketName: selection.marketName,
          status: "open",
          data: { eventStartDate: selection.startDate },
        };
      }
    })
    .filter((item) => item !== undefined);
  const ticketPayload = {
    type: "parlay",
    stake: +multipleBet.value.stake,
    toWin: +multipleBet.value.toWin,
    odds: +multipleBet.value.odds,
    freeBetId: selectedFreeBetId.value,
    wagers: wagersPayload,
    // stake: +this.multipleStake,
    // toWin: +this.multipleReturns,
    // odds: +this.multipleAccumulator,
    // wagers,
  };

  return [ticketPayload];
}

function calculateMultipleOdds(selections) {
  // const odds = 0;

  return selections
    .reduce((acc, selection) => {
      // if (acc === 0) {
      //   if (isFractional(selection.odds)) {
      //     acc = toDecimal(selection.odds);
      //     return;
      //   }
      return acc * selection.odds;
    }, 1)
    .toFixed(2);

  // selections.forEach((selection) => {
  //   // if (getters.isUnavailable(selection.id)) {
  //   //   return;
  //   // }

  //   if (odds === 0) {
  //     if (isFractional(selection.odds)) {
  //       odds = toDecimal(selection.odds);
  //       return;
  //     }

  //     odds = selection.odds;
  //   } else {
  //     if (isFractional(selection.odds)) {
  //       odds *= toDecimal(selection.odds);
  //       return;
  //     }

  //     odds *= selection.odds;
  //   }
  // });

  // return Number(odds);
}

export {
  buildStraightBetPayload,
  buildParlayPayload,
  calculateMultipleOdds,
  placeBets,
  ticketsPayload,
};
