<script setup>
import { computed, ref } from "vue";
import { useBetStore } from "../../../../store";
import BetslipMobileMenuToggle from "@/sportsbook/components/betslip/BetslipMobileMenuToggle.vue";
import BaseButton from "@/app/components/ui/BaseButton.vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { useUserService } from "@/user-account/composables/useUserService.ts";
import { useI18n } from "@/app/composables/useI18n";
import { useRoute } from "@/app/router";
import { ellipsis } from "@/app/utils/strings";
import { useConfig } from "@/app/composables/useConfig";
import CasinoBonusModal from "../modal/CasinoBonusModal.vue";
import { usePromotion } from "@/app/composables/usePromotion";
import BonusNull from "@/app/components/modal/BonusNull.vue";
import FreeModal from "../modal/FreeModal.vue";
import { useFreeBets } from "@/sportsbook/composables/useFreeBets";

const { isDrawerOpen } = useConfig();

const { activeBonus } = usePromotion();

const store = useBetStore();

const activeButton = ref(0);
const { freeBets } = useFreeBets();

const { t, locale } = useI18n();

const route = useRoute();
const userStore = useUserService();
function handleShowLoginModal() {
  store.handleShowLogin();
}

function handleShowModal() {
  if (userStore.isAuthenticated()) {
    store.handleShowBetModal();
  } else {
    handleShowLoginModal();
  }
}

function handleShowModalAndBet() {
  isDrawerOpen.value = false;
  if (userStore.isAuthenticated()) {
    if (store.betModal && store.section == "bet") {
      handleShowModal();
    } else if (store.betModal && store.section != "bet") {
      store.setSection("bet");
    } else {
      handleShowModal();
      store.setSection("bet");
    }
  } else {
    handleShowLoginModal();
  }
  activeButton.value = 1;
}

function handleShowModalAndAccount() {
  isDrawerOpen.value = false;
  if (userStore.isAuthenticated()) {
    if (store.betModal && store.section == "account") {
      handleShowModal();
    } else if (store.betModal && store.section != "account") {
      store.setSection("account");
    } else {
      handleShowModal();
      store.setSection("account");
    }
  } else {
    handleShowLoginModal();
  }
  activeButton.value = 3;
}

function handleShowDrawer() {
  if (userStore.isAuthenticated()) {
    store.handleShowBetModal(false);
  }
  isDrawerOpen.value = !isDrawerOpen.value;
  activeButton.value = 0;
}

const buttons = computed(() => {
  const data = [
    {
      name: "A - Z",
      route: { name: "/" },
      icon: "hamburger",
    },
    {
      name: "My Bets",
      route: { name: "casino.game", params: { id: 4499 } },
      icon: "my-bet",
    },
    {
      name: t("general.bonuses"),
      route: "",
      icon: "gift",
    },
    {
      name: "account.account_title",
      route: { name: "login" },
      icon: "account",
    },
  ];
  // if (userStore.isAuthenticated()) {
  //   data[3].name = "account.account_title";
  //   data[3].route = "account";
  // }
  return data;
});
const showFreeBetModal = ref(false);

const handleBonusModal = () => {
  isDrawerOpen.value = false;
  if (userStore.isAuthenticated()) {
    if (activeBonus.value) {
      showFreeBetModal.value = !showFreeBetModal.value;
    } else {
      // alert("Active bonus is null");
      showBonusNull.value = true;
    }
  } else {
    store.handleShowLogin();
  }
};

const handleShowFreeModal = () => {
  if (userStore.isAuthenticated()) {
    showFreeBetModal.value = !showFreeBetModal.value;
  } else {
    store.handleShowLogin();
  }
};
const showBonusNull = ref(false);
</script>

<template>
  <nav class="mobile-menu">
    <!-- <BaseButton
      v-for="(button, index) in buttons"
      :key="index"
      class="navigation-btn text"
      :class="{ active: activeButton === index }"
      :to="button.route"
    > -->
    <BonusNull :show="showBonusNull" @close="() => (showBonusNull = false)" />
    <!-- <FreeModal :show="showFreeBetModal" @close="handleShowFreeModal" /> -->
    <CasinoBonusModal :show="showFreeBetModal" @close="handleShowFreeModal" :bonus="activeBonus" />
    <BaseButton
      class="navigation-btn text"
      :class="{ active: activeButton === 0 }"
      @click="handleShowDrawer"
    >
      <div :class="isDrawerOpen ? 'active-mbl' : 'non-active'" />
      <BaseIcon :name="buttons[0].icon" />
      <span class="title"
        >{{
          [
            "sports.upcoming",
            "sport.tournament",
            "sport.league",
            "sport.competition",
            "livebetting",
          ].includes($route.name)
            ? t(`general.sportsbook`)
            : $route.name == "virtualGames"
            ? t(`general.virtualGames`)
            : t(`general.casino`)
        }}
        {{ t(buttons[0].name) }}</span
      >
    </BaseButton>

    <BaseButton
      class="navigation-btn text"
      :class="{ active: activeButton === 1 }"
      @click="handleShowModalAndBet"
    >
      <div :class="store.getSection === 'bet' && store.betModal ? 'active-mbl' : 'non-active'" />
      <BaseIcon :name="buttons[1].icon" />
      <span class="title">{{ t(buttons[1].name) }}</span>
    </BaseButton>

    <BaseButton
      class="navigation-btn text"
      :class="{ active: activeButton === 2 }"
      @click="handleBonusModal"
    >
      <!-- <BaseIcon :name="buttons[1].icon" /> -->
      <!-- <LanguageSelect class="open-up" /> -->
      <div :class="activeButton === 2 ? 'active-mbl' : 'non-active'" />
      <BaseIcon :name="buttons[2].icon" />
      <span class="title">{{ t(buttons[2].name) }}</span>
    </BaseButton>

    <BaseButton
      class="navigation-btn text"
      :class="{ active: activeButton === 3 }"
      @click="handleShowModalAndAccount"
    >
      <div
        :class="store.getSection === 'account' && store.betModal ? 'active-mbl' : 'non-active'"
      />
      <BaseIcon :name="buttons[3].icon" />
      <span class="title">{{ ellipsis(t(buttons[3].name), 10) }}</span>
    </BaseButton>

    <BetslipMobileMenuToggle />
  </nav>
</template>

<style lang="scss" scoped>
.mobile-menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  padding: 0px 0 1px;
  box-shadow: 1px -7px 11px 1px #363535;
  // height: 45px;
  transform: none;
  background-color: var(--be-small-card-bg);
  .navigation-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 3px;
    width: 25%;
    color: #ccc;
    justify-content: space-between;
    // opacity: 0.6;

    transition: all 0.3s ease-out;

    .active-mbl {
      border-top: 4px solid var(--be-primary-btn);
      border-radius: 0px 0px 10px 10px;
      height: 4px;
      width: 100%;
    }

    .non-active {
      height: 4px;
      width: 4px;
    }
    // background-color: none;
    &.spacer {
      width: 10%;
    }
    i {
      color: var(--be-primary-text-color);
    }
    .title {
      // line-height: 1.2;
      letter-spacing: 1px;
      font-size: 10px;
      white-space: nowrap;
    }
    &.router-link-active {
      opacity: 1;
    }
    &:hover::before {
      opacity: 0;
    }
  }
}
</style>
