<script setup>
import Session from "../Session.vue";
import { api } from "@/services";
import { ref, onMounted, computed } from "vue";
import { useBetStore } from "../../../../../../store";
import { useUserService } from "@/user-account/composables/useUserService";
import { t } from "@/app/composables/useI18n";

const store = useBetStore();

const allTickets = ref([]);
const loading = ref(false);
const totalCount = ref(0);
const page = ref(1);
const { isAuthenticated, activeWallet } = useUserService();

async function fetchBets(cp = 1) {
  if (!isAuthenticated()) {
    store.handleShowLogin();
    // await router.push({ name: "login" });
    return;
  }

  loading.value = true;
  // const { data, meta } = await handler(0, user.activeWallet.id);
  // const { data } = await handler(0, user.activeWallet.id);
  const data = await api.users.casinoTickets({
    paginator: {
      cp: cp,
      rpp: 10,
    },
    providerAlias: "virtualgames",
  });

  allTickets.value = [...allTickets.value, ...data?.bets];
  totalCount.value = data?.totalCount;
  loading.value = false;

  // lastPage.value = meta.last_page;
}

onMounted(fetchBets);

const handleFetchMore = () => {
  page.value = page.value + 1;
  fetchBets(page.value);
};
</script>

<template>
  <div v-if="allTickets.length > 0">
    <div v-for="ticket in allTickets" :key="ticket.id">
      <Session :ticket="ticket" :result="34" />
      <!-- <Session :result="-34" /> -->
    </div>
  </div>
  <div v-else>
    <div class="empty-data">
      {{ t("general.dont_have") }} {{ t("general.virtualGames") }} {{ t("general.bets") }}
    </div>
  </div>
  <button
    @click="handleFetchMore"
    v-if="allTickets.length < totalCount && allTickets.length != 0"
    class="load-more"
  >
    Load More
  </button>
</template>

<style lang="scss" scoped>
.load-more {
  background: var(--be-primary-btn);
  height: 45px;
  width: calc(100% - 32px);
  border-radius: 8px;
  margin: 10px 16px;
  color: var(--be-primary-btn-label);
}
.empty-data {
  text-align: center;
  margin: 40px 0;
}
</style>
