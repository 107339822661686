<script setup>
import { ref } from "vue";
import { api } from "@/services";
import { toast } from "@/app/utils/notification";
import { useI18n } from "@/app/composables/useI18n";
import { useAuth } from "@/user-account/composables/useAuth";
import { useBetStore } from "/store";
import BaseButton from "@/app/components/ui/BaseButton.vue";
import BaseSelectField from "@/app/components/ui/BaseSelectField.vue";

const { t } = useI18n();
const { logout } = useAuth();
const store = useBetStore();

const periods = ref([
  {
    label: `1 ${t("common.day")}`,
    value: "day",
  },
  {
    label: `1 ${t("common.week")}`,
    value: "week",
  },
  {
    label: `1 ${t("common.month")}`,
    value: "month",
  },
  {
    label: `1 ${t("common.year")}`,
    value: "year",
  },
]);
const selectedPeriod = ref(null);

async function enableSelfExclusion() {
  try {
    const result = await api.users.enableSelfExclusion(selectedPeriod.value);

    if (result?.success === true) {
      toast.info(t("self_exclusion.success_message"));
      store.handleShowBetModal(false);
      logout();
    } else {
      toast.error(t("self_exclusion.error_message"));
    }
  } catch (error) {
    toast.error(t("self_exclusion.error_message"));
  }
}
</script>

<template>
  <div class="container">
    <div class="form">
      <div class="form-fields">
        <BaseSelectField
          :label="t('self_exclusion.period')"
          :placeholder="t('self_exclusion.placeholder')"
          :options="periods"
          @update:model-value="selectedPeriod = $event"
        />
      </div>

      <div class="form-fields self-exclusion-warning" v-if="selectedPeriod !== null">
        {{ t("self_exclusion.warning") }}
      </div>

      <BaseButton class="submit alight wide high" type="button" @click="enableSelfExclusion()">
        {{ t("account.save") }}
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.self-exclusion-warning {
  color: red;
}

.margin-top {
  margin-top: 15px;
}

.label {
  font-size: 14px;
  margin-bottom: 7px;
}

@media screen and (max-width: 500px) {
  .container {
    height: 100%;
  }
}

.form {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 16px;
  margin-bottom: 1rem;
}

.btn-g {
  color: var(--be-primary-btn-label);
  border: none;
  height: 42px;
  width: 100%;
  border-radius: 4px;
  background: var(--Linear, linear-gradient(90deg, #3886ec 0%, #544fe5 100%));
  margin: 20px 0px;
  font-size: 14px;
}
</style>
