import { ref } from "vue";
import { api } from "@/services";
import { inActiveOdds, uniqueArray, inActiveOddsWithoutTournament } from "../utils/general";

const sports = ref([]);
const inActiveOddsIds = ref([]);
// const markets = ref([]);

export function useSportsdata() {
  // if (sports.value.length === 0) {
  //   initialize();
  // }

  async function initialize() {
    await loadSports();
  }

  async function loadSports() {
    sports.value = await api.sportsdata.sports();
  }

  async function setSports(_sports) {
    sports.value = _sports;
  }

  function getSportBySlug(slug) {
    return sports.value.find((s) => s.slug === slug);
  }

  function featuredMarkets(sportId) {
    const sport = sports.value.find((s) => s.id === sportId);
    return sport?.featuredMarkets;
  }

  async function fetchSportEvents(sportId, filters = {}) {
    if (sportId) {
      // loading.value = true;
      const data = await api.sportsdata.events({
        sportId,
        filters,
      });

      inActiveOddsIds.value = uniqueArray([
        ...inActiveOddsIds.value,
        ...inActiveOddsWithoutTournament(data.tournaments),
      ]);
      // loading.value = false;
      return data.tournaments;
    }
  }

  async function fetchSportsEvents(filters = {}) {
    const data = await api.sportsdata.events({
      filters,
    });
    inActiveOddsIds.value = uniqueArray([...inActiveOddsIds.value, ...inActiveOdds(data)]);

    return data;
  }

  return {
    initialize,
    sports,
    setSports,
    getSportBySlug,
    featuredMarkets,
    fetchSportsEvents,
    fetchSportEvents,
    inActiveOddsIds,
  };
}
