<script setup>
import { ref, watchEffect } from "vue";
import { useGlobalLoader } from "@/app/composables/useGlobalLoader";

const off = ref(true);

const { isActive } = useGlobalLoader();
watchEffect(() => {
  if (isActive.value) {
    off.value = false;
  } else {
    setTimeout(() => {
      off.value = true;
    }, 700);
  }
});
</script>

<template>
  <div class="overlay" :class="{ active: isActive, off }">
    <div class="overlay-content">
      <div class="container centering-block">
        <div class="loader centered">
          <div class="square square_one" />
          <div class="square square_two" />
          <div class="square square_three" />
          <div class="square square_four" />
          <div class="square square_five" />
          <div class="square square_six" />
          <div class="square square_seven" />
          <div class="square square_eight" />
          <div class="square square_nine" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(1, 2, 5, 0.8);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  visibility: visible;
  &.active {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  &.off {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
  .overlay-content {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 45%;
    right: 0;
    text-align: center;
    color: #555;
    opacity: 0.8;
    .mobile & {
      left: 40%;
    }
  }
}

$white: #FFFFFF;
$light: #f2ece4;
$dark: #ffe81a;
$animation-time: 10s;
$roll-dice-time: calc($animation-time/4.5);

.container{
    height: 100%;
}

.centering-block {
  text-align: center;
}

.centering-block:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}

.centered {
  display: inline-block;
  vertical-align: middle;
  width: 300px;
}
.loader{
    // display: inline-block;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 80px;
    animation: roll_dice $roll-dice-time infinite ease-in;

  .square {
    background-color: $white;
    width: 20px;
    height: 20px;
    margin: 3px;
    display: inline-block;
    opacity: 1;
    transition: background-color 0.25s;
  }

  .row {
    width: 68px;
    margin: 20px auto;
  }

  .square_one {
    animation: square_one $animation-time infinite;
  }

  .square_two {
    animation: square_two $animation-time infinite;
  }

  .square_three {
    animation: square_three $animation-time infinite;
  }

  .square_four {
    animation: square_four $animation-time infinite;
  }

  .square_five {
    animation: square_five $animation-time infinite;
  }

  .square_six {
    animation: square_six $animation-time infinite;
  }

  .square_seven {
    animation: square_seven $animation-time infinite;
  }

  .square_eight {
    animation: square_eight $animation-time infinite;
  }

  .square_nine {
    animation: square_nine $animation-time infinite;
  }
}

@keyframes roll_dice{
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes square_one {
  0% { background-color: $white; }
  12.5% { background-color: $white; }
  25% { background-color: $dark; }
  37.5% { background-color: $white; }
  50% { background-color: $dark; }
  62.5% {background-color: $dark;
    box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px,
    rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
    rgb(255, 135, 25) 0px 0px 12px inset;
  }
  75% {background-color: $dark; }
  87.5% {background-color: $light; }
  100% {background-color: $white; }
}

@keyframes square_two {
  0% { background-color: $white; }
  12.5% { background-color: $white; }
  25% { background-color: $white; }
  37.5% { background-color: $white; }
  50% { background-color: $white; }
  62.5% {background-color: $white; }
  75% {background-color: $white; }
  87.5% {background-color: $light; }
  100% {background-color: $white; }
}

@keyframes square_three {
  0% { background-color: $white; }
  12.5% { background-color: $white; }
  25% { background-color: $white; }
  37.5% { background-color: $dark; }
  50% { background-color: $dark;
    box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px,
    rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
    rgb(255, 135, 25) 0px 0px 12px inset; }
  62.5% {background-color: $dark;
    box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px,
    rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
    rgb(255, 135, 25) 0px 0px 12px inset;}
  75% {background-color: $dark; }
  87.5% {background-color: $light; }
  100% {background-color: $white; }
}

@keyframes square_four {
  0% { background-color: $white; }
  12.5% { background-color: $white; }
  25% { background-color: $white; }
  32.5% { background-color: $white; }
  50% { background-color: $white; }
  62.5% {background-color: $white; }
  75% {background-color: $dark;
    box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px,
    rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
    rgb(255, 135, 25) 0px 0px 12px inset;}
  87.5% {background-color: $light; }
  100% {background-color: $white; }
}

@keyframes square_five {
  0% { background-color: $white; }
  12.5% { background-color: $dark;
    box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px,
    rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
    rgb(255, 135, 25) 0px 0px 12px inset;}
  25% { background-color: $white; }
  37.5% { background-color: $dark;
    box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px,
    rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
    rgb(255, 135, 25) 0px 0px 12px inset; }
  50% { background-color: $white; }
  62.5% {background-color: $dark;
    box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px,
    rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
    rgb(255, 135, 25) 0px 0px 12px inset;}
  75% {background-color: $white; }
  87.5% {background-color: $light; }
  100% {background-color: $white; }
}

@keyframes square_six {
  0% { background-color: $white; }
  12.5% { background-color: $white; }
  25% { background-color: $white; }
  37.5% { background-color: $white; }
  50% { background-color: $white; }
  62.5% {background-color: $white; }
  75% {background-color: $dark;
    box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px,
    rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
    rgb(255, 135, 25) 0px 0px 12px inset;}
  87.5% {background-color: $light; }
  100% {background-color: $white; }
}

@keyframes square_seven {
  0% { background-color: $white; }
  12.5% { background-color: $white; }
  25% { background-color: $white; }
  37.5% { background-color: $dark; }
  50% { background-color: $dark;
    box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px,
    rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
    rgb(255, 135, 25) 0px 0px 12px inset;}
  62.5% {background-color: $dark;
    box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px,
    rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
    rgb(255, 135, 25) 0px 0px 12px inset;}
  75% {background-color: $dark; }
  87.5% {background-color: $light; }
  100% {background-color: $white; }
}

@keyframes square_eight {
  0% { background-color: $white; }
  12.5% { background-color: $white; }
  25% { background-color: $white; }
  37.5% { background-color: $white; }
  50% { background-color: $white; }
  62.5% {background-color: $white; }
  75% {background-color: $white; }
  87.5% {background-color: $light; }
  100% {background-color: $white; }
}

@keyframes square_nine {
  0% { background-color: $white; }
  12.5% { background-color: $white; }
  25% { background-color: $dark;
    box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px,
    rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
    rgb(255, 135, 25) 0px 0px 12px inset;}
  37.5% { background-color: $white; }
  50% { background-color: $dark; }
  62.5% {background-color: $dark;
    box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px,
    rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
    rgb(255, 135, 25) 0px 0px 12px inset;}
  75% {background-color: $dark; }
  87.5% {background-color: $light; }
  100% {background-color: $white; }
}
</style>
