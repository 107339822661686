<script setup>
const props = defineProps({
  status: {
    type: String,
    default: "open",
    validator: prop => ["open", "won", "lost", "void"].includes(prop),
  },
  type: {
    type: String,
    default: "normal",
    validator: prop => ["normal", "free-bet"].includes(prop),
  },
});

const icon = "/assets/images/bets/mybets/sax.svg";

const texts = {
  "normal": { line1: "", line2: "" },
  "free-bet": { line1: "FREE", line2: "BET" },
  "bonus": { line1: "BONUS", line2: "BET" },
};
</script>

<template>
  <div class="icon" :class="status">
    <div>{{ texts[type].line1 }}</div>
    <img :src="icon">
    <div>{{ texts[type].line2 }}</div>
  </div>
</template>

<style lang="scss" scoped>
.icon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px 0 0 4px;
  display: inline-flex;
  flex-direction: column;
  font-size: 8px;
}

.won {
  background: #07863a;
}

.lost {
  background: #d80027;
}

.open {
  background: #ed8730;
}

.void {
  background: #5a5a5a;
}

@media screen and (max-width: 500px) {
  .icon {
    height: 36px;
    width: 36px;
    font-size: 5px;
  }
}
</style>
