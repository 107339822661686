import axios from "axios";
import { useBetStore } from "../../../store";
import { useAuth } from "../../user-account/composables/useAuth";
import auth from "./interceptors/auth";
import { t } from "@/app/composables/useI18n";
import { toast } from "@/app/utils/notification";
import { UnprocessableEntityError } from "@/exceptions";

const baseURL = `${import.meta.env.VITE_API_URL}api`;

const client = axios.create({
  baseURL,
  timeout: 30000,
  headers: { "Content-Type": "application/json", "Accept": "application/json" },
  responseType: "json",
  responseEncoding: "utf8",
});

client.interceptors.request.use(auth);
client.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response) {
      const { data, status } = error.response;
      const { message, i18n, errors, details } = data || {};

      // Show toast warning if a message exists
      message && toast.warn(i18n ? t(`errors.${i18n}`) : message);

      // Handle validation errors
      if (errors) {
        throw new UnprocessableEntityError("Validation Failed", errors, data);
      }

      // Handle unauthorized access
      if (status === 401) {
        const auth = useAuth();
        const betStore = useBetStore();
        auth.logout();
        betStore.setTimeValue(false);
      }
    }

    return Promise.reject(data); // Reject the promise with the error
  },
);

export default client;
