<script setup>
import { computed, ref } from "vue";
import { useBetslip } from "@/sportsbook/composables/useBetslip.js";
import { useI18n } from "@/app/composables/useI18n";
import { useUserService } from "@/user-account/composables/useUserService";
import { useCurrencies } from "@/app/composables/useCurrencies";
import StakeField from "@/app/components/BetSlip/StakeField.vue";
import { useSportsdata } from "@/sportsbook/composables/useSportsdata";
import { findNumber } from "@/sportsbook/utils/general";
import { useFreeBets } from "@/sportsbook/composables/useFreeBets";

const props = defineProps({
  selection: {
    type: Object,
    required: true,
  },
  singleTab: {
    type: Boolean,
    required: true,
  },
  index: {
    type: String,
  },
});
const { removeSelection, selections, selectedFreeBet, selectFreeBet } = useBetslip();
const { activeFreeBets } = useFreeBets();
const { activeWallet } = useUserService();
const { t } = useI18n();
const { getIconByTicker } = useCurrencies();
const { inActiveOddsIds } = useSportsdata();

const toWin = computed(() => {
  if (!props.selection.odds || !props.selection.stake) {
    return "0.00";
  }
  return (props.selection.odds * props.selection.stake).toFixed(4);
});

const InActive = ref(false);
InActive.value = findNumber(inActiveOddsIds.value, props.selection.id);

const handleStackClick = () => {
  if (selectedFreeBet.value && activeFreeBets.value.length > 0) {
    selectFreeBet(activeFreeBets.value[0].id, activeFreeBets.value[0].amount, props.index);
  }
};
</script>

<template>
  <!-- <div> -->
  <div
    :class="`slip-card ${!selection.isActive ? 'suspended-background' : ''} ${
      singleTab || selections.length == 1 ? '' : 'slip-card--multiple'
    }`"
  >
    <div v-if="!selection.isActive" class="suspended">Suspended</div>
    <!-- <img class="suspended" src="/assets/images/lock.png" alt="" /> -->
    <div :class="`stake-header   ${singleTab || selections.length == 1 ? '' : 'w--half'}`">
      <div :class="`stake-title ${singleTab || selections.length == 1 ? '' : 'w--full'}`">
        <img
          class="delete-icon"
          src="/assets/images/livebetting/delete.svg"
          alt=""
          @click="removeSelection(props.selection.id)"
        />
        <div :class="`title ${!selection.isActive ? 'opacity-half' : ''}`">
          {{
            selection.header == "Home"
              ? selection.homeTeamName
              : selection.header == "Away"
              ? selection.awayTeamName
              : selection.header
          }}
          @
          <!-- </div> -->
          <span :class="`digit ${!selection.isActive ? 'opacity-half' : ''}`">
            {{ selection.odds }}
          </span>
        </div>
      </div>
      <div>
        <StakeField
          :class="`${!selection.isActive ? 'opacity-half' : ''}`"
          :disabled="!selection.isActive"
          v-if="singleTab || selections.length == 1"
          v-model="props.selection.stake"
          :placeholder="t('bets.stake')"
          @click="handleStackClick"
          :readonly="selectedFreeBet && activeFreeBets.length > 0"
        />
      </div>
    </div>
    <div
      :class="`${singleTab || selections.length == 1 ? 'ml--26' : 'w--45'} ${
        !selection.isActive ? 'opacity-half' : ''
      }`"
    >
      <div class="detail">
        <div>{{ selection.marketName }}</div>
      </div>

      <div class="detail">
        <div>{{ selection.eventName }}</div>
      </div>
    </div>
    <div v-if="singleTab || selections.length == 1" class="win-detail">
      <div>{{ t("bets.to_win") }}</div>
      <div class="currency-digit">: {{ +toWin }}</div>
      <img class="img-icon" :src="getIconByTicker(activeWallet?.currency?.ticker)" alt="" />
    </div>
  </div>
  <!-- </div> -->
</template>

<style lang="scss" scoped>
.slip-card {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 10px;
  position: relative;
}

.opacity-half {
  opacity: 0.5;
}

.suspended {
  position: absolute;
  top: 50%;
  left: 50%;
  // width: 25px;
  transform: translate(-50%, -50%);
}

.slip-card--multiple {
  display: flex;
  justify-content: space-between;
}

.w--45 {
  width: 45%;
}

.img-icon {
  width: 16px;
  height: 16px;
}

.w--half {
  width: 50%;
}

.stake-header {
  display: flex;
  justify-content: space-between;

  .stake-title {
    display: flex;
    gap: 0 10px;
    font-size: 14px;
    width: 63%;

    .title {
      width: 100%;
      overflow-wrap: break-word;
    }
  }
  .w--full {
    width: 100%;
  }

  .w--half {
    width: 50%;
  }
}

.digit {
  color: #f09426;
}

.stake {
  border-radius: 4px;
  background: var(--be-small-card-bg);
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  padding-left: 8px;
  .stake-btc {
    background: var(--be-divider);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 0 4px 4px 0;
  }

  .stake-input {
    outline: none;
    width: 100%;
    color: rgba(255, 255, 255, 0.5);
  }
}

.detail {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: flex;
  gap: 0 4px;
}

.win-detail {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  gap: 0 3px;
}

.delete-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.ml--26 {
  margin-left: 26px;
}

@media screen and (max-width: 1264px) {
  .stake-title {
    .title {
      width: 160px;
      padding: 0;
    }
  }
}

.suspended-background {
  background: rgba(25, 25, 25, 0.5);
}
</style>
