import { defineStore } from "pinia";

export const useBetStore = defineStore("betModal", {
  state: () => {
    return {
      betModal: false,
      chatModal: false,
      login: false,
      selectedProviders: {},
      section: "wallet",
      tickets: false,
      betId: [],
      accountTab: "loginInfo",
      account: false,
      bet: false,
      wallet: false,
      time: 60000,
      counting: true,
      walletTab: "balance",
      sport: "",
      errorData: null,
      timeValue: false,
      screenWidth: window.innerWidth,
      modules: import.meta.env.VITE_APP_MODULES?.split(","),
    };
  },

  actions: {
    handleShowBetModal(value) {
      const element = document.getElementsByTagName("body")[0];

      if (value === false || value) {
        this.betModal = false;
        element.style.overflow = "auto";
      } else {
        if (!this.betModal) {
          element.style.overflow = "hidden";
        } else {
          element.style.overflow = "auto";
        }
        this.betModal = !this.betModal;
      }
      // this.betModal = !this.betModal;
    },
    handleShowChatModal(value) {
      const element = document.getElementsByTagName("body")[0];

      if (value === false || value) {
        this.chatModal = false;
        element.style.overflow = "auto";
      } else {
        if (!this.chatModal) {
          element.style.overflow = "hidden";
        } else {
          element.style.overflow = "auto";
        }
        this.chatModal = !this.chatModal;
      }
      // this.betModal = !this.betModal;
    },
    setSection(sectionw) {
      this.section = sectionw;
    },

    setCounting(counting) {
      this.counting = counting;
    },
    setTime(time) {
      this.time = time;
    },
    setSport(sport) {
      this.sport = sport;
    },
    handleShowWallet(value) {
      if (value) {
        this.wallet = value;
      } else {
        this.wallet = !this.wallet;
      }
      this.account = false;
      this.bet = false;
    },
    handleShowMyBet(value) {
      if (value) {
        this.bet = value;
      } else {
        this.bet = !this.bet;
      }
      this.wallet = false;
      this.account = false;
    },
    handleShowLogin() {
      this.login = !this.login;
    },
    handleSelectedProvider(key, value) {
      this.selectedProviders[key] = value;
    },
    setEmptyProvider() {
      this.selectedProviders = {};
    },
    setAccountTab(value) {
      this.accountTab = value;
    },
    setWalletTab(value) {
      this.walletTab = value;
    },

    setTickets(value) {
      this.tickets = value;

      const element = document.getElementsByTagName("body")[0];

      if (value === false || value) {
        element.style.overflow = "auto";
      } else {
        if (!this.tickets) {
          element.style.overflow = "hidden";
        } else {
          element.style.overflow = "auto";
        }
      }
    },
    setBetId(value) {
      this.betId = value;
    },
    toggleSection(value) {
      if (this.section === value) {
        this.section = null;
        return;
      }

      this.section = value;
    },
    setErrorData(errorData) {
      this.errorData = errorData;
    },
    setTimeValue(timeValue) {
      this.timeValue = timeValue;
    },

    setScreenWidth(screenWidth) {
      this.screenWidth = screenWidth;
    },
  },

  getters: {
    getAccountTab: (state) => state.accountTab,
    getWalletTab: (state) => state.walletTab,
    getSection: (state) => state.section,
    getScreenWidth: (state) => state.screenWidth,
    getTickets: (state) => state.tickets,
    getBetId: (state) => state.betId,
    getModules: (state) => state.modules,
    getCounting: (state) => state.counting,
    getTime: (state) => state.time,
  },
});
