<template>
  <div class="table">
    <div class="label">Team cards</div>
    <div class="headings">
      <div class="flex-item">Barcelona</div>
      <div class="flex-item">Real Madrid</div>
    </div>

    <div class="item">
      <div class="flex-item">
        <div class="over">Over 3.5</div>
        <div>3.2</div>
      </div>
      <div class="flex-item-center">
        <div class="over">Over 3.5</div>
        <div>3.2</div>
      </div>
      <div class="flex-item-center">
        <div class="over">Over 3.5</div>
        <div>3.2</div>
      </div>
      <div class="flex-item-center">
        <div class="over">Over 3.5</div>
        <div>3.2</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 10px;
  .label {
    color: var(--White, #fff);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .headings {
    background: var(--be-small-card-bg);
    display: flex;
    justify-content: center;
    height: 39px;
    align-items: center;
    border-radius: 4px 4px 0 0;
    color: #fff;

    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    .flex-item {
      flex: 50%;
      display: flex;
      justify-content: center;
    }
  }

  .item {
    display: flex;
    justify-content: center;
    height: 39px;
    // align-items: center;
    border-radius: 4px 4px 0 0;

    .over {
      color: rgba(255, 255, 255, 0.5);
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .flex-item {
      flex: 33%;
      display: flex;
      padding-left: 10px;
      align-items: center;
      flex-direction: column;
      border-right: 1px solid var(--be-small-card-bg);
      border-left: 1px solid var(--be-small-card-bg);
      border-bottom: 1px solid var(--be-small-card-bg);
      color: #fff;
      justify-content: center;

      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #f09426;
    }

    .flex-item-center {
      flex: 33%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      border-right: 1px solid var(--be-small-card-bg);
      border-bottom: 1px solid var(--be-small-card-bg);
      color: #f09426;

      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
</style>
