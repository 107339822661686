<script setup>
import { computed } from "vue";

// import BetStatus from "./BetStatus";
import { ellipsis } from "@/app/utils/strings";
import { useI18n } from "@/app/composables/useI18n";
import { useUserService } from "@/user-account/composables/useUserService";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";

const props = defineProps({
  ticket: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();

const { activeWallet } = useUserService();

const ticketName = computed(() => {
  return props.ticket.wagers.length < 2 ? props.ticket.wagers[0].outcomeName : "Multiple bet";
});
</script>

<template>
  <div class="ticket-preview">
    <!-- <BetStatus :status="ticket.status" /> -->
    <div class="ticket-status">
      <div :class="ticket.status" />
    </div>
    <div class="ticket-amount">
      <BaseIcon :name="activeWallet?.currency?.icon" />

      <span>{{ ticket.stake }}</span>
      <!-- Number(ticket.stake).toFixed(ticket.wallet.currency.places) -->
    </div>
    <div class="ticket-type">
      {{ t(`bets.${ticket.type}`) }}
    </div>
    <div class="ticket-outcome-name">
      {{ ellipsis(ticketName, 30) }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ticket-preview {
  display: flex;
  align-items: center;
  font-size: 13px;
  max-width: 100%;
  padding: 2px 5px;
  min-height: 48px;
  outline: none;
  .tablet & {
    font-size: 12px;
  }
  .ticket-type {
    padding: 10px 0;
    opacity: 0.5;
    text-transform: capitalize;
    border-left: 1px solid #555555;
    border-right: 1px solid #555555;
    min-width: 100px;
    text-align: center;
    .tablet & {
      min-width: 65px;
    }
  }
  .ticket-amount {
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-basis: 130px;
    flex-shrink: 0;
    padding: 10px 0;
    margin-left: 10px;
    min-width: 80px;
    text-align: center;
    .tablet & {
      min-width: 55px;
    }
    img {
      height: 20px;
      width: 20px;
      // margin-bottom: -2px;
      margin: 0 10px;
    }
    span {
      margin-right: 10px;
    }
    // div {
    //   width: fit-content;
    //   padding: 0 10px;
    // }
  }
  .ticket-outcome-name {
    padding: 10px 5px;
    opacity: 0.5;
  }
}
.ticket-status {
  border-right: 1px solid #555555;
  min-width: 35px;

  div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 0.5rem;
    &.open {
      background: linear-gradient(311.99deg, #f09426 0%, #f5a83a 100%) !important;
    }
    &.won,
    &.half_won {
      background-color: #4caf50;
    }
    &.void {
      background-color: #4a4a4a;
    }
    &.lost,
    &.half_lost {
      background-color: #9e3535;
    }
  }
}
</style>
