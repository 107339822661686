<script setup>
import { ref, watch } from "vue";
import { useI18n } from "@/app/composables/useI18n";
import BaseButton from "@/app/components/ui/BaseButton.vue";
import BaseTextField from "@/app/components/ui/BaseTextField.vue";
import { rules as betLimitRules } from "@/user-account/utils/input-rules/bet-limit";

const props = defineProps({
  userBetLimits: {
    type: Object,
    default: () => ({}),
  },
  currency: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(["submit"]);

const { t } = useI18n();

const inputs = ref([
  {
    name: "daily",
    value: props.userBetLimits.activeBetLimit?.daily || "",
    type: "number",
    label: `${t("account.daily_bet_limit")} (${props?.currency?.ticker})`,
    placeholder: t("account.daily_bet_limit"),
    rules: betLimitRules,
    clearable: false,
    error: "",
  },
  {
    name: "weekly",
    value: props.userBetLimits.activeBetLimit?.daily || "",
    type: "number",
    label: `${t("account.weekly_bet_limit")} (${props?.currency?.ticker})`,
    placeholder: t("account.weekly_bet_limit"),
    rules: betLimitRules,
    clearable: false,
    error: "",
  },
  {
    name: "monthly",
    value: props.userBetLimits.activeBetLimit?.daily || "",
    type: "number",
    label: `${t("account.monthly_bet_limit")} (${props.currency.ticker})`,
    placeholder: t("account.monthly_bet_limit"),
    rules: betLimitRules,
    clearable: false,
    error: "",
  },
]);

watch(
  () => props.userBetLimits.activeBetLimit,
  (value) => {
    inputs.value[0].value = value?.daily;
    inputs.value[1].value = value?.weekly;
    inputs.value[2].value = value?.monthly;
  }
);
</script>

<template>
  <form @submit.prevent="emit('submit', inputs)">
    <div class="form-fields">
      <BaseTextField
        v-for="(input, i) in inputs"
        :key="i"
        :model-value="input.value"
        :rules="input.rules"
        :label="input.label"
        :placeholder="input.placeholder"
        :type="input.type"
        :clearable="input.clearable"
        :error="input.error"
        @update:model-value="input.value = $event"
        @error="input.error = $event"
      />
    </div>

    <BaseButton class="submit alight wide high" type="submit">
      {{ t("account.save") }}
    </BaseButton>
  </form>
</template>

<style lang="scss" scoped>
.form-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}
</style>
