import { t } from "@/app/composables/useI18n";

const uppercaseRegex = /[A-Z]/;
const lowercaseRegex = /[a-z]/;
const numberRegex = /[0-9]/;

export function minimum8Characters(password) {
  if (password && password.length >= 8) {
    return true;
  }

  return t("account.password_rule_min");
}

export function handleConfirmPassword(confirmPassword, password) {
  if (confirmPassword === password) {
    return true;
  }
  return t("account.confirm_password_rule");
}

export function maximum28Characters(password) {
  if (password && password.length <= 28) {
    return true;
  }
  return t("account.password_rule_max");
}

export function includeAtLeastOneUppercase(password) {
  if (uppercaseRegex.test(password)) {
    return true;
  }

  return t("account.password_rule_uppercase");
}

export function includeAtLeastOneLowercase(password) {
  if (lowercaseRegex.test(password)) {
    return true;
  }

  return t("account.password_rule_lowercase");
}

export function includeAtLeastOneNumber(password) {
  if (numberRegex.test(password)) {
    return true;
  }

  return t("account.password_rule_number");
}

export function containMaximumHalfOfUsername(username, password) {
  if (typeof username !== "string" && typeof password !== "string") {
    return false;
  }

  const regex = new RegExp(`[${username}]`);

  if (!regex.test(password)) {
    return true;
  }

  const usernameLength = username.length;
  const usernameChars = username.split("");

  const matches = [];

  usernameChars.forEach((char) => {
    if (!password.includes(char)) {
      return;
    }

    const findCharInMatches = matches.filter((value) => {
      return value === char;
    });

    if (findCharInMatches.length === 0) {
      matches.push(char);
    }
  });

  if (matches.length > Math.floor(usernameLength / 2)) {
    return t("account.password_rule_similar");
  }

  return true;
}

export const rules = [
  minimum8Characters,
  maximum28Characters,
  includeAtLeastOneUppercase,
  includeAtLeastOneLowercase,
  includeAtLeastOneNumber,
];
