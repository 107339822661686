<script setup>
import { onMounted, ref, watch } from "vue";
import { t } from "@/app/composables/useI18n";
import { api } from "@/services";
import BaseTextField from "@/app/components/ui/BaseTextField.vue";

const props = defineProps({
  currencyId: {
    type: Number,
    required: true,
  },
  providerId: {
    type: Number,
    required: true,
  },
});

const depositData = ref({});

async function cryptoDepositInit() {
  const result = await api.finance.cryptoDepositInit(props.providerId, props.currencyId);
  depositData.value = result.crypto;
}

watch(
  () => props.providerId,
  (value) => {
    cryptoDepositInit();
  },
);

watch(
  () => props.currencyId,
  (value) => {
    cryptoDepositInit();
  },
);

onMounted(() => {
  cryptoDepositInit();
});
</script>

<template>
  <div v-if="depositData?.cryptoAddress !== null">
    <BaseTextField
      :model-value="depositData.cryptoAddress"
      :label="t('finance.deposit_address')"
      type="text"
      readonly="true"
      icon-name="copy"
      class="crypto-address"
    />

    <img :src="depositData.qrCodeDataUri" class="qr-code" />
    <p class="deposit-intro">{{ t("finance.deposit_intro") }}</p>
  </div>
  <div>
    <span class="label">{{ t("finance.min_deposit") }}:</span>
    <span class="value">{{ depositData.minAmount }}</span>
  </div>
  <div>
    <span class="label">{{ t("finance.fee") }}:</span>
    <span class="value">{{ +depositData.fee * 100 }}%</span>
  </div>
</template>

<style lang="scss" scoped>
.label {
  margin-bottom: 7px;
}

.qr-code {
  float: left;
  margin: 0 10px 0 0;
}

.crypto-address {
  margin-bottom: 10px;
}

.deposit-intro {
  margin-top: 0px;
  margin-bottom: 14px;
}

span.label {
  font-size: 14px;
}

span.value {
  font-weight: normal !important;
  padding-left: 10px;
  font-size: 14px;
}
</style>
