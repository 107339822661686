<script setup>
import { ref, toRef, watch } from "vue";
import { useBetslip } from "../composables/useBetslip.js";
import EventStartTime from "./EventStartTime.vue";
import MarketSelection from "./MarketSelection.vue";
import { ellipsis } from "@/app/utils/strings";
import { router } from "@/app/router";
import { toast } from "@/app/utils/notification";
import { spaceStringToHyphenatedString } from "../utils/general.js";

const props = defineProps({
  match: {
    type: Object,
    required: true,
  },
  marketGroups: {
    type: Array,
    default: () => [],
  },
  type: {
    type: String,
  },
  leagueName: {
    type: String,
    required: true,
  },
  leagueId: {
    type: String,
    required: true,
  },
  leagueCountryId: {
    type: String,
    required: true,
  },
  sport: {
    type: Object,
    required: true,
  },
});

const mgs = toRef(props, "marketGroups");
const betslip = useBetslip();

const timerData = ref({});

function getSelection(marketGroup, index) {
  const market = Object.values(props.match.markets).find(
    (m) => m.id === marketGroup.markets[marketGroup.activeIndex].id
  );
  if (market?.odds) {
    return Object.values(market?.odds)[index];
  }
}

function onTeamClick() {
  router.push({
    name: "sport.event",
    params: {
      eventId: `${props.match.id}-${spaceStringToHyphenatedString(
        props.match.homeTeamName
      )}-${spaceStringToHyphenatedString(props.match.awayTeamName)}`,
    },
  });
}

function callToast() {
  toast.error("Max 20 selections per bet");
}

function toggleSelection(marketGroup, index) {
  const market = Object.values(props.match.markets).find(
    (m) => m.id === marketGroup.markets[marketGroup.activeIndex].id
  );

  const selection = Object.values(market?.odds)[index];
  selection.awayTeamName = props.match.awayTeamName;
  selection.homeTeamName = props.match.homeTeamName;
  selection.startDate = props.match.startsAt;
  betslip.toggleSelection(selection, props.match, callToast);
}

watch(
  () => props.match,
  (match) => {
    timerData.value = match.timer;
  },
  { immediate: true }
);
</script>

<template>
  <div class="featured-match" :data-event-id="props.match.id">
    <div class="desc">
      <EventStartTime
        class="time"
        :timer-data="timerData"
        :starts-at="match.startsAt"
        :live="false"
        :type="props.type"
      />
      <div class="teams-wrapper" @click="onTeamClick">
        <div class="teams">
          <div>
            {{ ellipsis(props.match.homeTeamName, 20) }}
          </div>
          <div>
            {{ ellipsis(props.match.awayTeamName, 20) }}
          </div>
        </div>
      </div>
    </div>

    <div class="market-groups">
      <div class="league-box">
        <div class="league-name">{{ props.leagueName }}</div>
      </div>
      <div
        v-for="(marketGroup, i) in mgs"
        :key="i"
        class="market-group match-odds"
        :data-qqqqqq="marketGroup.activeIndex"
        :class="{
          'three-way': marketGroup.markets[marketGroup.activeIndex].headers.length === 3,
        }"
      >
        <div v-for="index in marketGroup.markets[marketGroup.activeIndex].headers.length">
          <MarketSelection
            :key="index"
            :set="(selection = getSelection(marketGroup, index - 1))"
            v-if="getSelection(marketGroup, index - 1)?.isActive"
            :selection="selection"
            :data-selection-id="selection?.id"
            :data-event-id="selection?.eventId"
            :data-league-id="leagueId"
            :data-market-id="selection?.marketId"
            :data-category-id="leagueCountryId"
            :data-odds-id="selection?.id"
            :selected="betslip.containsSelection(selection?.id)"
            @click="toggleSelection(marketGroup, index - 1)"
          />

          <div class="market-selection" v-else>
            <img class="lock" src="/assets/images/lock.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/scss/market-groups.scss";

.market-selection {
  display: flex;
  flex-direction: column;
  background-color: var(--be-small-card-bg);
  color: rgba(255, 255, 255, 0.5);
  height: 45px;
  margin-right: 2px;
  font-size: 12px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.lock {
  width: 30px;
}

.featured-match {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  background-color: var(--be-big-card-bg);
  border-bottom: 2px solid var(--be-bg);
  font-size: 12px;
  box-sizing: border-box;
  .desc {
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;

    .teams {
      text-align: left;
      font-size: 12px;
      margin-top: 5px;
      .mobile & {
        font-size: 11px;
      }
      & > div {
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 2px 0;
      }

      @media screen and (max-width: 500px) {
        & > div {
          white-space: inherit;
          word-break: break-all;
        }
      }
    }
  }
}

.league-box {
  display: flex;
  width: 176px;
  justify-content: center;
  .league-name {
    width: 150px;
    text-align: center;
    margin-bottom: 6px;
    padding-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.match-score {
  display: flex;
  text-align: left;
  justify-content: space-between;
}

.match-odds {
  display: flex;
  justify-content: center;
}

.upcoming-date {
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.live-badge {
  background-color: #d0021b;
  border-radius: 3px;
  font-weight: 600;
  padding: 3px 3px 0 !important;
  max-width: 55px;
  min-width: 45px;
  font-size: 11px;
}

@media screen and (max-width: 1264px) {
  .teams,
  .live-badge {
    font-size: 11px;
  }
}

.teams-wrapper:hover {
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}

.overall-set-score {
  color: #f09426;
}

.theme--light.v-divider {
  border-color: rgba(255, 255, 255, 0.5);
}
</style>
