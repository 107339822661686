import { computed, ref } from "vue";

const navigations = ref({});

export function useNavigation() {
  function setNavigation(nav) {
    navigations.value = nav;
  }

  function findSportByName(query) {
    const sport = navigations.value?.sports || {};
    for (let id in sport) {
      if (sport[id].name == query) {
        return sport[id];
      }
    }
  }

  function findTournamentsByCountryId(sportSlug, id) {
    return findSportByName(sportSlug)?.categories[id]?.tournaments;
  }

  return {
    setNavigation,
    navigations,
    findSportByName,
    findTournamentsByCountryId,
  };
}
