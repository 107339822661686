import { type RouteRecordRaw } from "vue-router";
import VirtualGamesLayout from "@/virtual-games/layouts/VirtualGamesLayout.vue";
import VirtualGamesView from "@/virtual-games/views/VirtualGamesView.vue";
import VirtualGamesGameView from "@/virtual-games/views/VirtualGamesGameView.vue";

export const virtualGamesRoutes: RouteRecordRaw = {
  path: "/virtual-games",
  component: VirtualGamesLayout,
  children: [
    {
      path: "",
      name: "virtualGames",
      component: VirtualGamesView,
    },
    {
      path: "game/:code",
      name: "virtualGames.game",
      component: VirtualGamesGameView,
      props: true,
      meta: { requiresAuth: true },
    },
  ],
};
