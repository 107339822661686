<script setup>
import { router } from "@/app/router";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { useAuth } from "@/user-account/composables/useAuth";
import { useBetStore } from "../../../store";
const props = defineProps({
  game: {
    type: Object,
    default: null,
  },
});

const { isAuthenticated } = useAuth();
const store = useBetStore();

function onGameClick(game) {
  if (!isAuthenticated()) {
    store.handleShowLogin();
  } else {
    router.push({
      name: "virtualGames.game",
      params: { code: game.code },
    });
  }
}
</script>

<template>
  <div class="game" :data-game-id="game.id" @click="onGameClick(game)">
    <picture>
      <img
        :src="`/assets/images/games/${game.code}.jpg`"
        class="game-image"
        :alt="game.description"
      />
    </picture>
    <div class="game-hover-wrapper">
      <div class="game-title">
        {{ props.game.description }}
      </div>
      <div class="game-provider">DS Virtual games</div>
      <BaseIcon name="play" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.game {
  display: flex;
  position: relative;
  width: 621px;
  height: auto;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  margin-bottom: 10px;

  .tablet & {
    width: 120px;
  }

  .game-image {
    max-width: 100%;
    border-radius: 5px;
  }

  .game-hover-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    opacity: 0;
    transition: all 0.2s ease-in-out;

    .game-title {
      padding: 0 5px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
      font-size: 12px;
      font-weight: bold;
    }

    .game-provider {
      font-family: Source Sans Pro, sans-serif;
      font-size: 12px;
      color: hsla(0, 0%, 100%, 0.64);
    }

    .play-button {
      display: block;
      padding-top: 5px;
      text-align: center;
      width: 40px;
    }

    &:hover {
      opacity: 1;
    }
  }
}
</style>
