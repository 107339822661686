<script setup lang="ts">
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { useConfig } from "@/app/composables/useConfig";
import { ref } from "vue";
import { router } from "@/app/router";
import { useSportsdata } from "@/sportsbook/composables/useSportsdata.js";
import { useAuth } from "@/user-account/composables/useAuth";
import { useBetStore } from "../../../../store";
import { useI18n } from "@/app/composables/useI18n";

const props = withDefaults(
  defineProps<{
    menuItems: SidebarMenuItem[];
    showSeparator?: boolean;
    title?: string;
    gameHeading: boolean;
  }>(),
  {
    menuItems: () => [],
    showSeparator: true,
  }
);
const store = useBetStore();
const { fetchSportsEvents } = useSportsdata();
const sports = ref([]);

const { isDrawerOpen, appDivision } = useConfig();

const { isAuthenticated } = useAuth();
const { t, locale } = useI18n();

async function clicked(menuItem: SidebarMenuItem) {
  if (menuItem?.click) {
    menuItem?.handleClick();
  } else if (props.title == "Virtuals" && !isAuthenticated()) {
    store.handleShowLogin();
  } else {
    router.push(menuItem.route);
  }

  // if (menuItem?.route?.name != "virtualGames.game")
  //   sports.value = await fetchSportsEvents(menuItem.id, {
  //     timeFrame: "upcoming",
  //     type: "popular",
  //   });

  // isDrawerOpen.value = false;
}

function isActive(menuItem: SidebarMenuItem) {
  // if (this.$route.name === "sports.upcoming" && menuItem.route === "sports") {
  //   return true;
  // }
  // if (this.$route.name === "casino" && menuItem.route === "casino") {
  //   return true;
  // }
  // return (
  //   this.$route.name === menuItem.route.name
  //         && this.$route.params.sportId === menuItem.route.params.sportId
  // );
}
</script>

<template>
  <div :class="`separator ${props.showSeparator ? '' : 'none'}`">
    {{ props.title }}
  </div>

  <div class="sidebar-block">
    <div
      :class="showSeparator ? 'game-heading' : 'game-heading show-seperator-game'"
      v-if="gameHeading"
    >
      <img class="game-image" :src="`/assets/images/${appDivision}.svg`" alt="" />
      <div v-if="showSeparator">
        {{ t(`general.${appDivision}`) }}
        {{ t("general.games") }}
      </div>
    </div>
    <div
      v-for="menuItem in props.menuItems"
      :key="menuItem?.id"
      :class="
        appDivision === menuItem?.route?.name
          ? 'menu-item--active'
          : !showSeparator
          ? 'show-seperator  menu-item'
          : 'menu-item'
      "
      @click="clicked(menuItem)"
    >
      <BaseIcon color="white" :name="menuItem?.icon" size="16" class="icon" />

      <span class="title" v-if="showSeparator">{{ menuItem?.title }}</span>
      <div class="language-icons" v-if="menuItem?.icon == 'globe' && showSeparator">
        <div class="language-title">
          <div>:</div>
          <div>{{ locale.name }}</div>
        </div>
        <img src="/assets/images/right-icon.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.separator {
  // margin-top: 10px;
  // margin-bottom: 4px;
  margin-top: 10px;
  display: flex;
  // opacity: 0;
  transition: all 300ms ease-in-out;
  justify-content: center;
  color: #999;
  text-transform: uppercase;
  // font-size: 0.6em;
  // margin-left: 15px;
  font-size: 0px;
  letter-spacing: 2px;

  // &::before,
  // &::after {
  //   content: "";
  //   display: inline-block;
  //   width: 30px;
  //   border-bottom: 2px solid #555;
  //   margin: 0 10px 4px;
  // }
  .tablet & {
    opacity: 1;
  }
}
.language-icons {
  display: flex;
  width: 52%;
  justify-content: space-between;
}

.language-title {
  display: flex;
  gap: 5px;
  font-family: Rubik;
  font-size: 16px;
}

.game-heading {
  text-align: center;
  display: flex;
  padding: 8px 0 8px 16px;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #1a1b38;
  font-family: Rubik-med;
  font-size: 16px;
  .game-image {
    width: 16px;
    height: 16px;
  }
}

.sidebar-block {
  background: var(--be-small-card-bg);
  border-radius: 8px;
  margin: 0px 10px;
}

.none {
  opacity: 0;
}

.menu-item {
  padding: 8px 0 8px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  // opacity: 0.6;
  opacity: 1;

  &--active {
    opacity: 1;
  }

  .icon {
    margin-right: 10px;
    width: 24px;
  }
  .title {
    font-size: 16px;
    font-family: Rubik;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
  }
  &.grayed-out {
    opacity: 0.5;
    cursor: initial;
  }
  // &:hover {
  //   opacity: 0.4;
  // }
}

.show-seperator {
  padding-left: 9px;
}

.show-seperator-game {
  padding-left: 9px;
  border-bottom: 1px solid #1a1b38;
}
</style>
