<script setup>
import { computed } from "vue";
import { useBetslip } from "@/sportsbook/composables/useBetslip.js";
import { toast } from "@/app/utils/notification";

const props = defineProps({
  marketType: {
    type: Object,
    required: true,
  },
  competition: {
    type: Object,
    required: true,
  },
  odds: {
    type: Array,
    required: true,
  },
});

const betSlip = useBetslip();

const filteredOds = computed(() => {
  return props.odds.filter((odd) => odd.marketType === props.marketType.betGeniusId);
});

const columns = computed(() => {
  const columns = [];
  filteredOds.value.forEach((odd) => {
    if (!columns.includes(odd.outcomeName)) {
      columns.push(odd.outcomeName);
    }
  });
  return columns.sort();
});

const rows = computed(() => {
  const rows = [];
  filteredOds.value.forEach((odd) => {
    if (!rows.includes(odd.specifiers)) {
      rows.push(odd.specifiers);
    }
  });
  return rows.sort();
});

function formatPrice(price) {
  price = Number.parseFloat(price);
  return price.toFixed(2);
}

function getOdd(row, column) {
  return filteredOds.value.find((odd) => odd.specifiers === row && odd.outcomeName === column);
}

function onOddClick(row, column) {
  const odd = filteredOds.value.find((odd) => odd.specifiers === row && odd.outcomeName === column);

  if (!odd) {
    toast.error("Could not find odd");
    return;
  }

  if (betSlip.selections.value.length >= 20) {
    toast.error("Max 20 selections per bet");
    return;
  }

  if (odd.is_active === false) {
    toast.error("This selection is not available");
    return;
  }

  const selection = {
    eventName: `${props.competition.participants[0].name} v ${props.competition.participants[1].name}`,
    marketName: props.marketType.name,
    id: odd.id,
    marketId: odd.marketType,
    eventId: odd.competitionId,
    sportId: odd.sportId,
    specifiers: odd.specifiers,
    odds: odd.price,
    name: odd.outcomeName,
    header: odd.specifiers ? `${odd.outcomeName} ${odd.specifiers}` : odd.outcomeName,
    handicap: null,
    isSuspended: false,
    is_live: odd.isLive,
    isActive: odd.is_active,
    order: null,
    specifier: "",
    awayTeamName: props.competition.participants[1].name,
    homeTeamName: props.competition.participants[0].name,
    startDate: props.competition.startDate,
  };

  betSlip.toggleSelection2(selection);
}

function isSelected(row, column) {
  const odd = filteredOds.value.find((odd) => odd.specifiers === row && odd.outcomeName === column);
  return betSlip.selections.value.some((selection) => selection.id === odd.id);
}

function getPriceFor(row, column) {
  const odd = filteredOds.value.find((odd) => odd.specifiers === row && odd.outcomeName === column);
  return odd ? formatPrice(odd.price) : "";
}
</script>

<template>
  <div class="result">
    <div>{{ props.marketType.name }}</div>
    <div class="header">
      <div class="header-item placeholder" />
      <div v-for="(column, index) in columns" :key="index" class="header-item">
        {{ column }}
      </div>
    </div>

    <div v-for="(row, index) in rows" :key="index" class="row">
      <div class="row-item specifier">
        {{ row }}
      </div>
      <div
        v-for="(column, i) in columns"
        :key="i"
        class="row-item odd"
        :class="{ selected: isSelected(row, column) }"
        @click="onOddClick(row, column)"
      >
        <div class="lock" v-if="!getOdd(row, column).is_active">
          <img width="25px" height="25px" src="/assets/images/lock.png" alt="">
        </div>
        <span v-else>
          {{ getPriceFor(row, column) }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.result {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  font-size: 12px;
  padding: 10px;
  margin-bottom: 1rem;
  .header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;
    background-color: var(--be-small-card-bg);
    padding: 1rem;

    .header-item {
      color: #fff;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      flex: 1;
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 1rem;

    .row-item {
      color: #f09426;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      padding: 1rem;
      border-radius: 8px;
      flex: 1;
      cursor: pointer;

      &.specifier {
        text-align: left;
        color: #fff;
      }

      &.selected {
        background: #f09426;
        color: var(--be-big-card-bg);
      }

      .lock {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}
</style>
