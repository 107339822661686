<script setup>
// const emit = defineEmits(["change"]);
// const props = defineProps({
//   value: {
//     type: Boolean,
//     default: false,
//   },
// });

const modelValue = defineModel(false);
const uuid = Math.random(10000);
// function change(event) {
//   console.log(event.target.checked);
//   emit("change", event.target.checked);
// }
</script>

<template>
  <div class="toggle">
    <input :id="uuid" v-model="modelValue" type="checkbox">
    <label :for="uuid">Toggle</label>
  </div>
</template>

<style lang="scss" scoped>
.toggle {
  display: inline-flex
}
input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  &:checked {
    + {
      label {
        // background: #bada55;
        background: rgba(0, 0, 0, 0.38);
        &:after {
          left: calc(100% - 1px);
          transform: translateX(-100%);
        }
      }
    }
  }
}
label {
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 18px;
  // background: grey;
  background: rgba(0, 0, 0, 0.38);
  display: block;
  border-radius: 10px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: -1px;
    left: 1px;
    width: 20px;
    height: 20px;
    // background: #fff;
    background: linear-gradient(90deg, #0686f3 0%, #5a45ed 100%);
    border-radius: 20px;
    transition: 0.3s;
  }
  &:active {
    &:after {
      width: 34px;
    }
  }
}
</style>
