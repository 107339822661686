<script setup>
import { computed, onMounted, ref } from "vue";
import { calculateTotalMatchLength, capitalizeHyphenatedString } from "../utils/general";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { useI18n } from "@/app/composables/useI18n";
import { router, useRoute } from "@/app/router";
import { useNavigation } from "@/app/composables/useNavigations";
import { useSportsdata } from "@/sportsbook/composables/useSportsdata";
import BaseDropdown from "@/app/components/ui/BaseDropdown.vue";

const props = defineProps({
  activeSport: {
    type: Object,
    required: true,
  },
});
const { findSportByName } = useNavigation();
const { sports, fetchSportsEvents } = useSportsdata();

const country = ref("");
const countryId = ref("");
const sportOptions = ref(null);

const { t } = useI18n();
const route = useRoute();

const breadcrumbs = computed(() => {
  if (country.value && countryId.value && route.name === "country.leagues") {
    return [
      {
        text: "A-Z",
        route: {
          name: "sport.countries",
        },
      },
      {
        text: country.value,
      },
    ];
  } else if (route.name === "sport.competition") {
    return [
      {
        text: "competition",
      },
    ];
  } else if (route.name === "sport.league") {
    return [
      {
        text: findSportByName(capitalizeHyphenatedString(route.params.sportSlug))?.categories[
          route.params.countrySlug
        ]?.name,
      },
    ];
  } else if (route.name === "sport.tournament") {
    return [
      {
        text: `${
          findSportByName(capitalizeHyphenatedString(route.params.sportSlug))?.categories[
            route.params.countrySlug
          ]?.name
        }  >`,
        subtext: findSportByName(capitalizeHyphenatedString(route.params.sportSlug))?.categories[
          route.params.countrySlug
        ]?.tournaments[route.params?.tournamentSlug]?.name,
      },
    ];
  } else if (route.name === "sport.countries") {
    return [
      {
        text: "today's matches",
      },
    ];
  } else if (route.name === "today.events") {
    return [
      {
        text: "todays_matches",
      },
    ];
  } else if (route.name === "upcoming.events") {
    return [
      {
        text: t("sportsbook.popular"),
      },
    ];
  } else if (route.name === "country.leagues") {
    return [
      {
        text: "A-Z",
        route: {
          name: "sport.countries",
          params: {
            sportSlug: props.activeSport.slug,
          },
        },
      },
      {
        text: route.params.country,
      },
    ];
  } else if (route.name === "event.view") {
    return [
      {
        text: "A-Z",
        route: {
          name: "sport.countries",
          params: {
            sportSlug: props.activeSport.slug,
          },
        },
      },
      {
        text: route.params.countryName,
        route: {
          name: "country.leagues",
          params: {
            sportSlug: props.activeSport.slug,
            countryId: route.params.countryId,
            country: route.params.countryName,
          },
        },
      },
      {
        text: route.params.league,
      },
      {
        text: route.params.event,
      },
    ];
  } else if (route.name === "sport.categories") {
    return [
      {
        text: "Categories",
      },
    ];
  } else if (route.name === "sport.categories-events") {
    return [
      {
        text: "Categories",
        route: {
          name: "sport.categories",
          params: {
            sportSlug: props.activeSport.slug,
          },
        },
      },
      {
        text: route.params.categoryName,
      },
    ];
  }

  return [];
});

const views = computed(() => {
  const routeName = route.name;

  if (routeName === "event.view" || !props.activeSport.includeAzList) {
    return [];
  }

  if (
    routeName === "today.events" ||
    routeName === "sport.categories" ||
    routeName === "sport.countries" ||
    routeName === "sport.categories-events" ||
    routeName === "country.leagues"
  ) {
    return [
      {
        title: t("sportsbook.competitions"),
        route: { name: "sport.competition" },
      },
      {
        title: props.activeSport.showAllMatches
          ? t("sportsbook.popular")
          : t("sportsbook.today_events"),
        route: props.activeSport.showAllMatches
          ? { name: "upcoming.events" }
          : { name: "today.events" },
      },
      {
        title: props.activeSport.hasLeaguesAsCategories
          ? t("sportsbook.competitions")
          : t("sportsbook.today_events"),
        route: props.activeSport.hasLeaguesAsCategories
          ? { name: "sport.categories" }
          : { name: "sport.countries" },
      },
    ];
  }

  return [
    {
      title: t("sportsbook.competitions"),
      route: { name: "sport.competition" },
    },
    {
      title: t("sportsbook.popular"),
      route: { name: "upcoming.events" },
    },
    {
      title: t("sportsbook.today_events"),
      route: { name: "sport.countries" },
    },
  ];
});

function handleCountryClick() {
  if (route.name === "sport.tournament") {
    router.push({
      name: "sport.league",
      params: {
        countrySlug: route.params.countrySlug,
      },
    });
  }
}

function handleSportClick(sport) {
  router.push({
    name: !sport.showAllMatches ? "sports.today" : "sport.competition",
    params: {
      sportSlug: sport.slug,
    },
  });
  sportOptions.value.close();
}

const tournaments = ref([]);
const fetchSportsLive = async () => {
  let fetchSports;
  fetchSports = await fetchSportsEvents({
    timeFrame: "today",
    type: "live",
  });

  tournaments.value = fetchSports.flatMap((item) => item.tournaments);
};

onMounted(() => {
  fetchSportsLive();
});

function viewAll() {
  router.push({
    name: "livebetting",
  });
}
</script>

<template>
  <div class="page-selector">
    <div class="subheader">
      <div class="subheader-left">
        <BaseIcon v-if="breadcrumbs.length > 1" name="chevron-left" @click="$router.back()" />
        <BaseIcon :name="activeSport.icon" />
        <router-link
          class="subheader-title"
          :to="{
            name: 'sport.competition',
            params: {
              sportSlug: activeSport.slug,
            },
          }"
        >
          {{ t(`sports.${activeSport.slug}`) }}
        </router-link>
        <div class="drop-down">
          <BaseDropdown ref="sportOptions" :show-down-icon="false">
            <template #label>
              <div class="dropdown-circle">
                <img class="dropdown-icon" src="/assets/images/dropdown.svg" alt="" />
              </div>
            </template>
            <template #panel>
              <div class="dropdown-options">
                <div
                  :class="`dropdown-option ${
                    t(`sports.${sport.slug}`) == t(`sports.${activeSport.slug}`)
                      ? 'option-selected'
                      : ''
                  }`"
                  v-for="(sport, index) in sports"
                  @click="() => handleSportClick(sport)"
                >
                  {{ t(`sports.${sport.slug}`) }}
                </div>
              </div>
            </template>
          </BaseDropdown>
        </div>
      </div>

      <div class="view-live" @click="viewAll">
        <img src="/assets/images/live/video-square.svg" alt="" />
        <!-- <div>View Live Games ({{ tournaments ? calculateTotalMatchLength(tournaments) : "" }})</div> -->
      </div>
    </div>
    <div class="views">
      <router-link
        v-for="(view, i) in views"
        :key="i"
        :to="view.route"
        class="view"
        :style="{
          width: `${100 / views.length}%`,
          color:
            route.path.includes('competition') && view.title == t('sportsbook.competitions')
              ? 'var(--be-primary-btn-label) !important'
              : '',

          background:
            route.path.includes('competition') && view.title == t('sportsbook.competitions')
              ? 'var(--be-primary-btn)'
              : '',
        }"
      >
        {{ t(view.title) }}
      </router-link>
    </div>
  </div>
  <div
    class="sub-breadcrumb"
    v-if="route.name === 'sport.league' || route.name === 'sport.tournament'"
  >
    <img
      v-if="route.name === 'sport.league' || route.name === 'sport.tournament'"
      src="/assets/images/left-icon.png"
      class="left-icon"
      alt=""
      @click="$router.back()"
    />
    <div>
      <span v-for="(breadcrumb, i) in breadcrumbs" :key="i" class="breadcrumb">
        Competitions
        <span class="slash"> ></span>
        <router-link v-if="breadcrumb.route" :to="breadcrumb.route">
          {{ t(breadcrumb.text) }}
        </router-link>
        <span
          v-else
          :class="`ml-1 ${route.name === 'sport.tournament' ? 'bread-text' : ''}`"
          @click="handleCountryClick"
        >
          {{ t(breadcrumb.text) }}
        </span>
        <span v-if="breadcrumb?.subtext" class="bread-subtext">
          {{ t(breadcrumb.subtext) }}
        </span>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-selector {
  background-color: #1a1b38;
  border-radius: 2px;
}

.view-live {
  display: flex;
  align-items: center;
  background: #ff0000;
  color: white;
  border-radius: 4px 4px 0px 0px;
  height: 30px;
  text-align: center;
  padding: 0 10px;
  cursor: pointer;
  gap: 0 3px;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width: 500px) {
  .view-live {
    font-size: 10px;
  }
}

:deep(.dropdown-panel) {
  width: 240px !important;
  left: -10px;

  &:after {
    display: none;
  }
}

.drop-down {
  position: relative;
  margin-left: 8px;
  display: flex;
  align-items: center;

  .dropdown-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: white;
    position: relative;
    cursor: pointer;
  }

  @media screen and (max-width: 500px) {
    .dropdown-circle {
      width: 18px;
      height: 18px;
    }
  }

  .dropdown-icon {
    width: 14px;
  }

  .dropdown-options {
    position: absolute;
    width: 200px;
    border-radius: 8px;
    background: var(--be-small-card-bg);
    padding: 8px;
    font-size: 12px;
    left: -8px;
    margin-top: -8px;

    .dropdown-option {
      padding: 10px;
      cursor: pointer;
      text-transform: capitalize;
      &:hover {
        background: var(--be-bg);
      }
    }
    .option-selected {
      background: var(--be-bg);
    }
  }

  .dropdown-options:before {
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    content: "";
    display: block;
    z-index: 1;
  }
  .dropdown-options:after {
    position: absolute;
    top: -8px;
    left: 21px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid var(--be-bg);
    content: "";
    display: block;
    z-index: 2;
    transition: all 200ms linear;
  }
}

.sub-breadcrumb {
  display: flex;
  align-items: center;
  margin: 15px 0 0 10px;
  font-size: 12px;
  .breadcrumb {
    // display: flex;
    margin-left: 4px;
  }

  .bread-subtext {
    margin-left: 4px;
  }
}

.left-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 6px;
}

.subheader {
  padding: 15px 10px;
  width: 100%;
  background-color: var(--be-big-card-bg);
  border-radius: 2px 2px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .sport-icon {
    color: white;
    margin-right: 7px;
  }

  .subheader-left {
    display: flex;
  }
  .subheader-title {
    text-transform: capitalize;
    color: var(--be-primary-btn);
    margin: 0 6px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 500px) {
    .subheader-title {
      font-size: 13px;
      display: flex;
      align-items: center;
    }
  }
  .breadcrumb {
    display: flex;
    width: 100%;
    overflow: hidden;
    .slash {
      margin: 0 1px;
    }
    div {
      margin-left: 2px;
      text-transform: capitalize;
      white-space: nowrap;
      a {
        text-decoration: none;
        color: #ffffff;
        text-transform: capitalize !important;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .bread-text {
    cursor: pointer;
  }
  .bread-subtext {
    width: 98%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.views {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin-top: 0;

  .view {
    text-align: center;
    padding: 10px 20px;
    background-color: var(--be-small-card-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 300;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.5) !important;

    &:hover,
    &.router-link-exact-active {
      background-color: var(--be-primary-btn);
      // box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px, rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
      //   rgba(0, 0, 0, 0.15) 0px -3px 0px inset, rgb(180, 135, 1) 0px 0px 12px inset;
      color: var(--be-primary-btn-label) !important;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 500px) {
    .view {
      font-size: 12px;
    }
  }
}
</style>
