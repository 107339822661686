<script>
import SimplePage from "./SimplePage.vue";
import { toast } from "@/app/utils/notification";

export default {
  name: "ComingSoonPage",
  components: { SimplePage },

  data: () => ({
    email: "",
  }),

  methods: {
    sendEmail() {
      if (this.email === "demo") {
        window.location.href = "/prelaunch.html";
        return;
      }
      if (this.email !== "") {
        // alert(email.value);
        toast.info("Your email has been received. Thank you!");
        fetch(`https://api.betevet.com/subscribe.php?email=${this.email}`, {
          method: "get",
          headers: {
            // 'content-type': 'application/json'
          },
        })
          .then((res) => {
            // a non-200 response code
            if (!res.ok) {
              // create error instance with HTTP status text
              const error = new Error(res.statusText);
              // error.json = res.json();
              throw error;
            }

            return res.json();
          })

          .then(() => {
            // set the response data
            console.log(this.email);
          });
      }
      else {
        this.$toast.n("Please, enter your email address");
      }
    },
  },
};
</script>

<template>
  <SimplePage
    title="Betevet.com coming soon!"
    body="Leading Social Crypto Sportsbook & Casino"
    suffix="Leave your email and we'll let you know once we launch!"
  >
    <div class="form">
      <input v-model="email" type="email" placeholder="Your email...">
      <button @click="sendEmail">
        Notify me
      </button>
    </div>
  </SimplePage>
</template>

<style lang="scss" scoped>
.form {
  display: flex;
  margin-top: 30px;
  input {
    margin-right: 20px;
    border: 1px solid white;
  }
  button {
    background-color: transparent;
    font-size: 0.9em;
    color: #888;
    border: 1px solid #888;
    padding: 2px 15px;
    height: 2.2em;
    cursor: pointer;
    transition: all 0.5s;
    /* opacity: 0.5; */
  }

  button:hover {
    color: var(--be-primary-text-color);
    border-color: var(--be-primary-text-color);
    /* opacity: 0.1; */
  }
}
</style>
