import { jsonRpc } from "@/services/json-rpc/jsonrpc";

const finance = {
  async userWallets(params) {
    return await jsonRpc({
      method: "finance.wallets:list",
      params,
    });
  },

  async lastSelectedId(params) {
    return await jsonRpc({
      method: "users.wallet:updateLastSelectedWalletId",
      params,
    });
  },

  async allowedCurrencies(type) {
    return await jsonRpc({
      method: "finance.providers:currencies",
      params: {
        type,
      },
    });
  },

  async providers(currencyId, type) {
    return await jsonRpc({
      method: "finance.providers:list",
      params: {
        currencyId,
        type,
      },
    });
  },

  async depositInit(providerId, currencyId, amount) {
    return await jsonRpc({
      method: "finance.providers:deposit-init",
      params: {
        providerId,
        currencyId,
        amount,
      },
    });
  },

  async cryptoDepositInit(providerId, currencyId) {
    return await jsonRpc({
      method: "finance.providers:crypto-deposit-init",
      params: {
        providerId,
        currencyId,
      },
    });
  },

  async withdrawalFormData(params) {
    return await jsonRpc({
      method: "finance.providers:withdrawal-form-data",
      params,
    });
  },

  async withdraw(params) {
    return await jsonRpc({
      method: "finance.providers:withdrawal",
      params,
    });
  },

  async userTransactions(params) {
    return await jsonRpc({
      method: "finance.transactions:list",
      params,
    });
  },
};

export default finance;
