import { ref } from "vue";

const freeSpins = ref([]);

export function useFreeSpins() {
  async function setFreeSpins(_freeSpins) {
    freeSpins.value = _freeSpins;
  }

  return {
    freeSpins,
    setFreeSpins,
  };
}
