<script setup>
import { computed, onMounted, ref } from "vue";
import { api } from "@/services";
import { addOdds, useLiveData } from "@/sportsbook/composables/useLiveData";

import CategoryMenu from "@/sportsbook/components/CategoryMenu.vue";

import TemplateA from "@/events/components/TemplateA.vue";
import TemplateE from "@/events/components/TemplateE.vue";
import TemplateF from "@/events/components/TemplateF.vue";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  eventId: {
    type: String,
    required: true,
  },
});

const { odds } = useLiveData();

const competitionOdds = computed(() =>
  odds.value.filter((odd) => odd.competitionId === Number.parseInt(props.eventId))
);

const loading = ref(true);
const competition = ref(null);
const categories = ref([]);
const selectedCategory = ref(null);

const templates = { TemplateA, TemplateE, TemplateF };

const marketTypes = ref([]);

async function loadCompetition(eventId) {
  try {
    const result = await api.sportsdata.fetchCompetition({ competitionId: eventId });

    addOdds(result.odds);

    competition.value = result.competition;

    marketTypes.value = result.marketTypes;
    categories.value = result.categories;
    selectedCategory.value = categories.value[0] ?? null;

    loading.value = false;
  } catch (error) {
    loading.value = false;
  }
}

const filteredMarketTypes = computed(() => {
  const mt = marketTypes.value
    .filter((mt) => mt.status)
    .filter((mt) => mt.categoryIds.includes(selectedCategory.value?.id));
  return mt.sort((a, b) => a.weight - b.weight);
});

onMounted(() => {
  const scrollContainer = document.querySelector(`.brand-${import.meta.env.VITE_APP_BRAND}`);
  if (scrollContainer) {
    scrollContainer.scrollTo(0, 0);
  }

  loadCompetition(props.eventId);
});
</script>

<template>
  <h4>
    {{ competition?.participants[0]?.name }}{{ competition ? "   v  " : "" }}
    {{ competition?.participants[1]?.name }}
  </h4>

  <CategoryMenu
    :categories="categories"
    :active="selectedCategory"
    @category-selected="selectedCategory = $event"
  />

  <div v-if="!loading && !filteredMarketTypes.length" class="no-market-types">
    No market types available for this category
  </div>

  <template v-for="marketType in filteredMarketTypes" v-else :key="marketType.id">
    <component
      :is="templates[marketType.templateName]"
      :market-type="marketType"
      :odds="competitionOdds"
      :competition="competition"
    />
  </template>
</template>

<style scoped>
h4 {
  margin: 10px;
}

.no-market-types {
  margin: 10px;
  text-align: center;
}
</style>
