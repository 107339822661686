<script>
import Timer from "easytimer";

// import { useI18n } from "@/app/composables/useI18n";

// const { t } = useI18n();

export default {
  name: "EventStartTime",

  props: {
    sportId: {
      type: Number,
    },
    startsAt: {
      required: true,
      type: String,
    },

    live: {
      required: true,
      type: Boolean,
    },

    type: {
      type: String,
    },

    timerData: {
      type: Object,
      default: () => ({
        set: 0,
        game: 0,
        passedMinutes: 0,
        passedSeconds: 0,
        isPlaying: false,
        currentTime: 0,
        countdown: false,
        quarter: 0,
      }),
    },
  },

  data: () => ({
    timer: null,
    time: null,
  }),

  computed: {
    Timer() {
      if (this.time) {
        return this.time;
      }

      if (this.timerData.passedMinutes > 0) {
        return `${this.timerData.passedMinutes}’`;
      }

      if (this.timerData.passedMinutes === 0) {
        return "00:00";
      }

      return t("sportsbook.live");
    },
    eventTime() {
      return new Date(this.startsAt);
    },
    isToday() {
      return this.eventTime.toDateString() === new Date().toDateString();
      // return this.$moment(date).isSame(Date.now(), "day");
    },
    eventTimeDay() {
      const day = this.eventTime.getDate().toString().padStart(2, "0");
      const month = (this.eventTime.getMonth() + 1).toString().padStart(2, "0");
      return `${day}/${month}`;
    },
    eventTimeTime() {
      const hours = this.eventTime.getHours().toString().padStart(2, "0");
      const minutes = this.eventTime.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    },
    timerParams() {
      const seconds = this.timerData.passedMinutes * 60 + this.timerData.passedSeconds;

      return {
        countdown: this.timerData.countdown,
        startValues: { seconds },
      };
    },
  },

  watch: {
    timerParams(params) {
      if (!this.timer) {
        this.startTimer(params);
      }

      if (!this.timerData.isPlaying && this.timer.isRunning()) {
        this.pauseTimer();
      } else if (this.timerData.isPlaying && !this.timer.isRunning()) {
        this.restartTimer(params);
      }
    },
  },

  beforeUnmount() {
    if (this.timer) {
      this.timer.removeEventListener("secondsUpdated", this.handler);
    }

    this.timer = null;
  },

  methods: {
    startTimer(params) {
      if (!this.timer) {
        this.timer = new Timer();
      }

      this.timer.start(params);
      this.timer.addEventListener("secondsUpdated", this.handler);
    },

    handler() {
      this.time = this.timer.getTimeValues().toString(["minutes", "seconds"]);
    },

    stopTimer() {
      if (this.timer) {
        this.timer.stop();
        this.timer.removeEventListener("secondsUpdated", this.handler);
      }
    },

    pauseTimer() {
      if (this.timer) {
        this.timer.pause();
      }
    },

    restartTimer(params) {
      this.stopTimer();
      this.startTimer(params);
    },
  },
};
</script>

<template>
  <div class="match-time">
    <div v-if="live" class="live-badge">
      <span v-if="timerData.set && timerData.game">
        Set {{ timerData.set }}, Game {{ timerData.game }}
      </span>
      <span v-else-if="timerData.set">Set {{ timerData.set }}</span>
      <span v-else-if="timerData.quarter">Q{{ timerData.quarter }} </span>
      <span v-else-if="!timer && sportId === 1">00.00</span>
      <template v-if="timer && !timerData.set & (sportId === 1)">
        {{ Timer }}
      </template>
    </div>
    <div v-else-if="isToday" :class="`upcoming-date ${type == 'today' ? 'dark-red' : ''}`">
      {{ type == "today" ? "LIVE" : eventTimeTime }}
      <!-- {{ startsAt | moment("HH:mm") }} -->
    </div>
    <div v-else class="upcoming-date">
      {{ eventTimeDay }} @
      {{ eventTimeTime }}
      <!-- {{ startsAt | moment("DD/MM") }} @ -->
      <!-- {{ startsAt | moment("HH:mm") }} -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.live-badge {
  background-color: #d0021b;
  border-radius: 3px;
  font-weight: 600;
  padding: 0 3px;
  max-width: 60px;
  min-width: 45px;
  font-size: 11px;
  text-align: center;
}

.dark-red {
  color: #d0021b;
}
</style>
