<script setup>
import { ref } from "vue";
import { api } from "@/services";
import { resetErrors, setErrors, validate } from "@/user-account/utils/input-rules/validation";
import BetLimitsForm from "@/user-account/components/limits/BetLimitsForm.vue";
import { toast } from "@/app/utils/notification";
import { useI18n } from "@/app/composables/useI18n";
import { useCurrencies } from "@/app/composables/useCurrencies.ts";

const { t } = useI18n();
const { getDefaultCurrency } = useCurrencies();
const defaultCurrency = getDefaultCurrency();
const userBetLimits = ref({
  activeBetLimit: {
    daily: null,
    weekly: null,
    monthly: null,
  },
});

async function loadData() {
  const betLimits = await api.betLimits.user();

  userBetLimits.value.activeBetLimit = betLimits?.activeBetLimit;
}

async function onBetLimitsSubmit(form) {
  if (!validate(form)) {
    return;
  }

  try {
    const result = await api.betLimits.save({
      daily: form[0].value !== "" ? form[0].value : null,
      weekly: form[1].value !== "" ? form[1].value : null,
      monthly: form[2].value !== "" ? form[2].value : null,
    });

    resetErrors(form);

    if (result?.betLimit.id) {
      toast.info(t("account.bet_limits_success_message"));
      await loadData();
    } else {
      toast.error(t("account.bet_limits_error_message"));
    }
  } catch (error) {
    setErrors(form, error.errors);
    toast.error(t("account.bet_limits_error_message"));
  }
}

loadData();
</script>

<template>
  <div class="container">
    <div class="form">
      <BetLimitsForm
        :user-bet-limits="userBetLimits"
        :currency="defaultCurrency"
        @submit="onBetLimitsSubmit"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.margin-top {
  margin-top: 15px;
}

.country-select {
  background-color: var(--be-small-card-bg);
  height: 48px;
  width: 100%;
  padding-top: 7px;
}

.phone {
  margin: 16px 0;
}

.country {
  display: flex;
  gap: 0 20px;
  margin: 16px 0;
}

.postal-code {
  margin-top: 16px;
}

.label {
  font-size: 14px;
  margin-bottom: 7px;
}

.container {
  .countdown {
    color: green;
  }
}

@media screen and (max-width: 500px) {
  .container {
    height: 100%;
  }
}

.form {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 16px;
  margin-bottom: 1rem;
}

.btn-g {
  color: var(--be-primary-btn-label);
  border: none;
  height: 42px;
  width: 100%;
  border-radius: 4px;
  background: var(--Linear, linear-gradient(90deg, #3886ec 0%, #544fe5 100%));
  margin: 20px 0px;
  font-size: 14px;
}
.kyc-title {
  font-size: 12px;
  line-height: normal;
}
.levels {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 10px;
  margin: 16px 0px;
  .level {
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.3);
    width: 132px;
    text-transform: uppercase;
  }

  .level-active {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: var(--Linear, linear-gradient(90deg, #3886ec 0%, #544fe5 100%));
    box-shadow: 0px 0px 10px 0px rgba(116, 62, 232, 0.5);

    height: 32px;
    color: white;
  }

  .form {
    border-radius: 8px;
    background: var(--be-big-card-bg);
    margin: 16px;
    padding: 16px;
  }
}

.w100 {
  width: 100%;
}
</style>
