<script>
export default {
  name: "ContentLoader",

  inheritAttrs: false,

  props: {
    type: {
      type: String,
      default: "list-item, list-item-two-line, list-item-three-line",
    },
  },
};
</script>

<template>
  <!-- <v-skeleton-loader
    dark
    loading
    :type="type"
    v-bind="$attrs"
    v-on="$listeners"
  /> -->
  <div />
</template>
