import { ref, shallowReactive } from "vue";
import { api } from "@/services";

const games = ref([]);
const virtualGames = ref([]);

const loading = ref(false);

export function useVirtualGamesService() {
  async function updateGames() {
    games.value = [];

    loading.value = true;

    games.value = shallowReactive((await api.virtualGames.games()) ?? []);

    loading.value = false;
  }

  async function setVirtualGames(games) {
    virtualGames.value = games;
  }

  return {
    games,
    loading,
    updateGames,
    setVirtualGames,
    virtualGames,
  };
}
