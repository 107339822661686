<script setup>
const props = defineProps({
  title: {
    type: String,
    default: "",
  },
});
</script>

<template>
  <!-- <div class="separator"></div>
    <span class="separator-line"></span>
    <span class="separator-title">{{ props.title }}</span>
    <span class="separator-line"></span>
  </div> -->
  <div class="separator">
    {{ props.title }}
  </div>
</template>

<style lang="scss" scoped>
// .separator {
//   margin-top: 10px;
//   margin-bottom: 4px;
//   display: flex;
//   justify-content: center;
//   .separator-line {
//     display: inline-block;
//     width: 30px;
//     border-bottom: 1px solid #888;
//     margin: 0 10px 7px;
//   }
//   .separator-title {
//     color: #999;
//     text-transform: uppercase;
//     font-size: 0.6em;
//     letter-spacing: 2px;
//   }
// }
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #999;
  text-transform: uppercase;
  font-size: 0.6em;
  letter-spacing: 2px;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #888;
}

.separator:not(:empty)::before {
  margin-right: 0.5em;
}

.separator:not(:empty)::after {
  margin-left: 0.5em;
}
</style>
