<script setup>
import { onMounted, ref } from "vue";
import {useRoute} from "vue-router";
import { t } from "@/app/composables/useI18n";
import { api } from "@/services";

const transaction = ref({});
const wallet = ref({
  balance: 0,
  currency: {
    sign: "",
  },
});
const route = useRoute();
const transactionId = route.params.transactionId;
let refreshIntervalId = null;

async function loadTransaction(id) {
  const transactions = await api.finance.userTransactions({
    id,
    paginator: {
      cp: 1,
      rpp: 1,
    },
  });
  if (transactions.length > 0) {
    transaction.value = transactions[0];

    const wallets = await api.finance.userWallets({
      id: transaction.value.walletId,
    });
    if (wallets.length > 0) {
      wallet.value = wallets[0];
    }

    if (transaction.value.status === "initiated" && refreshIntervalId === null) {
      refreshIntervalId = setInterval(() => {
        loadTransaction(transactionId);
      }, 5000);
    } else if (transaction.value.status !== "initiated" && refreshIntervalId !== null) {
      clearInterval(refreshIntervalId);
    }
  }
}

onMounted(() => {
  loadTransaction(transactionId);
});
</script>

<template>
  <div class="deposit-view" v-if="transaction.id !== undefined">
    <h3 v-if="transaction.status === 'initiated'">{{ t("finance.initiated_transaction_title") }}</h3>
    <h3 v-else-if="transaction.status === 'success'">{{ t("finance.success_transaction_title") }}</h3>
    <h3 v-else-if="transaction.status === 'cancelled'">{{ t("finance.cancelled_transaction_title") }}</h3>
    <h3 v-else-if="transaction.status === 'error'">{{ t("finance.error_transaction_title") }}</h3>

    <div class="initiated" v-if="transaction.status === 'initiated'">
      <table>
        <tr>
          <td>{{ t("finance.initiated_transaction_message") }}</td>
          <td><img src="/assets/images/loading.gif" alt="" class="loading" /></td>
        </tr>
      </table>
    </div>
    <div class="success" v-else-if="transaction.status === 'success'">
      {{ t("finance.success_transaction_message") }}
    </div>
    <div class="cancelled" v-else-if="transaction.status === 'cancelled'">
      {{ t("finance.cancelled_transaction_message") }}
    </div>
    <div class="error" v-else-if="transaction.status === 'error'">
      {{ t("finance.error_transaction_message") }}
    </div>

    <div class="details">
      <div>
        <span class="label">{{ t("finance.transaction_id") }}:</span> <span>{{ transaction.id }}</span>
      </div>
      <div>
        <span class="label">{{ t("finance.amount") }}:</span> {{ wallet.currency.sign }}{{ transaction.amount }}
      </div>
      <div>
        <span class="label">{{ t("finance.current_balance") }}:</span> {{ wallet.currency.sign }}{{ wallet.balance }}
      </div>
      <div>
        <span class="label">{{ t("account.dob_date") }} & {{ t("finance.time") }}:</span> {{ transaction.createdAt }}
      </div>
      <div v-if="transaction.desc !== null && transaction.desc !== ''">
        <span class="label">{{ t("finance.description") }}:</span> {{ transaction.desc }}
      </div>
      <div v-if="transaction.message !== null">
        <span class="label">{{ t("finance.message") }}:</span> {{ transaction.message }}
      </div>
    </div>
  </div>
  <div class="deposit-view" v-else>
    <h3>{{ t("finance.deposit_not_found") }}</h3>
  </div>
</template>

<style lang="scss" scoped>
.deposit-view {
  margin: 2em 0em 4em;

  .initiated {
    color: #3abaf5;
    .loading {
      height: 50px;
    }
  }
  .success {
    color: #3af585;
  }
  .cancelled {
    color: #f5883a;
  }
  .error {
    color: #ec5269;
  }

  .details {
    margin-top: 2em;
    .label {
      color: #f5a83a;
    }
  }
}
</style>
