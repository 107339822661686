import { computed, ref } from "vue";

const timeServer = ref({});

export function useTimeServer() {
  function setTimeServer(nav) {
    timeServer.value = nav;
  }

  return {
    setTimeServer,
    timeServer,
  };
}
