import { ref } from "vue";

const referAndEarn = ref({ enabled: false });

export function useReferAndEarn() {
  function setReferAndEarn(data) {
    referAndEarn.value = data;
  }

  return { referAndEarn, setReferAndEarn };
};
