import { jsonRpc } from "@/services/json-rpc/jsonrpc";

const virtualGames = {
  async games(params) {
    return await jsonRpc({
      method: "virtualgames.games:list",
      params,
    });
  },

  async game(params) {
    return await jsonRpc({
      method: "virtualgames.game:show",
      params,
    });
  },

  async gameInit(params) {
    return await jsonRpc({
      method: "virtualgames.game:init",
      params,
    });
  },
};

export default virtualGames;
