class Exception extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
  }

  static build() {
    return new this();
  }
}

class HttpException extends Exception {
  constructor(message, status, response = {}) {
    super(message);
    this.status = status;
    this.response = response;
  }

  static build(message) {
    return new this(message);
  }
}

export class BadRequestError extends HttpException {
  constructor(message, response) {
    super(message, 400, response);
  }
}

export class AuthenticationError extends HttpException {
  constructor(message, response) {
    super(message, 401, response);
  }
}

export class ForbiddenError extends HttpException {
  constructor(message, response) {
    super(message, 403, response);
  }
}

export class UnprocessableEntityError extends HttpException {
  constructor(message, errors, response) {
    super(message, 422, response);
    this.errors = errors;
    this.buildMessage();
  }

  buildMessage() {
    if (typeof this.errors !== "object") {
      return;
    }

    const keys = Object.keys(this.errors);
    let append = "";
    for (const key of keys) {
      const isArray = Array.isArray(this.errors[key]);
      const isFirstItemString = typeof this.errors?.[key]?.[0] === "string";

      if (isArray && isFirstItemString) {
        append = this.errors?.[key]?.[0];
        break;
      }
    }

    this.message += ` - ${append}`;
  }
}

export class InternalServerError extends HttpException {
  constructor(message, response) {
    super(message, 500, response);
  }
}

export class UnexpectedServerError extends HttpException {
  constructor(message, response) {
    super(message, 0, response);
  }
}

const HttpExceptions = [
  BadRequestError,
  AuthenticationError,
  ForbiddenError,
  UnprocessableEntityError,
  InternalServerError,
];

export function throwHttpException(error) {
  if (!error.response) {
    throw error;
  }

  const status = error.response.status;
  const { message, errors } = error.response.data;

  for (const HttpException of HttpExceptions) {
    if (HttpException.build().status === status) {
      if (status === 422) {
        throw new HttpException(message, errors, error.response);
      }
      throw new HttpException(message, error.response);
    }
  }

  throw new UnexpectedServerError(message, status, error.response);
}

export function isHttpException(error) {
  return error instanceof HttpException;
}

export default {
  Exception,
  AuthenticationError,
  UnprocessableEntityError,
  UnexpectedServerError,
  throwHttpExceptionByStatus: throwHttpException,
  isHttpException,
};
