import Message from "vue-m-message";
import "vue-m-message/dist/style.css";
import "./notification.css";

Message.setDefault({
  position: "bottom-right",
  // className: "toast-item",
  duration: 3000,
  closable: true,
});

const config = {
  position: "bottom-right",
};

const toast = {
  // Message.info(msg);

  info(msg) {
    Message.info(msg, config);
  },
  warn(msg) {
    Message.warning(msg, config);
  },
  error(msg) {
    Message.error(msg, config);
  },
  success(msg) {
    Message.success(msg, config);
  },
};

export { toast };
