export const userAccountSections = [
  {
    class: "payments-section",
    title: "finance.payments_history",
    items: [
      {
        class: "deposit-btn",
        icon: "bank-transfer-in",
        title: "finance.deposit",
        route: "user-deposits",
      },
      {
        class: "withdraw-btn",
        icon: "bank-transfer-out",
        title: "finance.withdraw",
        route: "user-withdrawals",
      },
      {
        class: "transactions-btn",
        icon: "format-list-bulleted",
        title: "finance.transactions",
        route: "user-transactions",
      },
    ],
  },
  {
    // class: "account-details-security-section",
    title: "account.account_details",
    items: [
      {
        icon: "account-details",
        title: "account.edit_details",
        route: "details",
      },
      {
        icon: "card-account-details-outline",
        title: "account.KYC",
        route: "kyc",
      },
    ],
  },
  {
    title: "account.security_preferences",
    items: [
      {
        icon: "lock-outline",
        title: "account.2fa_add",
        route: "2fa",
      },
      {
        icon: "user-preferences",
        title: "account.preferences",
        route: "preferences",
      },
    ],
  },
];

// {
//   class: "betting-section",
//   title: "sportsbook.betting",
//   items: [
//     {
//       class: "open-bets-btn",
//       icon: "mdi-ticket-confirmation",
//       title: "sportsbook.open_bets",
//       route: "mybets"
//     },
//     {
//       class: "settled-bets-btn",
//       icon: "mdi-ticket-confirmation",
//       title: "sportsbook.settled_bets",
//       route: "mybets.settled"
//     }
//   ]
// },
// {
//   class: "contact-help-section",
//   title: "general.contact-help",
//   disabled: true,
//   items: [
//     {
//       class: "message-us-btn",
//       icon: "mdi-message-title",
//       title: "general.message_us")
//     },
//     {
//       class: "faqs-btn",
//       icon: "mdi-help-circle-outline",
//       title: "general.faqs",
//       route: "faqs"
//     }
//   ]
// },
