<script setup>
const uuid = Math.random(10000);
</script>

<template>
  <div class="article">
    <input :id="uuid" class="switch" type="checkbox" checked="false">
    <i />
    <label :for="uuid" class="header">
      <slot name="header" />
    </label>
    <div class="body">
      <slot name="body" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.article {
  animation: flipdown 0.5s ease both;

  .header {
    display: block;
    position: relative;
    // margin-bottom: 15px;
    user-select: none;
    background-color: var(--be-card-background-color) !important;
    // padding: 2px 5px;
    cursor: pointer;
  }
  .body {
    position: relative;
    overflow: hidden;
    max-height: 1000px;
    opacity: 1;
    transform: translate(0, 0);
    // margin-top: 14px;
    z-index: 2;
    // border: 1px solid gray;
    transition: all 0.25s ease-in-out;
  }
  .switch {
    display: none;
    &:checked {
      ~ {
        .body {
          margin-top: 0;
          max-height: 0;
          opacity: 0;
          transform: translate(0, 50%);
        }
        i:before {
          transform: translate(2px, 0) rotate(45deg);
        }
        i:after {
          transform: translate(-2px, 0) rotate(-45deg);
        }
        .header::before {
          transform: rotate(180deg) translateY(6px);
        }
      }
    }
  }

  i {
    position: absolute;
    transform: translate(-6px, 0);
    margin-top: 18px;
    right: 7px;
    z-index: 1;
    &:before,
    &:after {
      content: "";
      position: absolute;
      background-color: #aaa;
      width: 3px;
      height: 9px;
      transition: all 0.25s ease-in-out;
      transform: translate(-2px, 0) rotate(45deg);
    }
    &:after {
      transform: translate(2px, 0) rotate(-45deg);
    }
  }
}

@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }
  5% {
    opacity: 1;
  }
  80% {
    transform: rotateX(8deg);
  }
  83% {
    transform: rotateX(6deg);
  }
  92% {
    transform: rotateX(-3deg);
  }
  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}
</style>
