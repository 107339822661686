import { ref } from "vue";

const screenFactor = ref("");

export function useScreenSize() {
  function initScreenTracker(breakpoints) {
    const screenSizeMatches = [];

    breakpoints = Object.entries(breakpoints).sort((a, b) => +a[1] - +b[1]);

    for (let i = 0; i < breakpoints.length; i++) {
      const clauses = [];
      if (i > 0) {
        clauses.push(`(min-width: ${breakpoints[i - 1][1] + 1}px)`);
      }
      if (i < breakpoints.length - 1) {
        clauses.push(`(max-width: ${breakpoints[i][1]}px)`);
      }

      const mediaMatch = window.matchMedia(clauses.join(" and "));
      mediaMatch.addEventListener("change", (e) => {
        if (e.matches) {
          // setScreenSizeCallback(breakpoints[i][0]);
          screenFactor.value = breakpoints[i][0];
        }
      });
      if (mediaMatch.matches) {
        // setScreenSizeCallback(breakpoints[i][0]);
        screenFactor.value = breakpoints[i][0];
      }
      screenSizeMatches.push(mediaMatch);
    }
    //   console.log(screenSizeMatches);
  }

  return {
    screenFactor,
    initScreenTracker,
  };
}
