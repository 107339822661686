<script setup>
import { computed, ref } from "vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";

const props = defineProps({
  wallet: {
    type: Object,
    default: null,
  },
  showBalance: {
    type: Boolean,
    default: true,
  },
  showTicker: {
    type: Boolean,
    default: false,
  },
});

const showInfo = ref(false);

const title = computed(() => {
  return props.showTicker ? props.wallet.currency.name : props.wallet.currency.title;
});
const amount = computed(() => {
  return props.wallet.currency.places
    ? Number(props.wallet.balance).toFixed(props.wallet.currency.places)
    : props.wallet.balance;
});

const rolloverTitle = computed(() => {
  if (props.wallet.type === "bonus") {
    return `${props.wallet.data.rollover.requirement / props.wallet.data.bonusAmount}x (${
      props.wallet.data.rollover.requirement
    } ${props.wallet.currency.ticker})`;
  } else {
    return "";
  }
});
const rolloverProgress = computed(() => {
  return props.wallet?.data?.rollover
    ? (100 * props.wallet.data.rollover.amount / props.wallet.data.rollover.requirement).toFixed(2)
    : "";
});

function onClick() {
  if (props.wallet.type === "bonus") {
    showInfo.value = !showInfo.value;
  }
}
</script>

<template>
  <div class="option-wrapper" :class="`${props.wallet.type}-wallet`">
    <div class="option" @click="onClick">
      <div class="currency-symbols">
        <BaseIcon type="image" :name="props.wallet.currency.icon" class="currency-icon" />
        <BaseIcon
          type="image"
          size="40"
          name="bonus-ribbon"
          class="bonus-ribbon"
        />
        <div class="title" :class="{ showInfo }">
          {{ title }}
          <BaseIcon
            class="uil"
            name="chevron-down"
            size="24"
          />
        </div>
      </div>
      <div class="amount">
        {{ amount }}
      </div>
    </div>
    <div class="info" :class="{ opened: showInfo }">
      <hr class="top-hr">
      <h3>Bonus status</h3>
      <div class="rollover-progress-wrapper">
        <h4>Bonus Rollover {{ rolloverTitle }}</h4>
        <span class="progress-value">
          {{ rolloverProgress }}%
        </span>
        <progress
          color="#ff8719"
          max="100"
          :value="rolloverProgress"
        />
      </div>
      <p>
        <!-- Once the above 2 rollover requirements have been completed, -->
        Once the above rollover requirement has been completed, the bonus balance will be
        transferred to the main balance, and funds will be withdrawable.
      </p>
      <p>See Rules for full Bonus Terms & Conditions</p>
      <hr>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.option {
  margin: 5px 0;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  width: max-content;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 100%;
  color: #777;

  &:hover {
    background-color: #f5f5f5;
  }

  .total &:hover,
  .bonus-wallet &:hover {
    // cursor: default;
    background-color: unset;
  }
  .bonus-wallet & {
    margin-bottom: 0;
    color: #999;
    .currency-icon {
      opacity: 0.3;
    }
    .bonus-ribbon {
      position: absolute;
      top: -20px;
      left: -10px;
    }
  }
  .currency-symbols {
    display: flex;
    align-items: center;
    min-width: 10em;
    position: relative;
    .title {
      display: flex;
      margin-left: 1em;
      text-wrap: nowrap;
      .uil {
        transition: transform 200ms linear;
        margin-left: 6px;
      }
      &.showInfo {
        .uil {
          transform: rotate(180deg);
        }
      }
    }
  }

  .amount {
    min-width: 8em;
    text-align: right;
  }
  .selected-option & {
    margin: 0;
    padding: 0 2px 0 5px;
    background-color: unset;
    .amount {
      min-width: unset;
    }
    .currency-symbols {
      min-width: 2em;
      .title {
        display: none;
      }
    }
    img {
      margin-left: 7px;
      margin-right: 9px;
    }
  }
}
.selected-option.option-wrapper {
  width: 80%;
}

.info {
  max-height: 0px;
  overflow: hidden;
  margin: 0 1em;
  transition: max-height 0.2s ease-out;
  display: flex;
  flex-direction: column;
  color: #666;
  &.opened {
    // margin: 1em;
    max-height: 350px;
    transition: max-height 0.55s ease-out;
    .rollover-progress-wrapper .progress-value {
      opacity: 1;
    }
  }
  .rollover-progress-wrapper {
    margin: 0;
    progress {
      width: 100%;
      height: 15px;
      margin: 7px 0;
      // border-radius: 3px;
    }
    progress,
    progress::-webkit-progress-value {
      // width: 500px;
      border: 0;
      height: 15px;
      border-radius: 15px;
      background: #ff8719;
    }
    progress::-webkit-progress-bar {
      // width: 500px;
      border: 0;
      height: 15px;
      border-radius: 15px;
      background: #fbf197;
    }
    .progress-value {
      position: absolute;
      font-size: 11px;
      padding-top: 6px;
      left: 46%;
      color: #eee;
      opacity: 0;
      transition: opacity 0.25s ease-out;
    }
  }
  h3 {
    margin: 0.2em 0 0.6em;
    text-align: center;
  }
  h4 {
    font-size: 0.8em;
  }
  p {
    font-size: 0.8em;
    font-weight: 400;
    font-style: italic;
  }
  hr {
    margin: 1em;
  }
  .top-hr {
    border-top: 1px solid #ddd;
    border-bottom: 0;
    margin: 0.5em 1em;
  }
}

.funds-wallet {
  .bonus-ribbon, .uil, .info {
    display: none;
  }
}
</style>
