import { ref } from "vue";

const logo = ref(null);
const banners = ref([]);

export function useLogoBanner() {
  function setBanners(nav) {
    banners.value = nav;
  }

  function setLogo(nav) {
    logo.value = nav;
  }

  return {
    setBanners,
    setLogo,
    logo,
    banners,
  };
}
