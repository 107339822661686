import { type RouteRecordRaw } from "vue-router";
import Main from "@/events/components/Main.vue";
import EventsLayout from "@/events/layouts/EventsLayout.vue";

// import ResetPassword from "@/user-account/components/security/ResetPassword.vue";

export const eventsRoutes: RouteRecordRaw = {
  path: "/events",
  // name: "account",
  component: EventsLayout,
  children: [
    {
      path: "",
      name: "events",
      component: Main,
      meta: { requiresAuth: true, backRoute: "/" },
    },
  ],
};
