import { computed, ref } from "vue";
import { useUserService } from "@/user-account/composables/useUserService.ts";

const freeBets = ref([]);
const { activeWallet } = useUserService();

const activeFreeBets = computed(() => {
  if (!activeWallet.value) {
    return [];
  }

  return freeBets.value.filter((freeBet) => {
    return Number.parseInt(freeBet.currency.id) === Number.parseInt(activeWallet.value.currency.id);
  });
});

export function useFreeBets() {
  async function setFreeBets(_freeBets) {
    freeBets.value = _freeBets;
  }

  return {
    freeBets,
    activeFreeBets,
    setFreeBets,
  };
}
