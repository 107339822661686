import { ref } from "vue";
import { api } from "@/services";
import { useUserService } from "@/user-account/composables/useUserService";

const { isAuthenticated } = useUserService();
const activeBonus = ref(null);
const waitBonuses = ref([]);
const usedBonuses = ref([]);

export function usePromotion() {
  async function getActiveBonus() {
    if (!isAuthenticated()) {
      return;
    }

    const result = await api.promotion.userBonuses({
      status: 1,
      paginator: {
        cp: 1,
        rpp: 1,
      },
    });

    activeBonus.value = result.bonuses[0] ?? null;
  }

  async function getWaitBonuses() {
    if (!isAuthenticated()) {
      return;
    }

    const result = await api.promotion.userBonuses({
      status: 0,
      paginator: {
        cp: 1,
        rpp: 100,
      },
      order: {
        field: "id",
        direction: "asc",
      },
    });

    waitBonuses.value = result.bonuses ?? [];
  }

  async function getUsedBonuses() {
    if (!isAuthenticated()) {
      return;
    }

    const result = await api.promotion.userBonuses({
      statuses: [3, 4],
      paginator: {
        cp: 1,
        rpp: 100,
      },
      order: {
        field: "id",
        direction: "desc",
      },
    });

    usedBonuses.value = result.bonuses ?? [];
  }

  return {
    getActiveBonus,
    getWaitBonuses,
    getUsedBonuses,
    activeBonus,
    waitBonuses,
    usedBonuses,
  };
}
