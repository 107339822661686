<script setup>
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { useBetslip } from "../../sportsbook/composables/useBetslip";
import { toast } from "@/app/utils/notification";
import { useStopwatch, useTimer } from "vue-timer-hook";
import moment from "moment";
import { useTimeServer } from "@/app/composables/useTimeServer";
import { onMounted, watchEffect } from "vue";
import { capitalizeString, matchStatuses, convertToTimeFormat } from "@/sportsbook/utils/general";
import { router } from "@/app/router";

const props = defineProps({
  match: {
    type: Object,
  },
  matchIndex: {
    type: Number,
  },
  slug: {
    type: String,
  },
  tournamentId: {
    type: String,
  },
  countryId: {
    type: String,
  },
});

const { timeServer } = useTimeServer();

function splitName(text, count) {
  return text.slice(0, count) + (text.length > count ? "..." : "");
}

const autoStart = true;

const currentTime = moment(timeServer.value);
const givenTime = moment(props.match?.clock?.refPoint);
const difference = moment.duration(currentTime.diff(givenTime));

function durationToSeconds(duration) {
  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;

  const { years, months, days, hours, minutes, seconds, milliseconds } = duration;

  const totalSeconds =
    seconds +
    minutes * secondsInMinute +
    hours * secondsInHour +
    days * secondsInDay +
    milliseconds / 1000;

  return totalSeconds;
}

const stopwatch = useStopwatch(Math.floor(durationToSeconds(difference._data)), autoStart);

const time = new Date();
time.setSeconds(time.getSeconds() + Math.floor(durationToSeconds(difference._data))); // 10 minutes timer
const timer = useTimer(time);

function toggleSelection(marketGroup, index, match) {
  const market = Object.values(match.markets).find(
    (m) => m.id === marketGroup.markets[marketGroup.activeIndex].id
  );
  const selection = Object.values(market?.odds)[index];
  selection.awayTeamName = match.awayTeamName;
  selection.homeTeamName = match.homeTeamName;
  selection.startDate = match.startsAt;
  betslip.toggleSelection(selection, match, callToast);
}

function callToast() {
  toast.error("Max 20 selections per bet");
}

const betslip = useBetslip();

function onMatchClick(matchId) {
  router.push({
    name: "sport.event",
    params: {
      eventId: matchId,
    },
  });
}
</script>

<template>
  <div :class="`row ${matchIndex % 2 == 0 ? '' : 'dark'}`">
    <div class="time">
      <div>
        {{
          slug == "football" && match?.clock?.isRunning
            ? convertToTimeFormat(
                `${stopwatch.minutes.value + stopwatch.hours.value * 60}:${
                  stopwatch.seconds.value
                } `
              )
            : (slug == "basketball" || slug == "ice hockey") && match?.clock?.isRunning
            ? convertToTimeFormat(
                `${timer.minutes.value + timer.hours.value * 60}:${timer.seconds.value} `
              )
            : null
        }}
        {{ matchStatuses?.[capitalizeString(slug)]?.[match?.gameState] }}
      </div>
    </div>

    <div class="score" v-if="slug == 'basketball' || slug == 'ice hockey' || slug == 'football'">
      {{ match?.score?.home }} - {{ match?.score?.away }}
    </div>
    <div class="score" v-else>
      <div>
        <span class="current">{{ match?.score?.home }} </span>
        <span v-if="slug == 'tennis'" class="current">{{ match?.score?.currentSet?.home }}</span>
        <span>{{ match?.score?.currentGame?.home }}</span>
      </div>
      <div>
        <span class="current">{{ match?.score?.away }} </span>
        <span class="current" v-if="slug == 'tennis'">{{ match?.score?.currentSet?.away }}</span>
        <span>{{ match?.score?.currentGame?.away }}</span>
      </div>
    </div>
    <div class="match" @click="onMatchClick(match.id)">
      <span>{{ splitName(match?.homeTeamName, 15) }}</span
      ><span>&nbsp; vs &nbsp;</span>
      <span>{{ splitName(match?.awayTeamName, 15) }}</span>
    </div>
    <div class="boxes full-time">
      <div
        v-for="(odd, oddIndex) in Object.values(Object.values(match.markets)[0]?.odds)"
        :class="`box ${odd.isActive ? 'cursor-pointer' : ''} ${
          betslip.containsSelection(odd.id) ? 'yellow-box' : null
        }`"
        @click="
          () =>
            odd.isActive
              ? toggleSelection(
                  { markets: [Object.values(match.markets)[0].market], activeIndex: 0 },
                  oddIndex,
                  match
                )
              : null
        "
        :data-selection-id="odd?.id"
        :data-event-id="odd?.eventId"
        :data-league-id="tournamentId"
        :data-market-id="odd?.marketId"
        :data-category-id="countryId"
        :data-odds-id="odd?.id"
      >
        <div :class="betslip.containsSelection(odd.id) ? '' : 'sub-label'">
          {{ match.markets[0]?.market?.headers[oddIndex] }}
        </div>
        <div v-if="odd.isActive">
          {{ odd.odds }}
        </div>
        <img v-else class="lock" src="/assets/images/lock.png" alt="" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.lock {
  width: 30px;
}

.livebetting {
  padding: 30px;
  display: flex;
  gap: 0 30px;
  .left-sec {
    width: 73%;
  }
  .right-sec {
    width: 27%;
  }

  @media screen and (max-width: 500px) {
    .left-sec {
      width: 100%;
    }
    .right-sec {
      display: none;
    }
  }
}

.black-text {
  color: #17271a;
}

.live {
  border-radius: 4px 4px 0px 0px;
  background: #f00;
  color: white;
  display: flex;
  width: 89px;
  height: 39px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 0 4px;

  .live-icon {
    width: 14px;
    height: 14px;
  }
}

.breadcrumb {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  display: flex;
  gap: 0 8px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.game-bar {
  border-radius: 0px 8px 8px 8px;
  background: var(--be-big-card-bg);
  display: flex;
  overflow-x: scroll;

  .game {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    padding: 10px 10px 12px 10px;
    gap: 8px 0px;
    cursor: pointer;
    text-transform: capitalize;
    .game-icon {
      position: relative;
      width: fit-content;
    }

    .game-index {
      border-radius: 10px;
      background: #f00;
      width: 10px;
      height: 10px;
      font-size: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -2px;
      right: -8px;
    }
  }

  .dim-game {
    opacity: 0.5;
  }
}

.heading {
  font-size: 14px;
  margin: 16px 0;
}

.header-bar {
  background: var(--be-big-card-bg);
  display: flex;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  padding: 8px 10px;
  text-transform: uppercase;
  border-radius: 8px 8px 0 0;
}

.time {
  width: 20%;
  text-align: center;
}

.score {
  width: 20%;
  text-align: center;

  .current {
    margin-right: 8px;
  }
}

.match {
  width: 35%;
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .match {
    width: 95%;
    text-transform: uppercase;

    .match-name {
      width: 8 0px;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      text-overflow: ellipsis;
    }

    .match-v {
      width: 20px;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      text-overflow: ellipsis;
    }
  }
}

.full-time {
  width: 25%;
  display: flex;
  gap: 0 3px;
}

.rows {
  border: 1px solid var(--be-big-card-bg);
  border-radius: 0 0 8px 8px;
  .row {
    display: flex;
    padding: 10px;
    align-items: center;
    .time {
      color: #ff453a;
      text-transform: uppercase;
      font-size: 12px;
    }

    .match {
      font-size: 14px;
    }

    .boxes {
      display: flex;
      gap: 0 10px;

      .box {
        border-radius: 5px;
        background: var(--be-small-card-bg);
        width: 122.5px;
        height: 59px;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;
      }

      .sub-label {
        font-size: 13px;
        color: white;
        opacity: 0.5;
      }
      .yellow-box {
        border-radius: 5px;
        background: var(--be-primary-btn);
        color: var(--be-primary-text-color);
        width: 122.5px;
        height: 59px;
        padding: 8px;
        display: flex;
        opacity: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
      }
    }
  }

  .dark {
    background: var(--be-big-card-bg);
  }
}

.stake-header {
  display: flex;
  justify-content: space-between;

  .stake-title {
    display: flex;
    gap: 0 10px;
    font-size: 14px;
  }
}

.stake {
  border-radius: 4px;
  background: var(--be-small-card-bg);
  width: 150px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  padding-left: 8px;
  .stake-btc {
    background: var(--be-divider);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 0 4px 4px 0;
  }
}

.delete-icon {
  width: 16px;
  height: 16px;
}

.detail {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: flex;
  gap: 0 4px;
}

.digit {
  color: #f09426;
}

.currency-digit {
  color: #1382eb;
}

.win-detail {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  gap: 0 3px;
}

.multiple {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 16px 0 8px 0;
  .clear-slip {
    display: flex;
    gap: 0 4px;
    color: rgba(255, 255, 255, 0.5);
  }
}

.btn-g {
  color: var(--be-primary-btn-label);
  border: none;
  height: 42px;
  width: 100%;
  border-radius: 4px;
  background: var(--Linear, linear-gradient(90deg, #3886ec 0%, #544fe5 100%));
  margin: 12px 0px;
  cursor: pointer;
  font-size: 14px;
}

.betslip {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  .betslip-label {
    display: flex;
    gap: 0 6px;
  }
}

.slip-card {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 10px;
}
.boxes {
  display: flex;
  justify-content: space-between;
  .odd-box {
    border-radius: 8px;
    background: var(--be-big-card-bg);
    padding: 6px 16px 8px 16px;
    display: flex;
    width: 131px;
    height: 52px;
    flex-direction: column;
    align-items: flex-end;
    font-size: 14px;

    .odd-digit {
      display: flex;
      color: #1382eb;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      gap: 0 4px;
    }
  }
  .stake-box {
    border-radius: 4px;
    background: var(--be-small-card-bg);
    width: 134px;
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    padding-left: 10px;

    .stake-box-btc {
      background: #333360;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 52px;
      border-radius: 0 4px 4px 0;
    }
  }
}

@media screen and (max-width: 500px) {
  .livebetting {
    padding: 14px;
  }
  .header-bar {
    display: none;
  }
  .rows {
    // border: none;
    border-radius: 8px;
    .row {
      flex-direction: column;
      .time {
        display: flex;
        justify-content: center;
        gap: 0 3px;
        width: 100%;
        margin-bottom: 4px;
      }
    }
  }

  .boxes {
    width: 100%;
    margin-top: 8px;
  }
}
</style>
