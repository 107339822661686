import { jsonRpc } from "@/services/json-rpc/jsonrpc";

const promotion = {
  async userBonuses(params) {
    return await jsonRpc(
      {
        method: "bonus.user:list",
        params,
      },
    );
  },
};

export default promotion;
