<script setup>
const props = defineProps({
  label: {
    type: String,
    default: "Result and both teams to score ‘X’ points",
  },
  headings: {
    type: Object,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: ["100", "120"],
  },
  items: {
    type: Object,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: ["3.2", "3.4"],
  },
});
</script>

<template>
  <div class="table">
    <div class="label">
      {{ props.label }}
    </div>
    <div class="headings">
      <div class="flex-item" />

      <div v-for="item in headings" class="flex-item">
        {{ item }}
      </div>
    </div>

    <div class="item">
      <div class="flex-item">Top Raptors and Yes</div>
      <div v-for="item in items" class="flex-item-center">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 10px;
  .label {
    color: var(--White, #fff);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .headings {
    background: var(--be-small-card-bg);
    display: flex;
    justify-content: center;
    height: 39px;
    align-items: center;
    border-radius: 4px 4px 0 0;
    color: #fff;

    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    .flex-item {
      flex: 33%;
      display: flex;
      justify-content: center;
    }
  }

  .item {
    display: flex;
    justify-content: center;
    height: 39px;
    // align-items: center;
    border-radius: 4px 4px 0 0;

    .flex-item {
      flex: 33%;
      display: flex;
      padding-left: 10px;
      align-items: center;
      border-right: 1px solid var(--be-small-card-bg);
      border-left: 1px solid var(--be-small-card-bg);
      border-bottom: 1px solid var(--be-small-card-bg);
      color: #fff;

      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .flex-item-center {
      flex: 33%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid var(--be-small-card-bg);
      border-bottom: 1px solid var(--be-small-card-bg);
      color: #f09426;

      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
</style>
