<script setup>
import { computed, onMounted, ref } from "vue";
import Stake from "./Stake.vue";
import { api } from "@/services";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { t } from "@/app/composables/useI18n";

const props = defineProps({
  show: Boolean,
});

const emit = defineEmits(["close"]);

const tabs = [
  { title: "open_bets", icon: "deposit", value: "open" },
  { title: "settled_bets", icon: "settled", value: "settled" },
];

const selectedTab = ref("open");
const totalCount = ref(0);
const page = ref(1);

let tickets = ref([]);
const loading = ref(true);

async function fetchBets(type = "open", cp = 1) {
  const data = await api.users.tickets({
    type: type,
    paginator: { cp: cp, rpp: 10 },
  });

  tickets.value = [...tickets.value, ...data?.tickets];
  loading.value = false;

  totalCount.value = data?.totalCount;
}

const filteredTickets = computed(() => {
  return tickets.value?.filter((ticket) => {
    if (selectedTab.value === "open") {
      return ticket.status === "open";
    }

    return ticket.status !== "open";
  });
});

onMounted(() => {
  fetchBets();
});

const handleTabs = (tab) => {
  selectedTab.value = tab;
  tickets.value = [];
  page.value = 1;
  loading.value = true;
  fetchBets(tab);
};

const handleFetchMore = () => {
  page.value = page.value + 1;
  fetchBets(selectedTab.value, page.value);
};
</script>

<template>
  <div>
    <div class="bets">
      <div
        v-for="tab in tabs"
        :key="tab.value"
        class="bet"
        :class="{ 'active-bet': selectedTab === tab.value }"
        @click="() => handleTabs(tab.value)"
      >
        <!-- <img :src="tab.icon" /> -->
        <BaseIcon
          :name="tab.icon"
          size="18"
          :color="
            selectedTab === tab.value
              ? 'var(--be-primary-btn-text-label)'
              : 'var( --be-primary-text-color-opacity)'
          "
        />
        <div>
          {{ t(`sportsbook.${tab.title}`) }}
        </div>
      </div>
    </div>

    <Stake v-for="ticket in filteredTickets" :key="ticket.id" :ticket="ticket" />
    <button
      @click="handleFetchMore"
      v-if="tickets?.length < totalCount && tickets?.length != 0"
      class="load-more"
    >
      Load More
    </button>
    <div v-if="!loading && filteredTickets?.length == 0">
      <div class="empty-data">
        {{ t("general.dont_have") }} {{ t(`sportsbook.${selectedTab}`) }} {{ t("general.bets") }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.empty-data {
  text-align: center;
  margin: 40px 0;
}
.load-more {
  background: var(--be-primary-btn);
  height: 45px;
  width: calc(100% - 32px);
  border-radius: 8px;
  margin: 10px 16px;
  color: var(--be-primary-btn-label);
}
.bets {
  display: flex;
  gap: 0 16px;
  justify-content: center;
  border-radius: 10px;
  background: var(--be-big-card-bg);
  padding: 10px;
  margin: 10px 16px;
}

.bet {
  color: rgba(255, 255, 255, 0.3);
  width: 191px;
  height: 40px;
  display: flex;
  gap: 0 8px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.active-bet {
  color: var(--be-primary-btn-label);
  background: var(--be-primary-btn);
  cursor: pointer;
}
</style>
