import { ref } from "vue";

const isActive = ref(false);
// const systemSettings = ref(false);

export function useGlobalLoader() {
  function enableLoader(duration = null) {
    isActive.value = true;
    if (duration) {
      setTimeout(disableLoader, duration);
    }
  }
  function disableLoader() {
    isActive.value = false;
  }

  return {
    enableLoader,
    disableLoader,
    isActive,
  };
}
