<script lang="ts" setup>
import { GDialog } from "gitart-vue-dialog";
import BaseButton from "./BaseButton.vue";
import "gitart-vue-dialog/dist/style.css";

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  content: {
    type: String,
    default: "",
  },
});

// const dialogState = ref(false);
const dialogState = defineModel<boolean>();
</script>

<template>
  <GDialog v-model="dialogState" max-width="500">
    <div class="wrapper">
      <div class="content">
        <div class="title">
          {{ props.title }}
        </div>

        <p>
          {{ props.content }}
        </p>
      </div>

      <div class="actions">
        <BaseButton class="actions-button" @click="dialogState = false">
          Ok
        </BaseButton>
      </div>
    </div>
  </GDialog>

  <!-- <button class="btn" @click="dialogState = true">
    Open Dialog
  </button> -->
</template>

<style lang="scss" scoped>
.wrapper {
  color: #000;

  .content {
    padding: 20px;

    .title {
      font-size: 1.4em;
      font-weight: 700;
      margin-bottom: 1em;
    }

  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

}
</style>
