<script setup>
// import BetSlip from "./BetSlip.vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { useBetslip } from "@/sportsbook/composables/useBetslip";
import { useI18n } from "@/app/composables/useI18n";
import BetSlip from "@/app/components/BetSlip/BetSlip.vue";

const { mobileBetslipVisible, selections } = useBetslip();
const { t } = useI18n();
</script>

<template>
  <!-- v-if="mobileBetslipVisible" -->

  <div
    class="betslip-mobile"
    v-if="mobileBetslipVisible"
    :class="{ open: mobileBetslipVisible && selections.length > 0 }"
  >
    <div class="bet-slip-mb">
      <!-- <div class="close-title" @click="mobileBetslipVisible = false">
        <div>{{ t("bets.close_betslip") }}</div>
        <BaseIcon name="chevron-down" />
      </div> -->
      <BetSlip :mbl="true" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.betslip-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 105;
  width: 100%;
  height: 100%;
  transform: translateY(+100%);
  transition: transform 0.3s ease-in-out;

  background: rgba(13, 12, 44, 0.5);
  backdrop-filter: blur(5px);

  &.open {
    transform: translateX(0);
  }
  .close-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: 600;
    font-size: 18px;
    color: white;
    cursor: pointer;
    background: linear-gradient(311.99deg, #f09426 0%, #f5a83a 100%);
  }

  .bet-slip-mb {
    position: fixed;
    bottom: 0px;
    border-bottom: 1px solid var(--be-small-card-bg);
    width: 100%;
    background: var(--be-bg);
    padding: 16px;
    height: 670px;
  }

  @media screen and (max-height: 736px) {
    .bet-slip-mb {
      height: 100%;
      overflow: auto;
    }
  }
}
</style>
