<script setup>
import { ref } from "vue";
import { toast } from "@/app/utils/notification";
import { t } from "@/app/composables/useI18n";
import { api } from "@/services";

const props = defineProps({
  currencyId: {
    type: Number,
    required: true,
  },
  providerId: {
    type: Number,
    required: true,
  },
});

const loading = ref(false);
const depositAmount = ref(100);

async function depositInit() {
  loading.value = true;
  const result = await api.finance.depositInit(
    props.providerId,
    props.currencyId,
    depositAmount.value,
  );
  loading.value = false;

  if (result?.transaction?.status === "success") {
    toast.info(t("finance.success_transaction_message"));
  } else {
    toast.error(t("finance.error_transaction_message"));
  }
}
</script>

<template>
  <div class="label">{{ t("finance.deposit_amount") }}</div>
  <input v-model="depositAmount" class="deposit-amount" type="number" />

  <button class="btn-g" :disabled="loading" @click="depositInit()">
    <span v-if="loading">{{ t("finance.loading") }}</span>
    <span v-else>{{ t("finance.deposit") }}</span>
  </button>
</template>

<style lang="scss" scoped>
.btn-g {
  color: var(--be-primary-btn-label);
  border: none;
  height: 42px;
  width: 100%;
  border-radius: 4px;
  background: var(--Linear, linear-gradient(90deg, #3886ec 0%, #544fe5 100%));
  margin: 12px 0px;
}

.label {
  margin-bottom: 7px;
}

.deposit-amount {
  font-size: 14px;
  border-radius: 4px;
  background: var(--be-small-card-bg);
  border: none;
  height: 40px;
  outline: none;
  width: 100%;
  color: white;
  text-indent: 10px;
}
</style>
