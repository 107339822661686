<script setup>
import { onMounted, ref } from "vue";

// import { api } from "@/services";
import { useSportsdata } from "../composables/useSportsdata.js";
import SportCoupons from "@/sportsbook/components/SportCoupons.vue";

// import PageSelector from "@/components/PageSelector";
import ProgressLoader from "@/app/components/ui/ProgressLoader.vue";
import ContentLoader from "@/app/components/ContentLoader.vue";

// import { useRoute } from "@/router";

// const route = useRoute();
const sports = ref([]);
// const leagues = ref([]);
const filters = ref({});
const { fetchSportsEvents } = useSportsdata();
const loading = ref(false);

// watch(
//   route,
//   async () => {
//     // console.log("route in SP - ", route.value);
//     if (route.value.name == "upcoming.events") {
//       sports.value = [getSportBySlug(route.value.params.sportSlug)];
//       // sports.value[0].tournaments = await fetchSportEvents(sports.value[0], filters);
//       // leagues.value =
//       // filters.value = {
//       //   sportId: getSportBySlug(route.value.params.sportSlug),
//       // };
//     } else if (["sports.upcoming", "sports.today"].includes(route.value.name)) {
//       // filters.value = { timeFrame: "today" };
//       if (sports.value.length == 0) {
//         // sports.value = await api.sportsdata.sports(fetchSportsEvents);
//         loading.value = true;
//         sports.value = await fetchSportsEvents(filters.value);
//         loading.value = false;
//       }
//     } else if (route.value.name == "country.leagues") {
//       filters.value = { categoryId: route.value.params.countryId };
//       sports.value = [getSportBySlug(route.value.params.sportSlug)];
//     }
//     // console.log("---------- ", sports.value);
//   },
//   { immediate: true }
// );

// computed: {
//   // timeStatus() {
//   //   return this.live ? t("sportsbook.live") : t("sportsbook.upcoming");
//   // },
// },

// name

onMounted(async () => {
  loading.value = true;
  sports.value = await fetchSportsEvents(filters.value);
  loading.value = false;
});

// beforeDestroy() {
//   clearInterval(this.worker);
// },
</script>

<template>
  <div v-if="!sports.length || loading">
    <ProgressLoader :loading="loading" />
    <ContentLoader />
  </div>
  <div v-else>
    <div v-for="(sport, i) in sports" :key="sport.id">
      <SportCoupons :sport="sport" @loading="updateLoadingStatus(i, $event)" />
    </div>
  </div>
</template>
