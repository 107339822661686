import { jsonRpc } from "@/services/json-rpc/jsonrpc";

const auth = {

  async register(params) {
    return await jsonRpc({
      method: "auth:register",
      params,
    });
  },
  async login(params) {
    return await jsonRpc({
      method: "auth:login",
      params,
    });
  },
  async verifyCode(params) {
    return await jsonRpc({
      method: "auth:verifyCode",
      params,
    });
  },
  async verifyPasswordReset(params) {
    return await jsonRpc({
      method: "auth:verifyPasswordReset",
      params,
    });
  },

  async resetPassword(params) {
    return await jsonRpc({
      method: "auth:resetPassword",
      params,
    });
  },

  async resendCode(params) {
    return await jsonRpc({
      method: "auth:resendCode",
      params,
    });
  },

  async resendPasswordResetCode(params) {
    return await jsonRpc({
      method: "auth:resendPasswordResetCode",
      params,
    });
  },

  async changePassword(params) {
    return await jsonRpc({
      method: "auth:changePassword",
      params,
    });
  },

  async resendOtpCode(params) {
    return await jsonRpc({
      method: "auth:resendOtpCode",
      params,
    });
  },
  // 2FA
  async qrCode(params) {
    return await jsonRpc({
      method: "auth.2fa:qrCode",
      params,
    });
  },
  async enable2fa(params) {
    return await jsonRpc({
      method: "auth.2fa:enable",
      params,
    });
  },
  async disable2fa(params) {
    return await jsonRpc({
      method: "auth.2fa:disable",
      params,
    });
  },

};

export default auth;
