<script setup>
import BaseTextField from "@/app/components/ui/BaseTextField.vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { useI18n } from "@/app/composables/useI18n";
import { toast } from "@/app/utils/notification";

const props = defineProps({
  coin: {
    type: String,
    required: true,
  },

  readOnly: {
    type: Boolean,
    default: false,
  },

  showActions: {
    type: Boolean,
    default: false,
  },

  clearable: {
    type: Boolean,
    default: false,
  },

  label: {
    type: String,
    default: "",
  },

  // title: String
});

const { t } = useI18n();

const modelValue = defineModel();

function copy() {
  // this.$emit("copy", this.value);
  const el = document.createElement("textarea");
  el.value = props.modelValue;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
  toast.info(t("finance.address_copied"));
}
</script>

<template>
  <div>
    <BaseTextField
      v-model="modelValue"
      class="coin-address"
      :clearable="clearable"
      :label="label"
      :readonly="readOnly"
    />
    <div class="wallet-action copy-address" @click="copy">
      <BaseIcon v-if="showActions" name="content-copy" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wallet-address {
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer !important;

  .wallet-action {
    padding: 12px;
    background-color: var(--be-small-card-bg);
    margin: 0 5px 0 1em;
    border-radius: 3px;
    cursor: pointer;
  }

  // .wallet-actions {
  //   display: flex;
  //   justify-content: space-between;
  //   align-content: center;
  //   margin: 5px 0 5px 5px;
  //   cursor: pointer;
  // }
}

.tablet .wallet-action.copy-address {
  padding: 12px 5px 12px;
}

.coin-address.mobile-panel {
  cursor: pointer;
}

.coin-address {
  cursor: pointer;
  width: 100%;
}

.coin-deposit-address-title {
  line-height: 20px;
}
</style>
