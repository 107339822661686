<script lang="ts" setup>
import { ref, watch } from "vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";

const props = defineProps({
  showDownIcon: {
    type: Boolean,
    default: true,
  },
});

const uuid = Math.round(Math.random() * 10000).toString();

const isOpen = ref(false);
const dropdownPanel = ref();

defineExpose({
  close,
});

function clickListener(event) {
  if (!event.composedPath().includes(dropdownPanel.value)) {
    close();
  }
}

watch(isOpen, () => {
  if (isOpen.value === true) {
    window.addEventListener("click", clickListener);
  } else {
    window.removeEventListener("click", clickListener);
  }
});

function close() {
  isOpen.value = false;
}
</script>

<template>
  <div class="base-dropdown" :class="{ open: isOpen }">
    <label :for="uuid" @click.stop="isOpen = !isOpen">
      <slot name="label" />
      <BaseIcon v-if="props.showDownIcon" class="uil" name="chevron-down" size="24" />
    </label>
    <div ref="dropdownPanel" class="dropdown-panel">
      <slot name="panel" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.open-up.base-dropdown .dropdown-panel {
  top: unset;
  bottom: 40px;
  left: auto;
  right: -50px;
  max-height: 90vh;
  overflow-y: auto;
}
.base-dropdown {
  position: relative;
  max-width: 100%;

  label {
    position: relative;
    // height: 50px;
    transform: rotate(0deg);
    transition: transform 200ms linear;
    border-radius: 4px;
    border: none;
    display: flex;

    cursor: pointer;
    .uil {
      transition: transform 200ms linear;
    }
  }
}

.open {
  label {
    .uil {
      transform: rotate(180deg);
      // transition: transform 200ms linear;
    }
  }
  .dropdown-panel {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
}

.dropdown-panel {
  position: absolute;
  // padding: 5px;
  color: var(--be-card-text-color);
  background-color: var(--be-card-background-color);
  top: 42px;
  right: 0;
  // width: 220px;
  border-radius: 2px;
  display: block;
  box-shadow: 0 14px 35px 0 rgba(9, 9, 12, 0.4);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: all 200ms linear;
  .wide & {
    width: 100%;
  }
  .center & {
    max-width: 100%;
    top: 50px;
  }
  .mobile .header-wallet-select & {
    position: fixed;
    max-width: 94vw;
    top: 70px;
    left: 3vw;
  }
  :deep(a) {
    color: inherit;
  }

  &:before {
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    content: "";
    display: block;
    z-index: 1;
  }

  &:after {
    position: absolute;
    top: -7px;
    right: 30px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid var(--be-small-card-bg);
    content: "";
    display: block;
    z-index: 2;
    transition: all 200ms linear;
    .up & {
      display: none;
    }
  }
}
</style>
