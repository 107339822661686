<script>
import SimplePage from "./SimplePage.vue";
import { brands } from "@/app/utils/brands";

export default {
  name: "MaintenanceView",
  components: { SimplePage },
  data() {
    const { footerLogo, name, address, title } = brands[import.meta.env.VITE_APP_BRAND];
    return {
      footerLogo,
      brandName: name,
      address,
      title,
    };
  },
};
</script>

<template>
  <SimplePage
    title="We will be back soon!"
    body="We are currently undergoing routine maintenance to better improve your experience."
    :suffix="`Regards, ${title} team.`"
  />
</template>
