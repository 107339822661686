import { computed, ref } from "vue";
import { useCurrencies } from "@/app/composables/useCurrencies";

const { currencies } = useCurrencies();
const user = ref();
const selectedWalletId = ref(null);

const wallets = computed(() => {
  const currencyIds = currencies.value
    .filter((currency) => currency.status)
    .map((currency) => currency.id);

  return user.value?.wallets.filter((wallet) => currencyIds.includes(wallet.currencyId)) || [];
});

const fundWallets = computed(() => {
  const currencyIds = currencies.value
    .filter((currency) => currency.status)
    .map((currency) => currency.id);

  return (
    user.value?.wallets.filter((wallet) => {
      return wallet.type === "funds" && currencyIds.includes(wallet.currencyId);
    }) || []
  );
});

const activeWallet = computed(() => {
  const walletId = Number.parseInt(selectedWalletId.value);

  if (walletId) {
    return fundWallets.value.find(wallet => wallet.id === walletId);
  }

  return fundWallets.value[0];
});

const bonusWallet = computed(() => {
  return wallets.value.find((wallet) => {
    return wallet.type === "bonus" && wallet.data?.fundsWalletId === activeWallet.value.id;
  });
});

const isFiat = computed(() => {
  return activeWallet.value.currency.type === "fiat";
});

export function useUserService() {
  function setUser(userAccount) {
    (userAccount?.wallets || []).forEach((wallet) => {
      wallet.currency = currencies.value.find((c) => c.id === wallet.currencyId);
    });
    user.value = userAccount;
  }
  function isAuthenticated() {
    return !!user.value;
  }

  function selectWallet(walletId) {
    selectedWalletId.value = walletId || null;
  }

  return {
    isAuthenticated,
    setUser,
    selectWallet,
    activeWallet,
    bonusWallet,
    wallets,
    fundWallets,
    isFiat,
    user,
  };
}
