import { jsonRpc } from "@/services/json-rpc/jsonrpc";

const sportsdata = {
  async events(params) {
    return jsonRpc(
      {
        method: "sports.events:list",
        params,
      },
      { uri: `sports.events:${params?.sportId ?? ""}:list` },
    );
  },

  async sports(params) {
    return jsonRpc({
      method: "sports.sports:list",
      params,
    });
  },

  async countries(params) {
    return jsonRpc({
      method: "sports.countries:list",
      params,
    });
  },

  async fetchCompetition(params) {
    return jsonRpc({
      method: "sports.competitions:show",
      params,
    });
  },
};

export default sportsdata;
