<script setup>
import { ref } from "vue";
import { api } from "@/services";
import BaseButton from "@/app/components/ui/BaseButton.vue";
import BaseTextField from "@/app/components/ui/BaseTextField.vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";

// import { toast } from "@/app/utils/notification";
import { useI18n } from "@/app/composables/useI18n";

const { t, locale } = useI18n();

const emailSent = ref(false);
const email = ref("");

async function sendEmail() {
  await api.users.forgotPassword({ email: email.value, locale: locale.value.code });
  emailSent.value = true;
}
</script>

<template>
  <div class="forgot-password-wrapper">
    <h2>
      {{ t("account.forgot_password") }}
    </h2>
    <div v-if="emailSent" class="email-sent">
      {{ t("account.forgot_password_email_sent") }}
      <BaseIcon name="email-check-outline" class="letter" size="80" />
    </div>
    <div v-else>
      <div>{{ t("account.forgot_password_alert") }}</div>
      <BaseTextField
        v-model="email"
        type="email"
        :label="t('account.type_email')"
        class="email-input"
      />
      <BaseButton class="check-email-button alight wide high" @click="sendEmail()">
        {{ t("account.check_email") }}
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.forgot-password-wrapper {
  .email-sent {
    .letter {
      margin: 1em auto;
    }
  }
  .email-input {
    margin: 2em 0;
    .check-email-button {
      margin-top: 2em;
    }
  }
}
</style>
