<script setup>
import { useI18n } from "@/app/composables/useI18n";
import {
  capitalizeHyphenatedString,
  matchStatuses,
  convertToTimeFormat,
} from "../../../../../sportsbook/utils/general";
import { useStopwatch, useTimer } from "vue-timer-hook";
import { useTimeServer } from "@/app/composables/useTimeServer";
import moment from "moment";
import { convertToLocalTime } from "@/sportsbook/utils/general";

const { wager } = defineProps({
  wager: {
    type: Object,
    required: true,
  },
  response: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();

const autoStart = true;
const { timeServer } = useTimeServer();
const currentTime = moment(timeServer.value);
const givenTime = moment(wager?.clock?.refPoint);

function durationToSeconds(duration) {
  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;

  const { years, months, days, hours, minutes, seconds, milliseconds } = duration;

  const totalSeconds =
    seconds +
    minutes * secondsInMinute +
    hours * secondsInHour +
    days * secondsInDay +
    milliseconds / 1000;

  return totalSeconds;
}

const difference = moment.duration(currentTime.diff(givenTime));
const stopwatch = useStopwatch(Math.floor(durationToSeconds(difference._data)), autoStart);

const time = new Date();
time.setSeconds(time.getSeconds() + Math.floor(durationToSeconds(difference._data))); // 10 minutes timer
const timer = useTimer(time);
</script>

<template>
  <div class="selections">
    <div class="flex">
      <div class="label-stake">
        {{ t("bets.selection") }}
      </div>
      <div v-if="response">
        {{ wager.eventName }}
      </div>
      <div v-else>
        {{ wager.header }}
        {{ wager?.outcomeName }}
      </div>
    </div>
    <div
      v-if="wager?.status != 'open' || response"
      :class="`wagerstatus ${
        wager?.status == 'open'
          ? 'status--open'
          : wager?.status == 'won'
          ? 'stake-no--win'
          : wager?.status == 'void'
          ? 'status--void'
          : 'stake-no--lose'
      }`"
    >
      {{ wager?.status }}
    </div>
    <div v-else class="time">
      <span>
        {{
          wager.sport == "Football" && wager?.clock?.isRunning
            ? convertToTimeFormat(
                `${stopwatch.minutes.value + stopwatch.hours.value * 60}:${
                  stopwatch.seconds.value
                } `
              )
            : (wager.sport == "Basketball" || wager == "Ice Hockey") && wager?.clock?.isRunning
            ? convertToTimeFormat(
                `${timer.minutes.value + timer.hours.value * 60}:${timer.seconds.value} `
              )
            : null
        }}
      </span>
      <span class="stake-event">
        {{ matchStatuses?.[capitalizeHyphenatedString(wager.sport)]?.[wager?.eventState] }}</span
      >
      <span>
        <span
          class="score"
          v-if="
            wager?.sport == 'Basketball' ||
            wager?.sport == 'Ice Hockey' ||
            wager?.sport == 'Football'
          "
        >
          {{ wager?.score?.home }}
          {{ wager?.score?.away || wager?.score?.away == 0 ? "-" : "" }}
          {{ wager?.score?.away }}
        </span>
        <span class="score" v-else>
          <span>
            <span class="current">{{ wager?.score?.home }} </span>
            <span v-if="wager?.sport == 'tennis'" class="current">{{
              wager?.score?.currentSet?.home
            }}</span>
            <span>{{ wager?.score?.currentGame?.home }}</span>
          </span>
          <span>
            <span class="current">{{ wager?.score?.away }} </span>
            <span class="current" v-if="wager?.sport == 'tennis'">{{
              wager?.score?.currentSet?.away
            }}</span>
            <span>{{ wager?.score?.currentGame?.away }}</span>
          </span>
        </span>
      </span>
    </div>
  </div>
  <div class="flex">
    <div class="label-stake">
      {{ t("bets.market") }}
    </div>
    <div>{{ wager.marketName }}</div>
  </div>
  <div class="flex">
    <div class="label-stake">
      {{ t("bets.odds") }}
    </div>

    <div>{{ wager?.odds ? wager?.odds : null }}</div>
  </div>
  <div class="flex">
    <div class="label-stake">
      {{ t("bets.event") }}
    </div>
    <div>{{ wager?.eventName }}, {{ convertToLocalTime(wager?.data?.eventStartDate) }}</div>
  </div>
</template>

<style lang="scss" scoped>
.selections {
  display: flex;
  justify-content: space-between;
}

.status--open {
  color: #ed8730;
}

.time {
  color: #ff453a;
}

.stake-event {
  margin: 0 10px 0 5px;
}

.label-stake {
  color: rgba(255, 255, 255, 0.5);
  font-family: Poppins-reg;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-right: 8px;
}

@media screen and (max-width: 500px) {
  .label-stake {
    font-size: 12px;
  }
}
.flex {
  display: flex;
}

@media screen and (max-width: 500px) {
  .flex {
    font-size: 12px;
  }
}
.wagerstatus {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width: 500px) {
  .wagerstatus {
    font-size: 12px;
  }
}

.stake-no--lose {
  color: #d80027;
}

.stake-no--win {
  color: #07863a;
}
</style>
