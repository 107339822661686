import { createApp } from "vue";
import { createPinia } from "pinia";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { createGtm } from "@gtm-support/vue-gtm";
import { createMetaManager } from "vue-meta";
import { FingerprintJSPro, fpjsPlugin } from "@fingerprintjs/fingerprintjs-pro-vue-v3";
import vuetify from "../plugins/vuetify";
import { router } from "./app/router";
import { api } from "./services";
import { loadIcons } from "./app/utils/icons";
import { useI18n } from "./app/composables/useI18n";
import "@/assets/scss/main.css";
import "@/assets/scss/layout.scss";
import "@/brands/common/css/main.scss";
import App from "./App.vue";
import "vuetify/dist/vuetify.min.css";

import { useAuth } from "@/user-account/composables/useAuth";
import { initPingService } from "@/app/utils/network";
import { useBetslip } from "@/sportsbook/composables/useBetslip.js";
import { useEventSource } from "@/sportsbook/composables/useEventSource";

const { initAuth } = useAuth();
const { initI18n } = useI18n();
const { initializeWorker } = useEventSource();

const app = createApp(App);
app.use(createPinia());
api.init();
loadIcons();

await Promise.all([
  initI18n(),
  initAuth(),
  initPingService(),
  useBetslip().load(),
  initializeWorker(),
]);

app.use(router);

app.use(vuetify);

app.use(fpjsPlugin, {
  loadOptions: {
    apiKey: import.meta.env.VITE_APP_FINGERPRINT_API_KEY,
    endpoint: [
      // "https://metrics.yourwebsite.com",
      FingerprintJSPro.defaultEndpoint,
    ],
    scriptUrlPattern: [
      // "https://metrics.yourwebsite.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
      FingerprintJSPro.defaultScriptUrlPattern,
    ],
  },
});

app.use(createMetaManager());

app.use(
  createGtm({
    id: "GTM-WTL9LMCC",
    vueRouter: router,
  }),
);

app.component(VueCountdown.name, VueCountdown);

await router.isReady();

app.mount("#app");
