<script setup>
import { computed, ref, watch } from "vue";
import { api } from "@/services";

// import Pagination from "@/components/ui/Pagination.vue";
import BaseButton from "@/app/components/ui/BaseButton.vue";

// import { cfl } from "@/app/utils/strings";
import ProgressLoader from "@/app/components/ui/ProgressLoader.vue";
import WalletSelect from "@/user-account/components/WalletSelect.vue";

import { useUserService } from "@/user-account/composables/useUserService.ts";
import { useI18n } from "@/app/composables/useI18n";

const { t } = useI18n();

const { activeWallet, wallets, isFiat } = useUserService();

const transactions = ref([]);
const loading = ref(false);
const transactionType = ref("All");
const tr = {
  All: "finance.transactions_all",
  Deposits: "finance.transactions_deposits",
  Withdrawals: "finance.transactions_withdrawals",
};

// computed: {
//   ...mapGetters(`user`, ["wallets"]),
//   ...mapState(`user`, { wallet: "activeWallet" }),
const filteredTransactions = computed(() => {
  // if (!activeWallet.value || !this.transactions) {
  //   return [];
  // }
  return transactions.value.filter((tx) => {
    return (
      tx.walletId === activeWallet.value.id
      && (transactionType.value === "All" || `${tx.type}s` === transactionType.value.toLowerCase())
      // this.typeMapping[tx.type] === this.transactionType)
    );
  });
});

watch(activeWallet, () => fetchTransactions(), { immediate: true });
// : {
//   async getactiveWallet.value {
//     if (this.page === 1) {
//       await this.fetchTransactions(); // call every user account update
//     }
//   },
// },
// async created() {
//   await this.fetchTransactions();
// },

async function fetchTransactions() {
  // loading.value = true;
  transactions.value = await api.users.listTransactions();
  // loading.value = false;
}

function desc(tx) {
  if (isFiat.value) {
    const provider = tx.extraData.serviceProvider?.name ?? "";
    const desc = [];
    if (provider) {
      desc.push(`${t("finance.provider")}: ${provider}`);
    }
    desc.push(`${t("finance.description")}: ${tx.desc}`);
    return desc;
  } else {
    if (tx.type === "adjustment") {
      return [`${t("finance.description")}: ${tx.desc}`];
    // } else if (tx.type === "deposit") {
    //   return "Tx Id: " + tx.extraData?.serviceProviderData.txid;
    } else if (tx.type === "withdrawal" || tx.type === "deposit") {
      const tt = tx.extraData?.serviceProvider?.confirmation?.transactions[0] ?? null;
      return [
      `Tx Id: <a target='_blank' href='https://www.blockchain.com/explorer/search?search=${tt?.txid}'>${tt?.txid}</a>`,
      `${t(
        "finance.address",
      )}: <a target='_blank' href='https://www.blockchain.com/explorer/search?search=${
        tt?.address
      }'>${tt?.address}</a>`,
      ];
    }
  }
}
</script>

<template>
  <div class="all-tx-view">
    <ProgressLoader :loading="loading" />

    <div class="tx-type-switch">
      <BaseButton
        v-for="type in ['All', 'Deposits', 'Withdrawals']"
        :key="type"
        class="tx-type-button alight"
        :class="{ active: transactionType === type }"
        @click="transactionType = type"
      >
        {{ t(tr[type]) }}
      </BaseButton>
    </div>

    <div class="transactions">
      <h3>
        {{ t("finance.select_currency") }}
      </h3>
      <WalletSelect class="wallet-select center" :wallets="wallets" mode="currency" />

      <div class="title">
        {{ activeWallet.currency.ticker }}
        {{ t("finance.transactions") }}
      </div>
      <div v-for="tx in filteredTransactions" :key="tx.id" class="transaction">
        <div class="header">
          <span>#{{ tx.id }} | {{ t(`finance.${tx.type}`) }}</span>
          <span>{{ tx.createdAt }}</span>
        </div>
        <div class="amount">
          {{ t("finance.amount") }}: {{ tx.amount }}
        </div>
        <div v-for="(txt, i) in desc(tx)" :key="i" class="desc" v-html="txt" />

        <div class="status">
          {{ t("finance.status") }}: {{ t(`finance.${tx.status}`) }}
        </div>
      </div>

      <!-- <Pagination
      :value="page"
      :length="lastPage"
      :total-visible="4"
      class="mt-3"
      @input="onPageChange"
    /> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.all-tx-view {
  margin: 2em 0em 4em;
  .tx-type-switch {
    display: flex;
    margin-top: 15px;
    .mobile & {
      margin-top: 0;
      flex-direction: column;
    }
    // @media screen and (max-width: 380px) {
    //   & {
    //     margin-top: 0;
    //     flex-direction: column;
    //   }
    // }
    .tx-type-button {
      min-width: unset !important;
      flex: 1 0 25%;
      margin: 5px;
      &.selected {
        border: 1px solid white;
      }
    }
  }
  .wallet-select {
    width: 100%;
  }
  .transactions {
    // margin-top: 15px;
    .title {
      margin: 20px 0;
    }
    .transaction {
      padding: 5px 10px;
      margin: 10px;
      border: 1px solid #ccc;
      border-radius: 3px;

      .header {
        display: flex;
        justify-content: space-between;
        color: #ddd;
        margin-bottom: 10px;
      }

      .desc {
        overflow-x: clip;
      }

      .status {
        // font-size: 0.9em;
        margin-top: 10px;
        color: #aaa;
      }
    }
  }
}
</style>
