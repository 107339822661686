<script setup>
import { computed, ref } from "vue";
import GameCard from "./GameCard.vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import "@splidejs/vue-splide/css";
import { useI18n } from "@/app/composables/useI18n";
import { router } from "@/app/router";
import { useCasinoService } from "@/casino/composables/useCasinoService.js";
import VueHorizontal from "vue-horizontal";

const props = defineProps({
  category: {
    type: Object,
    default: null,
  },
});

const { t } = useI18n();

const spliderOptions = {
  perPage: 10,
  pagination: false,
  breakpoints: {
    1376: {
      perPage: 9,
    },
    1230: {
      perPage: 8,
    },
    1114: {
      perPage: 7,
    },
    1000: {
      perPage: 6,
    },
    600: {
      perPage: 3,
    },
    882: {
      perPage: 5,
    },
  },
};

const horizontal = ref(null);

const prevSlide = (event) => {
  if (horizontal.value && typeof horizontal.value.prev === "function") {
    horizontal.value.prev(event);
  } else {
    console.error("Horizontal reference or next method is not defined");
  }
};

const nextSlide = (event) => {
  if (horizontal.value && typeof horizontal.value.next === "function") {
    horizontal.value.next(event);
  } else {
    console.error("Horizontal reference or next method is not defined");
  }
};

const casinoService = useCasinoService();

const categoryGames = computed(() => {
  return (casinoService.games.value || []).filter((game) => props.category.id === game.category.id);
});
function handleViewAll(slug) {
  router.push({ path: `/casino/${slug}` });
}
</script>

<template>
  <section v-if="categoryGames.length > 0" class="list-wrapper">
    <div class="list-header">
      <router-link
        :to="{ name: 'casino.category', params: { category: category.slug } }"
        class="list-title"
      >
        <BaseIcon :name="category.slug" color="var(--be-disable-state)" />
        <span> {{ t(`casino.${category.slug}`) }}</span>
      </router-link>
      <div class="list-view" @click="() => handleViewAll(category.slug)">
        <div>{{ t("general.view-all") }}</div>
        <div @click="(event) => prevSlide(event)" :class="horizontal?.hasPrev ? '' : 'dim'">
          <img src="/assets/images/left-arrow-base-sliding.svg" alt="" />
        </div>
        <div @click="(event) => nextSlide(event)" :class="horizontal?.hasNext ? '' : 'dim'">
          <img src="/assets/images/right-arrow-base-sliding.svg" alt="" />
        </div>
      </div>
    </div>
    <!-- <BaseSlider :slides="categoryGames" :splider-options="spliderOptions"> -->
    <VueHorizontal ref="horizontal" :button="false">
      <div v-for="slide in categoryGames">
        <div class="game-wrapper">
          <GameCard :game="slide" />
        </div>
      </div>
    </VueHorizontal>
    <!-- </BaseSlider> -->
  </section>
</template>

<style lang="scss" scoped>
@import "@/casino/assets/scss/casino-styles.scss";

.dim {
  opacity: 0.3;
}

.list-view {
  display: flex;
  gap: 0 20px;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  color: var(--be-primary-btn);
  font-family: Rubik;
}

.list-header {
  display: flex;
  justify-content: space-between;
  margin-right: 0px;
  margin-bottom: 10px;
}

.list-wrapper {
  padding: 16px 16px;
  margin: 10px 0px;
  background-color: var(--be-big-card-bg);
  border-radius: 8px;

  .list-title {
    display: flex;
    align-items: center;
    margin: 0 0 4px 2px;

    img {
      max-width: 24px;
      height: 24px;
    }

    span {
      margin: 4px 0 0 8px;
    }
  }

  .game-wrapper {
    display: flex;
    height: 100%;
    margin-right: 8px;
  }
}

@media screen and (max-width: 550px) {
  .list-wrapper {
    background: transparent;
  }
}
</style>
