<script setup>
import { ref, watch } from "vue";
import BaseToggle from "@/app/components/ui/BaseToggle.vue";
import { t } from "@/app/composables/useI18n";
import { api } from "@/services";
import WalletSelect from "@/user-account/components/WalletSelect.vue";
import { useUserService } from "@/user-account/composables/useUserService.ts";

const { user, activeWallet } = useUserService();

const hideWalletsWithZeroBalances = ref(user.value.data.preferences.hideWalletsWithZeroBalances);

watch(hideWalletsWithZeroBalances, () => {
  api.users.updatePreferences({
    name: "hideWalletsWithZeroBalances",
    value: hideWalletsWithZeroBalances.value,
  });
});

watch(activeWallet, (a, b) => {
  if (a.id !== b.id) {
    // console.log(JSON.stringify(b));
    // console.log(activeWallet.value.currency.ticker);
    api.users.updatePreferences({
      name: "primaryCurrencyId",
      value: activeWallet.value.currency.id,
    });
  }
});
</script>

<template>
  <div class="preferences-wrapper">
    <h2>
      {{ t("account.preferences") }}
    </h2>

    <div class="content">
      <h3>Select you preferred currency</h3>
      <p>Used for displaying Total and current exchange rates</p>

      <WalletSelect class="wallet-select" />
    </div>
    <!-- <h2>
      Show crypto wallets with 0 balance
    </h2> -->

    <div class="setting-row">
      <h4>Hide crypto wallets with 0 balance</h4>

      <div class="toggle">
        <span>No</span>
        <BaseToggle v-model="hideWalletsWithZeroBalances" />
        <span>Yes</span>
      </div>
    </div>
    <!-- <div class="content">
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.content {
  margin-top: 2em;
  p {
    margin-bottom: 2em;
  }
  .setting-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2em;
    padding: 1em;
    .toggle {
      display: flex;
      align-items: center;
      span {
        margin: 0 1em;
      }
    }
  }
}
</style>
