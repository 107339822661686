<script setup>
import { onMounted, ref, watch } from "vue";
import { toast } from "@/app/utils/notification";
import BaseTextField from "@/app/components/ui/BaseTextField.vue";
import { t } from "@/app/composables/useI18n";
import { api } from "@/services";
import { useCurrencies } from "@/app/composables/useCurrencies";

const props = defineProps({
  currencyId: {
    type: Number,
    required: true,
  },
  providerId: {
    type: Number,
    required: true,
  },
});

const { getIconByTicker } = useCurrencies();

const wallets = ref([]);
const withdrawalWallet = ref({
  balance: 0,
  currency: {
    sign: "",
  },
});
const withdrawalFormData = ref({});
const loading = ref(false);
const cryptoAddress = ref("");
const amount = ref(0);

async function loadWithdrawalFormData() {
  withdrawalFormData.value = await api.finance.withdrawalFormData({
    providerId: props.providerId,
    currencyId: props.currencyId,
  });
}

async function loadUserWallets() {
  // Load wallets
  wallets.value = await api.finance.userWallets();

  // Search withdrawal wallet
  if (wallets.value.length > 0) {
    const filteredWallets = wallets.value.filter((wallet) => {
      return Number.parseInt(wallet.currency.id) === props.currencyId && wallet.type === "funds";
    });
    if (filteredWallets.length > 0) {
      withdrawalWallet.value = filteredWallets[0];
    }
  }
}

async function withdraw() {
  loading.value = true;
  const result = await api.finance.withdraw({
    providerId: props.providerId,
    currencyId: props.currencyId,
    cryptoAddress: cryptoAddress.value,
    amount: amount.value,
  });
  loading.value = false;

  if (result?.status === "success") {
    toast.info(t("finance.withdrawal_processing"));
  } else {
    toast.error(t("errors.withdrawal_error"));
  }
}

watch(
  () => props.providerId,
  (value) => {
    loading.value = true;
    loadWithdrawalFormData();
    loadUserWallets();
    loading.value = false;
  }
);

watch(
  () => props.currencyId,
  (value) => {
    loading.value = true;
    loadWithdrawalFormData();
    loadUserWallets();
    loading.value = false;
  }
);

onMounted(() => {
  loading.value = true;
  loadWithdrawalFormData();
  loadUserWallets();
  loading.value = false;
});
</script>

<template>
  <div>
    <div>
      <div class="label">
        {{ t("finance.deposit_address_withdrawal") }}
      </div>
      <BaseTextField v-model="cryptoAddress" />
    </div>

    <div class="amount-container">
      <div class="amount-title">
        <div>{{ t("finance.amount") }}</div>
        <div class="digits">
          <img class="img-icon" :src="getIconByTicker(withdrawalWallet?.currency?.ticker)" alt="" />
          {{ withdrawalWallet.balance }}
        </div>
      </div>
      <div class="amount">
        <input v-model="amount" type="number" class="amount-input" />
        <button class="max-btn" @click="amount = withdrawalWallet.balance">MAX</button>
      </div>
      <div class="amount-title top-space">
        <div>{{ t("finance.min_withdrawal") }}</div>
        <div class="digits">
          <img class="img-icon" :src="getIconByTicker(withdrawalWallet?.currency?.ticker)" alt="" />
          {{ withdrawalFormData.min_amount }}
        </div>
      </div>
      <div class="amount-title top-space">
        <div>{{ t("finance.fee") }}</div>
        <div class="digits">
          <img class="img-icon" :src="getIconByTicker(withdrawalWallet?.currency?.ticker)" alt="" />
          {{ withdrawalFormData.fee_percent * 100 }}%
        </div>
      </div>
    </div>

    <button class="btn-g" :disabled="loading" @click="withdraw()">
      <span v-if="loading">{{ t("finance.loading") }}</span>
      <span v-else>{{ t("finance.withdraw") }}</span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.img-icon {
  width: 16px;
  height: 16px;
  float: right;
}

.amount-container {
  margin: 10px 0px;
}

.top-space {
  margin-top: 10px;
}

.label {
  margin-bottom: 7px;
  font-size: 14px;
}
.amount {
  border-radius: 4px;
  position: relative;
  display: flex;
  width: 100%;
  .amount-icon {
    position: absolute;
    top: 50%;
    transform: translate(8px, -50%);
  }

  .amount-input {
    border: none;
    background: var(--be-small-card-bg);
    height: 40px;
    border-radius: 4px 0 0 4px;
    outline: none;
    color: white;
    text-indent: 10px;
    width: 100%;
  }
}

.amount-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;

  .digits {
    color: rgba(255, 255, 255, 0.5);
  }
}

.max-btn {
  height: 40px;
  width: 50px;
  background: var(--Linear, linear-gradient(90deg, #3886ec 0%, #544fe5 100%));
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  border-radius: 0 4px 4px 0;
}

.btn-g {
  color: var(--be-primary-btn-label);
  border: none;
  height: 42px;
  width: 100%;
  border-radius: 4px;
  background: var(--Linear, linear-gradient(90deg, #3886ec 0%, #544fe5 100%));
  margin: 20px 0px;
  font-size: 14px;
}

.withdraw-desc {
  font-family: Poppins;
  font-size: 12px;
}
</style>
