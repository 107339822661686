<script setup>
import BaseIcon from "@/app/components/ui/BaseIcon.vue";

const props = defineProps({
  game: {
    type: Object,
    default: null,
  },
});
</script>

<template>
  <picture>
    <img src="/assets/images/no_image_game.png" class="game-image" />
  </picture>
  <div class="game-main-wrapper">
    <div class="top" />
    <div class="center">
      <!-- <img src="/assets/images/no_image.png" width="30px" height="30px" /> -->
      <BaseIcon name="null" color="var(--be-secondary-text-color)" />
    </div>
    <div class="bottom">
      <div class="game-title">
        {{ props.game.name }}
      </div>
      <div class="game-provider">
        {{ props.game.provider.name }}
      </div>
    </div>
  </div>
  <div class="game-hover-wrapper">
    <div class="top" />
    <div class="center">
      <div class="play-button">PLAY</div>
    </div>
    <div class="bottom" />
  </div>
</template>

<style lang="scss" scoped>
.game-image {
  max-width: 100%;
  border-radius: 5px;
}

.game-hover-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.2s ease-in-out;

  .bottom {
    padding: 8px;
    flex: 1;
  }

  .center {
    padding: 8px;
    text-align: center;
    flex: 1;

    .play-button {
      display: inline;
      background-color: var(--be-primary-btn);
      border: 1px solid var(--be-primary-btn);
      color: var(--be-primary-btn-label);
      border-radius: 8px;
      font-size: 14px;
      font-family: Rubik-med;
      padding: 8px 15.4px;
      box-shadow: 0 0 0 0 rgba(72, 255, 145, 0.3);
      cursor: pointer;
    }
  }

  .top {
    padding: 8px;
    flex: 1;
  }

  &:hover {
    opacity: 1;
  }
}

.game-main-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  .bottom {
    padding: 0px 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .center {
    padding: 8px;
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .top {
    padding: 8px;
    flex: 1;
  }

  .game-title {
    font-family: Rubik;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    font-size: 14px;
    font-weight: 400;
  }

  .game-provider {
    font-family: Rubik;
    font-size: 12px;
    margin-top: 3px;
    color: var(--be-secondary-text-color);
  }

  &:hover {
    opacity: 1;
  }
}
</style>
