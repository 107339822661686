import { t } from "@/app/composables/useI18n";

const uppercaseRegex = /[A-Z]/;
const lowercaseRegex = /[a-z]/;
const numberRegex = /[0-9]/;
const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

export function minimum8Characters(password) {
  if (
    password &&
    password.length >= 8 &&
    password.length <= 28 &&
    uppercaseRegex.test(password) &&
    lowercaseRegex.test(password) &&
    numberRegex.test(password) &&
    specialCharRegex.test(password)
  ) {
    return true;
  }

  return "Password must be between 8 and 28 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.";
}

export const rules = [minimum8Characters];
