const BET_SLIP_KEY = "betslip-state";

const defaultState = {
  // mobileBetslipVisible: false,
  selections: [],
  multipleStake: "",
};

function loadBetslipFromLs() {
  if (localStorage) {
    const state = localStorage.getItem(BET_SLIP_KEY);
    if (state) {
      return JSON.parse(state);
    }
  }
  return defaultState;
}

function saveBetslipToLs(state) {
  if (localStorage && typeof state === "object") {
    localStorage.setItem(BET_SLIP_KEY, JSON.stringify(state));
  }
};

export { loadBetslipFromLs, saveBetslipToLs };
