import { type RouteRecordRaw } from "vue-router";
import OneComponentLayout from "@/app/layouts/OneComponentLayout.vue";
import LoginComponent from "@/user-account/components/auth/LoginComponent.vue";
import UserAccount from "@/user-account/components/UserAccount.vue";
import UserTransactionsView from "@/user-account/views/UserTransactionsView.vue";
import UserTransactionsDeposits from "@/user-account/components/transactions/UserTransactionsDeposits.vue";
import UserTransactionsWithdrawals from "@/user-account/components/transactions/UserTransactionsWithdrawals.vue";
import UserTransactionsAll from "@/user-account/components/transactions/UserTransactionsAll.vue";
import EditUserCredentials from "@/user-account/components/security/EditUserCredentials.vue";
import UserPreferences from "@/user-account/components/UserPreferences.vue";
import Manage2FA from "@/user-account/components/security/Manage2FA.vue";
import ForgotPassword from "@/user-account/components/security/ForgotPassword.vue";

export const accountRoutes: RouteRecordRaw = {
  path: "/account",
  // name: "account",
  component: OneComponentLayout,
  children: [
    {
      path: "",
      name: "account",
      component: UserAccount,
      meta: { requiresAuth: true, backRoute: "/" },
    },
    {
      path: "login",
      name: "login",
      component: LoginComponent,
      meta: { excludesAuth: true },
    },
    {
      path: "verification-expired",
      name: "verification-expired",
      component: LoginComponent,
      meta: { excludesAuth: true },
      props: {
        verificationEmailExpired: true,
      },
    },
    {
      path: "forgot-password",
      name: "forgot-password",
      component: ForgotPassword,

    },
    // {
    //   path: "reset-password/:username/:email/:token",
    //   name: "reset-password.email",
    //   component: ResetPassword,
    //   props: true,
    //
    // },
    // {
    //   path: "reset-password/:username/:email/",
    //   name: "reset-password.2FA",
    //   component: ResetPassword,
    //   props: true,
    //
    // },
    {
      path: "details",
      name: "details",
      component: EditUserCredentials,
      meta: { requiresAuth: true, backRoute: "account" },
    },
    {
      path: "2fa",
      name: "2fa",
      component: Manage2FA,
      meta: { requiresAuth: true, backRoute: "account" },
    },
    {
      path: "preferences",
      name: "preferences",
      component: UserPreferences,
      meta: { requiresAuth: true, backRoute: "account" },
    },
    {
      path: "finances",
      component: UserTransactionsView,
      name: "finances",

      meta: { requiresAuth: true },
      children: [
        {
          path: "deposit/:transactionId?",
          component: UserTransactionsDeposits,
          name: "user-deposits",
          meta: { requiresAuth: true, backRoute: "account" },
        },
        {
          path: "withdraw",
          component: UserTransactionsWithdrawals,
          name: "user-withdrawals",
          meta: { requiresAuth: true, backRoute: "account" },
        },
        {
          path: "transactions",
          component: UserTransactionsAll,
          name: "user-transactions",
          meta: { requiresAuth: true, backRoute: "account" },
        },
      ],
    },

    // {
    //   path: "/blocked",
    //   name: "blocked",
    //   component: Blocked,
    // }
  ],
};
