import http from "@/services/json-rpc/http";
import { jsonRpc } from "@/services/json-rpc/jsonrpc";

const users = {
  async tickets(params) {
    return await jsonRpc({
      method: "users.tickets:list",
      params,
    });
  },
  async casinoTickets(params) {
    return await jsonRpc({
      method: "casino.bets:list",
      params,
    });
  },
  async placeBet(params) {
    return await jsonRpc({
      method: "users.tickets:place",
      params,
    });
  },
  async countries(params) {
    return await jsonRpc({
      method: "utils.countries:list",
      params,
    });
  },
  async getPersonalData() {
    return await jsonRpc({
      method: "users.kyc:getPersonalData",
    });
  },
  async storePersonalData(params) {
    return await jsonRpc({
      method: "users.kyc:storePersonalData",
      params,
    });
  },

  async setEmail(params) {
    return await jsonRpc({
      method: "auth:setEmail",
      params,
    });
  },

  async storeDocuments(params) {
    const data = await http.post("users/storeDocuments", params);
    return data;
  },
  async updatePreferences(params) {
    return await jsonRpc({
      method: "users.preferences:update",
      params,
    });
  },
  async listTransactions() {
    // return await jsonRpc("users.transactions:list");
    return await jsonRpc({
      method: "users.transactions:list",
    });
  },

  async updateDetails(params) {
    // return await jsonRpc("users.withdrawal:create", params);
    return await jsonRpc({
      method: "users.details:update",
      params,
    });
  },
  async forgotPassword(params) {
    return await jsonRpc({
      method: "users.forgotPassword:sendEmail",
      params,
    });
  },
  async createWithdrawal(params) {
    return await jsonRpc({
      method: "finance.withdrawal:create",
      params,
    });
  },
  async createDeposit(params) {
    return await jsonRpc({
      method: "finance.deposit:create",
      params,
    });
  },
  async getCurrentUser() {
    return await jsonRpc({
      method: "users.current:get",
    });
  },
  async enableSelfExclusion(period) {
    return await jsonRpc({
      method: "users.self-exclusion:enable",
      params: { period },
    });
  },
};

export default users;
