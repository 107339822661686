<script setup>
import { onMounted, ref, watch } from "vue";
import UpcomingEventsSportSelector from "./UpcomingEventsSportSelector.vue";
import SportCoupons from "@/sportsbook/components/SportCoupons.vue";
import ProgressLoader from "@/app/components/ui/ProgressLoader.vue";
import { useBetStore } from "../../../../store";
// import { api } from "@/services";
// import { useRoute } from "@/router";
import { useSportsdata } from "@/sportsbook/composables/useSportsdata.js";

// const route = useRoute();

const props = defineProps({
  timeFrame: {
    type: String,
    // default: "today",
  },
  type: {
    type: String,
  },
});

const store = useBetStore();

const { fetchSportsEvents } = useSportsdata();

const sport = ref();
const sports = ref([]);
// const filters = ref({});
const loading = ref(false);

// load sport list with events
async function loadSports() {
  loading.value = true;
  sports.value = await fetchSportsEvents({ timeFrame: props.timeFrame, type: props.type });
  // if (sports.value.length) {
  // display first sport

  sport.value = Object.values(sports.value)[0] ?? null;
  store.setSport(Object.values(sports.value)[0]);
  // }
  loading.value = false;
}

function selectSport(selectedSport) {
  sport.value = selectedSport;
  store.setSport(sport);
}

onMounted(async () => {
  loadSports();
});

watch(
  () => props.timeFrame,
  () => {
    loadSports();
  }
);
</script>

<template>
  <div>
    <ProgressLoader :loading="loading" />

    <UpcomingEventsSportSelector
      v-if="sports.length != 0"
      :sports="Object.values(sports)"
      @select-sport="selectSport($event)"
    />

    <SportCoupons v-if="sport" :sport="sport" :load-tournaments="false" :type="props.timeFrame" />
  </div>
</template>
