<script setup>
import { computed, ref } from "vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { toast } from "@/app/utils/notification";
import { t } from "@/app/composables/useI18n";

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  iconName: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  clearable: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  rules: {
    type: Array,
    default: () => [],
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["error"]);

const modelValue = defineModel();

const type = ref(props.type);
const iconName = ref(props.iconName);
const uuid = Math.random(1000);

function onBlur(event) {
  props.rules.forEach((rule) => {
    const result = rule(event.target.value);
    emit("error", result === true ? "" : result);
  });
}

const notEmpty = computed(() => {
  return props.modelValue != null && props.modelValue.toString().length > 0;
});

function handleIcon() {
  if (props.iconName) {
    if (iconName.value === "eye-opened") {
      type.value = "text";
      iconName.value = "eye-closed";
    } else if (iconName.value === "copy") {
      copy();
    } else {
      type.value = "password";
      iconName.value = "eye-opened";
    }
  } else if (notEmpty.value && props.clearable) {
    modelValue.value = "";
  }
}

function copy() {
  const copyText = document.getElementById(uuid);
  copyText.select();
  copyText.setSelectionRange(0, 99999);
  navigator.clipboard.writeText(copyText.value);
  toast.info(t("common.copied_to_clipboard"));
}
</script>

<template>
  <span class="input-element">
    <span v-if="props.label" class="label">{{ props.label }}</span>
    <input
      :id="uuid"
      v-model="modelValue"
      :type="type"
      :readonly="props.readonly"
      :placeholder="props.placeholder"
      :disabled="props.disabled"
      :class="props.clearable || props.iconName ? 'with-icon' : ''"
      @blur="onBlur"
    />

    <div
      v-if="(notEmpty && props.clearable) || props.iconName"
      class="suffix-icon"
      :style="{ top: props.label ? '39px' : '12px' }"
      @click="handleIcon"
    >
      <BaseIcon :name="iconName ? iconName : 'close'" />
    </div>
    <small v-if="props.error" class="error">{{ props.error }}</small>
  </span>
</template>

<style lang="scss" scoped>
.input-element {
  display: flex;
  flex-direction: column;
  position: relative;
  .label {
    margin-bottom: 10px;
    font-size: 14px;
  }

  input {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    transition: all 0.2s;
    appearance: none;
    color: var(--be-primary-text-color);
    background-color: var(--be-small-card-bg);
    font-weight: 700;
    border: 2px solid transparent;
    border-radius: 2px;
    font-size: 12px;
    padding-left: 0.5rem;
    &[type="text"],
    &[type="password"] {
      background-color: var(--be-small-card-bg);
    }

    ::placeholder {
      color: red;
      opacity: 1;
    }

    ::-ms-input-placeholder {
      color: red;
    }

    &:enabled:focus {
      box-shadow: none;
    }
    &:focus,
    &:active {
      outline: none;
      border-color: #ffe81a;
    }
  }

  .with-icon {
    padding-right: 30px;
  }

  .error {
    color: #ff5252;
    margin: 3px 0 0 4px;
    font-size: 11px;
    height: auto;
  }
  .suffix-icon {
    position: absolute;
    top: 35px;
    right: 5px;
    opacity: 0.6;
    transition: all 0.3s ease-in;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  color-scheme: dark;
  opacity: 0.5;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
