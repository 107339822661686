<script setup>
import { computed, ref } from "vue";
import { onlyNumbers } from "@/sportsbook/utils/stakeValidationRules";
import { useI18n } from "@/app/composables/useI18n";
import { useUserService } from "@/user-account/composables/useUserService";
import { useCurrencies } from "@/app/composables/useCurrencies";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "Stake",
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});
const { activeWallet } = useUserService();

const { t } = useI18n();
const { getIconByTicker } = useCurrencies();

const KEY_CODE_BACKSPACE = 8;
const KEY_CODE_ARROW_LEFT = 37;
const KEY_CODE_ARROW_RIGHT = 39;

const label = ref(t("bets.stake"));
const showLabel = ref(true);
const allowedKeys = [KEY_CODE_BACKSPACE, KEY_CODE_ARROW_LEFT, KEY_CODE_ARROW_RIGHT];

// defineModel
const internalValue = defineModel();

const stake = computed({
  get() {
    return internalValue.value;
  },
  set(value) {
    internalValue.value = value;
  },
});

function keyDown(e) {
  const value = e.target.value;
  if (typeof onlyNumbers(value) === "string" && !allowedKeys.includes(e.keyCode)) {
    e.preventDefault();
  } else if (allowedKeys.includes(e.keyCode)) {
    return;
  }

  if (!stake.value || typeof stake.value !== "string") {
    return;
  }

  if ((stake.value + e.key).length > 10) {
    e.preventDefault();
  }
}

function focus(e) {
  // prefix.value = true;
  showLabel.value = false;
}

function blur(e) {
  // prefix.value = true;
  showLabel.value = true;
}

function formatStake(value) {
  if (typeof value !== "string" || !value.includes(".")) {
    return value;
  }
  let stake = "";
  stake =
    value.substring(0, value.indexOf(".")) +
    value.substring(value.indexOf("."), value.indexOf(".") + 9);
  internalValue.value = stake;
}
</script>

<template>
  <div class="stake">
    <input
      v-model="internalValue"
      class="stake-input"
      type="number"
      :placeholder="props.placeholder"
      :disabled="props.disabled"
      :label="showLabel ? label : ''"
      @keydown="keyDown"
      @focus="focus"
      @blur="blur"
      @change="formatStake"
      :readonly="readonly"
    />
    <div class="stake-btc">
      <img
        v-if="getIconByTicker(activeWallet?.currency?.ticker) != ''"
        class="stake-currency"
        :src="getIconByTicker(activeWallet?.currency?.ticker)"
        alt=""
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
input[readonly] {
  cursor: pointer;
}
.stake {
  border-radius: 4px;
  background: var(--be-small-card-bg);
  width: 150px;
  //   height: 30px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  padding-left: 8px;
  .stake-btc {
    background: var(--be-divider);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 100%;
    border-radius: 0 4px 4px 0;

    .stake-currency {
      width: 16px;
    }
  }

  .stake-input {
    outline: none;
    width: 100%;
    color: white;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

@media screen and (max-width: 500px) {
  .stake {
    width: 100%;
  }
}
</style>
