import { ref } from "vue";
import { api } from "@/services";
import { toast } from "@/app/utils/notification";
import { useUserService } from "@/user-account/composables/useUserService.ts";
import { useAuth } from "@/user-account/composables/useAuth";
import { useGlobalLoader } from "@/app/composables/useGlobalLoader";

const { enableLoader } = useGlobalLoader();
const { isAuthenticated } = useAuth();
const { activeWallet } = useUserService();
const selectedCurrency = ref("USD");
const loading = ref(false);

const isGameSession = ref(false);

export function useGameHelper() {
  async function startGame(game, isDemo, handleLogin) {
    if (!isAuthenticated() && !isDemo) {
      handleLogin();
      return;
    }
    enableLoader(3000);
    isGameSession.value = true;
    loading.value = true;

    const data = await api.virtualGames.gameInit({
      gameId: game.value.id,
      walletId: activeWallet.value?.id,
      isDemo,
      cur: selectedCurrency.value || "USD",
    });

    if (data.redirectUrl === null) {
      toast.warn(`This game can not be played${isDemo ? " in demo mode" : ""}`);
    } else {
      game.value.redirectUrl = data.redirectUrl;
    }

    loading.value = false;
  }

  return {
    startGame,
    isGameSession,
    selectedCurrency,
  };
}
