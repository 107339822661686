<script setup>
import { useBetslip } from "@/sportsbook/composables/useBetslip.js";

const { mobileBetslipVisible, multipleBet, selections } = useBetslip();

function open() {
  const element = document.querySelector(`.brand-${import.meta.env.VITE_APP_BRAND}`);
  if (selections.value.length > 0) {
    mobileBetslipVisible.value = !mobileBetslipVisible.value;

    element.style.overflow = "hidden";
  }
}
</script>

<template>
  <div class="betslip-toggle" v-if="selections.length > 0" @click="open()">
    <span class="event-count">{{ selections.length }}</span>
  </div>
</template>

<style lang="scss" scoped>
.betslip-toggle {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  bottom: 32px;
  height: 50px;
  width: 50px;
  font-family: Source Sans Pro, serif;
  background: var(--be-primary-btn);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.2);
  padding-top: 4px;
  margin: 0 0;
  border-radius: 50%;
  // min-width: 42px;
  .amount {
    color: var(--White, #fff);
    text-align: center;

    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 4px;
  }
  .event-count {
    color: var(--be-primary-btn-label);
    text-align: center;
    font-family: Poppins;
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
</style>
