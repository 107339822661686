// const debounceWithArgs = (fn, delay) => {
function debounce(fn, delay) {
  let timeout;

  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export { debounce };
// export {debounce, debounceWithArgs};
