<script setup>
import { ref, watch, computed } from "vue";
import { useI18n } from "@/app/composables/useI18n";
import BaseButton from "@/app/components/ui/BaseButton.vue";
import BaseTextField from "@/app/components/ui/BaseTextField.vue";
import { rules as emailRules } from "@/user-account/utils/input-rules/email";
import { rules as phoneRules } from "@/user-account/utils/input-rules/phone";
import { camelToSnakeCaseWithNumbers } from "@/app/utils/strings";
import { useConfig } from "@/app/composables/useConfig";
import BasePhoneField from "@/app/components/ui/BasePhoneField.vue";
import { api } from "@/services";

const props = defineProps({
  regions: {
    type: Array,
    required: true,
  },
  personalData: {
    type: Object,
    default: () => ({}),
  },
});

const countries = ref([]);

const { registrationFlow } = useConfig();

const emit = defineEmits(["submit"]);

const { t } = useI18n();

const displayOrders = [
  "firstName",
  "lastName", 
  "dateOfBirth",
  "city",
  "addressLine1",
  "addressLine2",
  "postalCode"
];

const inputs = ref([
  {
    name: "",
    value: "",
    type: "",
    label: "",
    placeholder: "",
    rules: null,
    clearable: false,
    error: "",
  },
  {
    name: "country",
    value: "",
    type: "text",
  }
]);

const countryCodes = computed(() => {
  return countries.value.data?.filter((country) => country.status).map((country) => country.code);
});

async function fetchCountries() {
  countries.value = await api.utils.listCountries({
    paginator: {
      cp: 1,
      rpp: 1000,
    },
  });
}

watch(
  () => [registrationFlow.value.personal_info_data,registrationFlow.value.allow_personal_info],
  ([data,allow]) => {
    if (!data || !allow) return;
    
    inputs.value = inputs.value.slice(0, 2);
    
    const fieldConfigs = {
      firstName: {
        type: "text",
        label: t("kyc.first_name"),
        value: props.personalData?.firstName || "",
      },
      lastName: {
        type: "text",
        label: t("kyc.last_name"),
        value: props.personalData?.lastName || "",
      },
      dateOfBirth: {
        type: "date",
        label: t("kyc.date_of_birth"),
        value: props.personalData?.dateOfBirth || "",
      },
      city: {
        type: "text",
        label: t("kyc.city"),
        value: props.personalData?.city || "",
      },
      addressLine1: {
        type: "text",
        label: t(`kyc.${camelToSnakeCaseWithNumbers("addressLine1")}`),
        value: props.personalData?.addressLine1 || "",
      },
      addressLine2: {
        type: "text",
        label: t(`kyc.${camelToSnakeCaseWithNumbers("addressLine2")}`),
        value: props.personalData?.addressLine2 || "",
      },
      postalCode: {
        type: "text",
        label: t("kyc.postal_code"),
        value: props.personalData?.postalCode || "",
      },
    };

    displayOrders.forEach(field => {
      if (data[field]) {
        inputs.value.push({
          name: field,
          value: props.personalData?.[field] || "",
          type: fieldConfigs[field].type,
          label: fieldConfigs[field].label,
          placeholder: fieldConfigs[field].label,
          rules: null,
          clearable: false,
          error: "",
        });
      }
    });
  },
  { immediate: true, deep: true }
);

watch(
  () => props.personalData,
  (newData) => {
    if (!newData) return;
    
    const isEmailField = registrationFlow.value.type === 'number_2fa_with_email';
    if (inputs.value[0]) {
      inputs.value[0].value = isEmailField 
        ? newData?.email || '' 
        : newData?.phone ? `+${newData.phone}` : '';
    }
    
    inputs.value.forEach(input => {
      if (input.name && newData[input.name] !== undefined) {
        input.value = newData[input.name];
      }
    });
  },
  { immediate: true, deep: true }
);

watch(
  () => [registrationFlow.value.type, props.personalData],
  ([type,personalData]) => {
    const isEmailField = type === 'number_2fa_with_email';
    inputs.value[0] = {
      name: isEmailField ? 'email' : 'phone',
      value: isEmailField 
        ? personalData?.email || '' 
        :personalData?.phone ? `+${personalData.phone}` : '',
      type: isEmailField ? 'email' : 'tel',
      label: t(isEmailField ? 'auth.email' : 'auth.phone'),
      placeholder: t(isEmailField ? 'auth.email' : 'auth.phone'),
      rules: isEmailField ? emailRules : phoneRules,
      clearable: false,
      error: "",
    };
  },
  { immediate: true }
);

fetchCountries();
</script>

<template>
  <form @submit.prevent="emit('submit', inputs)">
    <div class="form-fields">
      <BasePhoneField
        :model-value="inputs[0].value"
        :rules="inputs[0].rules"
        :label="inputs[0].label"
        :placeholder="inputs[0].placeholder"
        :type="inputs[0].type"
        :icon-name="inputs[0].iconName"
        :error="inputs[0].error"
        :country-codes="countryCodes"
        @update:model-value="inputs[0].value = $event"
        @country-changed="inputs[1].value = $event.iso2"
        @error="inputs[0].error = $event"
        v-if="registrationFlow.type === 'email_2fa_with_number'"
      />
      
      <BaseTextField
        v-if="registrationFlow.type === 'number_2fa_with_email'"
        :model-value="inputs[0].value"
        :rules="inputs[0].rules"
        :label="inputs[0].label"
        :placeholder="inputs[0].placeholder"
        :type="inputs[0].type"
        :clearable="inputs[0].clearable"
        :icon-name="inputs[0].iconName"
        :error="inputs[0].error"
        @update:model-value="inputs[0].value = $event"
        @error="inputs[0].error = $event"
      />

      <BaseTextField
        v-for="(input, index) in inputs.slice(2)"
        :key="input.name"
        :model-value="input.value"
        :rules="input.rules"
        :label="input.label"
        :placeholder="input.placeholder"
        :type="input.type"
        :clearable="input.clearable"
        :icon-name="input.iconName"
        :error="input.error"
        @update:model-value="input.value = $event"
        @error="input.error = $event"
      />
    </div>

    <BaseButton class="submit alight wide high" type="submit">
      {{ t("kyc.сlick_to_complete") }}
    </BaseButton>
  </form>
</template>

<style lang="scss" scoped>
.form-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}
</style>
