<script setup>
import { ref } from "vue";
import BaseDropdown from "@/app/components/ui/BaseDropdown.vue";

defineProps({
  options: {
    type: Array,
    default: () => [],
  },
});
const modelValue = defineModel();
const baseDropdown = ref();

function setOption(b) {
  modelValue.value = b;
  baseDropdown.value.close();
}
</script>

<template>
  <BaseDropdown ref="baseDropdown" class="base-select">
    <template #label>
      <div class="option">
        {{ modelValue?.title }}
      </div>
    </template>
    <template #panel>
      <div class="options">
        <div v-for="option in options" :key="option.id" class="option" @click="setOption(option)">
          {{ option.title }}
        </div>
      </div>
    </template>
  </BaseDropdown>
</template>

<style lang="scss" scoped>
.base-select {
  height: 40px;
  border: 1px solid #424361;
  margin: 1em 0 1em;
  padding: 7px 0 10px 1em;
  .options {
    margin: 1em;
    .option {
      padding: 0.5em 1em;
      cursor: pointer;
      &:hover {
        background-color: var(--be-small-card-bg);
      }
    }
  }
  :deep(.dropdown-panel) {
    width: 100%;
    top: 40px;
    &::after {
      content: none;
    }
  }
}
</style>
