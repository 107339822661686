<script>
import SimplePage from "./SimplePage.vue";

export default {
  name: "NotFoundPage",
  components: { SimplePage },
};
</script>

<template>
  <SimplePage title="Oops!" body="404 - PAGE NOT FOUND" suffix="">
    <a href="/">
      <button class="go-home app-default-btn">GO TO HOME PAGE</button>
    </a>
  </SimplePage>
</template>

<style scoped>
.go-home {
  padding: 10px 20px;
  margin-top: 40px;
}
</style>
