<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useMeta } from "vue-meta";
import { useBetStore } from "../store.js";
import AppMobileMenu from "./app/components/mobile-menu/AppMobileMenu.vue";
import { useLogoBanner } from "@/app/composables/useLogoBanner";
import { useFingerprint } from "@/app/composables/useFingerprint";

import GlobalLoader from "@/app/components/ui/GlobalLoader.vue";
import { useScreenSize } from "@/app/composables/useScreenSize";

const { screenFactor, initScreenTracker } = useScreenSize();
const route = useRoute();
const store = useBetStore();
const { logo } = useLogoBanner();

function removeSplashScreen() {
  setTimeout(() => {
    document.querySelector(".splash-screen")?.classList.add("fade-out");
    setTimeout(() => {
      document.querySelector("body").classList.remove("splash");
      document.body.style.position = "initial";
    }, 500);
  }, 1000);
}

initScreenTracker({
  mobile: 480,
  tablet: 766,
  notebook: 1280,
  desktop: 10000,
});

function storeAffiliateTags() {
  const urlParams = new URLSearchParams(window.location.search);

  const bTag = urlParams.get("btag");

  if (!bTag) {
    return;
  }

  localStorage.setItem("btag", JSON.stringify({ tag: bTag, date: new Date() }));
}

onMounted(async () => {
  storeAffiliateTags();
  window.addEventListener("resize", handleResize);
  removeSplashScreen();
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});

function handleResize() {
  store.setScreenWidth(window.innerWidth);
}

const isVisibleMobileMenu = computed(() => route.name !== "maintenance");

document.body.classList.add(`brand-${import.meta.env.VITE_APP_BRAND}`);

useMeta({
  meta: [
    { property: "og:url", content: import.meta.env.VITE_APP_URL },
    { property: "og:type", content: "website" },
    { property: "og:title", content: "Crypto Sportsbook & Casino. Free Entry Competitions & Prizes" },
    { property: "og:description", content: "Join Crypto Sportsbook & Casino, Live games, Friendly support, Multiple cryptocurrencies, Fast Withdrawals" },
    { property: "og:image", content: logo.value },
  ],
});

// Initialize fingerprint tracking
useFingerprint();
</script>

<template>
  <metainfo />
  <main :class="screenFactor">
    <router-view />
    <AppMobileMenu v-if="isVisibleMobileMenu" class="tablet-only" />
    <GlobalLoader />
  </main>
</template>

<style></style>
