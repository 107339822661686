import { ref } from "vue";
import { api } from "@/services";
import { toast } from "@/app/utils/notification";
import { useUserService } from "@/user-account/composables/useUserService.ts";
import { useAuth } from "@/user-account/composables/useAuth";
import { useGlobalLoader } from "@/app/composables/useGlobalLoader";

const { enableLoader } = useGlobalLoader();
const { isAuthenticated } = useAuth();
const { activeWallet } = useUserService();
const selectedCurrency = ref("USD");
const loading = ref(false);

const isGameSession = ref(false);

export function useGameHelper() {
  async function startGame(game, isDemo, handleLogin) {
    if (!isAuthenticated() && !isDemo) {
      handleLogin();
      return;
    }
    enableLoader(3000);
    isGameSession.value = true;
    loading.value = true;

    // Get wallet id
    let walletId = activeWallet.value?.id;
    if (isBonusGame(game)) {
      walletId = activeWallet.value.activeBonusWallet.id;
    }

    const data = await api.casino.gameInit({
      gameId: game.value.id,
      walletId,
      isDemo,
      cur: selectedCurrency.value,
    });

    if (data.redirect_url === null) {
      toast.warn(`This game can not be played${isDemo ? " in demo mode" : ""}`);
    } else {
      game.value.redirectUrl = data.redirect_url;
    }
    loading.value = false;
  }

  function isBonusGame(game) {
    // Check is has bonus wallet for current active wallet
    if (
      activeWallet.value.activeBonusWallet === undefined
      || activeWallet.value.activeBonusWallet === null
    ) {
      return false;
    }

    // Check is has limitations by game ids
    if (
      activeWallet.value.activeBonusWallet.allowedGameIds === null
      || activeWallet.value.activeBonusWallet.allowedGameIds === []
    ) {
      return true;
    }

    const allowedGameIds = activeWallet.value.activeBonusWallet.allowedGameIds;

    // Check is current game provider was selected
    if (allowedGameIds[`provider.${game.value.providerId}`] === undefined) {
      return false;
    }

    const providerSettings = allowedGameIds[`provider.${game.value.providerId}`];

    // Check is for current game provider was selected any specific games
    if (
      providerSettings.games === undefined
      || providerSettings.games === null
      || (Array.isArray(providerSettings.games) && providerSettings.games.length === 0)
    ) {
      return true;
    }

    // Check is current game was selected
    return providerSettings.games[`game.${game.value.id}`] !== undefined
      && providerSettings.games[`game.${game.value.id}`] !== null;
  }

  return {
    startGame,
    isBonusGame,
    isGameSession,
    selectedCurrency,
  };
}
