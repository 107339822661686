const numericPattern = /^\d*\.?\d*$/;

export function onlyNumbers(value) {
  if (value === null || numericPattern.test(value)) {
    return true;
  }

  return "Stake field should have only numbers";
}

// const rules = [onlyNumbers];

// export { rules };
// export default rules;
