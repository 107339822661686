<script setup>
import { ref } from "vue";
import CasinoBonusModal from "../../modal/CasinoBonusModal.vue";

const props = defineProps({
  bonus: {},
  title: String,
  expire: String,
  icon: String,
  value: String,
  info: {
    type: Boolean,
    default: true,
  },
});

const showInfoModal = ref(false);

function handleShowCasinoBonusModal() {
  showInfoModal.value = false;
}
</script>

<template>
  <div>
    <CasinoBonusModal :show="showInfoModal" @close="handleShowCasinoBonusModal" :bonus="bonus" />
    <div class="active-block">
      <div class="active-title">
        <div>{{ title }}</div>
        <img
          v-if="info"
          class="info-light"
          @click="showInfoModal = true"
          src="/assets/images/info.svg"
          alt=""
        />
      </div>
      <div class="currency">
        <img :src="icon" alt="" class="currency-icon" />
        <div>{{ value }}</div>
      </div>
      <div class="expires" v-if="expire !== null">{{ expire }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.currency-icon {
  width: 16px;
  height: 21px;
}

.active-block {
  background: var(--be-big-card-bg);
  padding: 16px;
  border-radius: 8px;
}

.active-title {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 11px;
  font-family: Poppins-reg;
}

.currency {
  display: flex;
  align-items: center;
  gap: 0 4px;
  font-family: Poppins-bold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.info-light {
  opacity: 0.3;
  cursor: pointer;
}

.expires {
  color: #fff;
  font-family: Poppins-reg;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.3;
  margin-top: 5px;
}
</style>
