import { t } from "@/app/composables/useI18n";

export function min4(code) {
  if (code.length > 0 && code.length < 4) {
    return t("account.invalid_refer_code");
  }

  return true;
}

export const rules = [min4];
