export const updateNestedProperties = (obj, keys, updates) => {
  let current = obj;
  for (let i = 0; i < keys.length - 1; i++) {
    if (!current[keys[i]]) {
      // console.error(`Path does not exist: ${keys.slice(0, i + 1).join(" -> ")}`);
      return; // Exit the function if the path does not exist
    }
    current = current[keys[i]];
  }
  const finalKey = keys[keys.length - 1];
  if (current[finalKey] === undefined) {
    // console.error(`Final key does not exist: ${finalKey}`);
    return; // Exit the function if the final key does not exist
  }

  // Apply updates
  for (const [key, value] of Object.entries(updates)) {
    if (current[finalKey][key] !== undefined) {
      current[finalKey][key] = value;
    } else {
      // console.error(`Key does not exist: ${key}`);
      return; // Exit the function if the key does not exist
    }
  }
};
