import { jsonRpc } from "@/services/json-rpc/jsonrpc";

const betLimits = {
  async user() {
    return await jsonRpc(
      {
        method: "users.bet-limit:user",
      },
    );
  },

  async save(params) {
    return await jsonRpc({
      method: "users.bet-limit:save",
      params,
    });
  },
};

export default betLimits;
