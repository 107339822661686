<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useBetStore } from "../../../../store";
import TicketInfo from "./TicketInfo.vue";
import TicketDetails from "./TicketDetails.vue";
import { api } from "@/services";
import CollapsableBox from "@/app/components/ui/CollapsableBox.vue";
import { useUserService } from "@/user-account/composables/useUserService";
import { useI18n } from "@/app/composables/useI18n";

const props = defineProps({
  type: {
    type: String,
    default: "open",
  },
});

const store = useBetStore();

const { t } = useI18n();

const { isAuthenticated, activeWallet } = useUserService();

// data: () => ({
//   header: "betSlip",
//   wallet: null,
//   bets: [],
//   loading: false,
//   notMenuSelector: true
// }),
// const status = "open";
// const wallet = computed(() => getActiveWallet);
// const tickets = ref([]);
const allTickets = ref([]);
const loading = ref(false);
onMounted(fetchBets);

watch(
  () => props.type,
  async () => {
    fetchBets();
  }
);

const tickets = computed(() => {
  return allTickets.value.filter((ticket) => ticket.walletId == activeWallet.value?.id);
});

async function fetchBets() {
  if (!isAuthenticated()) {
    store.handleShowLogin();
    // await router.push({ name: "login" });
    return;
  }

  loading.value = true;
  // const { data, meta } = await handler(0, user.activeWallet.id);
  // const { data } = await handler(0, user.activeWallet.id);
  allTickets.value = await api.users.tickets({
    type: props.type,
  });
  loading.value = false;

  // lastPage.value = meta.last_page;
}
</script>

<template>
  <div>
    <div class="ticket-history">
      <CollapsableBox v-for="ticket in tickets" :key="ticket.id">
        <template #header>
          <TicketInfo :ticket="ticket" />
        </template>
        <template #body>
          <!-- <p>hello</p> -->
          <TicketDetails :ticket="ticket" />
        </template>
      </CollapsableBox>
      <div v-if="!tickets.length" class="no-open-bets">
        {{ t("You have no bets") }}
      </div>
    </div>
    <!-- <Pagination
      v-if="bets.length"
      v-model="page"
      :total-visible="4"
      :length="lastPage"
      @input="onPagination"
      class="mt-3"
    /> -->
  </div>
</template>

<style lang="scss" scoped>
// .ticket-history {
//   background-color: #19183b;
// }
</style>
