<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  iconName: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "0",
  },
  type: {
    type: String,
    default: "text",
  },
  clearable: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  rules: {
    type: Array,
    default: () => [],
  },
  readonly: {
    type: Boolean,
    default: false,
  },

  digits: {
    type: Number,
    default: 4,
  },

  error: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const valueArr = ref([]);
const valueArray1 = ref(["", "", "", ""]);

const uuid = Math.random(1000);
const inputRef = ref({});

const localError = ref();

function onInput(v, index) {
  valueArr.value[index - 1] = +v;
  valueArray1.value[index - 1] = +v;

  const value = valueArr.value.reduce((acc, el) => {
    return acc + el;
  }, "");

  if (value.length == 4) {
    emit("update:modelValue", value);
    return;
  }
  if (v != "") {
    inputRef.value[index + 1].focus();
  }
}

function onBlur(event) {
  localError.value = "";
  props.rules.forEach((rule) => {
    const result = rule(event.target.value);
    if (result !== true) {
      localError.value = result;
    }
  });
}

const errorMessage = computed(() => {
  return props.error || localError.value;
});

function isNumericString(str) {
  const regex = /^[0-9]+$/;
  return regex.test(str);
}

const handlePaste = (e) => {
  e.preventDefault();
  if (
    isNumericString(e.clipboardData.getData("text")) &&
    e.clipboardData.getData("text").length >= 4
  ) {
    valueArray1.value[0] = e.clipboardData.getData("text")[0];
    valueArray1.value[1] = e.clipboardData.getData("text")[1];
    valueArray1.value[2] = e.clipboardData.getData("text")[2];
    valueArray1.value[3] = e.clipboardData.getData("text")[3];
    const value = valueArray1.value.reduce((acc, el) => {
      return acc + el;
    }, "");
    emit("update:modelValue", +value);
  }
};
</script>

<template>
  <span class="input-element">
    <span v-if="props.label" class="label">{{ props.label }}</span>

    <div class="input-field">
      <input
        v-for="index in digits"
        :id="uuid + index"
        :ref="(el) => (inputRef[index] = el)"
        :key="index"
        :value="valueArray1[index - 1]"
        :type="type"
        :class="{ 'error-state': errorMessage }"
        :readonly="props.readonly"
        :disabled="props.disabled"
        @input="onInput($event.target.value, index)"
        @on-blur="onBlur"
        maxlength="1"
        @paste="handlePaste"
      />
    </div>

    <small v-if="errorMessage" class="error">{{ errorMessage }}</small>
  </span>
</template>

<style lang="scss" scoped>
.input-element {
  display: flex;
  flex-direction: column;
  position: relative;

  .label {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .input-field {
    display: flex;
    gap: 1rem;
  }

  .error-state {
    border: solid 2px #ff5252;
  }

  .error {
    color: #ff5252;
    margin: 10px 0px;
    height: 20px;
  }

  input {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    transition: all 0.2s;
    appearance: none;
    color: var(--be-primary-text-color);
    background-color: var(--be-small-card-bg);
    font-weight: 700;
    border: 2px solid transparent;
    border-radius: 2px;
    text-align: center;

    &[type="text"],
    &[type="password"] {
      background-color: var(--be-small-card-bg);
    }

    ::placeholder {
      color: red;
      opacity: 1;
      /* Firefox */
    }

    ::-ms-input-placeholder {
      /* Edge 12-18 */
      color: red;
    }

    // margin: 5px 0;
    &:enabled:focus {
      box-shadow: none;
    }

    &:focus,
    &:active {
      outline: none;
      border-color: #ffe81a;
      // box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px, rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
      //   rgba(0, 0, 0, 0.15) 0px -3px 0px inset, rgb(255, 135, 25) 0px 0px 12px inset;
    }
  }

  .suffix-icon {
    position: absolute;
    top: 35px;
    right: 5px;
    opacity: 0.6;
    transition: all 0.3s ease-in;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
