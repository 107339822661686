import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-vue-v3";
import { onMounted } from "vue";
import { useAuth } from "@/user-account/composables/useAuth";
import { sendFingerprint } from "@/user-account/api/index";

export function useFingerprint() {
  const { isAuthenticated } = useAuth();

  const { data, error, isLoading, getData } = useVisitorData(
    { extendedResult: true },
    { immediate: true },
  );

  const sendFingerprintData = async () => {
    if (!isAuthenticated()) {
      return;
    }

    try {
      if (!data.value?.sealedResult) {
        await getData();
      }

      await sendFingerprint({
        sealedResult: data.value?.sealedResult,
      });
    } catch (error) {
      console.error("Error sending fingerprint data:", error);
    }
  };

  onMounted(async () => {
    await sendFingerprintData();
  });

  return {
    sendFingerprintData,
    data,
    isLoading,
    error,
  };
}
